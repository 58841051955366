import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserPerviewList from '../../UserPerviewList';
import { getTotalContri, haveValue, hideLoader, showLoader, usersInContributions, isNotEmpty } from '../../../utils/helpers';
import { ThemeContext } from '../../../hooks/useThemeContext';
import { AuthContext } from "../../../Context/AuthContext";
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BiRupee } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import contributionAction from '../../../Actions/contribution.service';
import FieldError from "./../../../../src/Components/Common/FieldError";
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import config from "../../../Config/Config";
import UnSuccess from '../../../Animations/Unsuccessful';

function MoneyCollectBox(props) {
  const { id } = props;
  const { event, disable } = props;
  const { loggedInUserId, setIsLoggedIn, setIsSidebarVisible, loggedInUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showContributeNow, setShowContributeNow] = useState(false);
  const [amount, setAmount] = useState();
  const [error, setError] = useState();
  const { showErrorToast, showSuccessToast, hideErrorToast } = useContext(ThemeContext);
  const [showContributionSetting, setShowContributionSetting] = useState(false);
  const [contributionList, setContributionList] = useState();
  const [alreadyContributed, setAlreadyContributed] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [joinedPart, setJoinedPart] = useState([]);
  const [numberError, setNumberError] = useState({
    visible: false,
    title: 'Please add your phone number first to contribute.'
  })

  useEffect(() => {
    setDisabledButton(disable);
  }, [disable])

  useEffect(() => {
    let resultA = [];
    if (isNotEmpty(event?.participants)) {
      const newFilter = Object.keys(event?.participants).reduce((result, key) => {
        if (event?.participants[key].status.includes("joined")) {
          resultA.push(event?.participants[key]);
        };
        setJoinedPart(resultA);
      }, []);
    }
  }, [event])

  const handleNumberErrorClose = async () => {
    setNumberError({ ...numberError, visible: false })
    navigate('/editProfile');
  }

  const handlePayment = async () => {
    if (loggedInUserData && !haveValue(loggedInUserData?.phoneNumber)) {
      setNumberError({ ...numberError, visible: true })
      // showErrorToast('Please add your phone number first to contribute.')
      // navigate('/editProfile');
      return false;
    }
    let payload = {};
    if (window.location.origin == 'https://dev.wegroup4.com' || window.location.origin == 'https://www.dev.wegroup4.com')
      payload.amount = 1;
    else
      payload.amount = event?.contriPerPerson;
    payload.productinfo = event?.giftCardType;
    payload.sku = event?.giftCard?.sku
    showLoader();
    try {
      const res = await contributionAction.initiateTransaction(id, payload);
      console.log(res)
      if (config.DEMO == 'true') {
        navigate(`/paymentsuccess/${id}`);
      }
      else {
        window.location.href = `https://pay.easebuzz.in/v2/pay/${res?.data}`;
      }
      hideLoader();
    } catch (err) {
      console.log(err, "==error");
      hideLoader();
    }
    return false;
  };


  const handleClosedateadded = () => {
    setShowContributionSetting(false);
    setShowContributeNow(false);
  }

  useEffect(() => {
    showLoader();
    contributionAction.contributionList(id).then(res => {
      setContributionList(res);
      let userListData = usersInContributions(res);
      setUsersData(userListData);
      res.map((itemc) =>
        itemc?.eventParticipantId?.userId?._id === loggedInUserId ?
          setAlreadyContributed(true)
          :
          ''
      )
      hideLoader();
    }).catch(err => {
      console.log(err)
    });
  }, []);

  console.log("Here is event data", event)


  const contributionPage = () => {
    if ((haveValue(event?.totalContri) && event?.totalContri > 0) || (haveValue(joinedPart) && joinedPart.length > 1)) {
      setShowContributionSetting(true)
    } else {
      if (isNotEmpty(event?.giftCardType)) {
        navigate(`/ContributionPage/${id}`);
      } else {
        showErrorToast('Please Choose Gift Card first.');
      }
    }
  }
  const contributeNow = () => {
    setShowContributeNow(true)
  }

  return (
    <>
      <div className='MoneyCollectBox'>
        <h4>
          <div className='leftbar'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#E6F4D7" />
              <path d="M11.9987 15.3333V18M19.9987 14V16.6666M19.332 10.6666C20.9645 10.6666 21.8475 10.9165 22.2868 11.1103C22.3453 11.1361 22.3745 11.149 22.459 11.2295C22.5096 11.2778 22.602 11.4196 22.6257 11.4854C22.6654 11.5951 22.6654 11.6551 22.6654 11.7751V18.9407C22.6654 19.5466 22.6654 19.8495 22.5745 20.0052C22.4821 20.1636 22.393 20.2372 22.22 20.2981C22.0499 20.3579 21.7067 20.292 21.0201 20.1601C20.5396 20.0677 19.9697 20 19.332 20C17.332 20 15.332 21.3333 12.6654 21.3333C11.0329 21.3333 10.1499 21.0835 9.71062 20.8897C9.65211 20.8639 9.62286 20.851 9.53843 20.7704C9.48782 20.7221 9.39544 20.5804 9.37168 20.5146C9.33203 20.4048 9.33203 20.3448 9.33203 20.2248L9.33203 13.0592C9.33203 12.4533 9.33203 12.1504 9.42288 11.9947C9.5153 11.8363 9.60443 11.7627 9.7774 11.7018C9.94746 11.642 10.2907 11.7079 10.9773 11.8399C11.4578 11.9322 12.0277 12 12.6654 12C14.6654 12 16.6654 10.6666 19.332 10.6666ZM17.6654 16C17.6654 16.9204 16.9192 17.6666 15.9987 17.6666C15.0782 17.6666 14.332 16.9204 14.332 16C14.332 15.0795 15.0782 14.3333 15.9987 14.3333C16.9192 14.3333 17.6654 15.0795 17.6654 16Z" stroke="#4F7A21" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Money Collection
          </div>
          <div className='settingicon'>
            {
              !(window.location.pathname.startsWith("/participant")) && (haveValue(event?.participants) && event?.participants.length < 2) ?
                <>
                  <Button onClick={contributionPage} className='settingbtn'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17 9.00006L13 5.00006M1.5 20.5001L4.88437 20.124C5.29786 20.0781 5.5046 20.0551 5.69785 19.9925C5.86929 19.937 6.03245 19.8586 6.18289 19.7594C6.35245 19.6476 6.49955 19.5005 6.79373 19.2063L20 6.00006C21.1046 4.89549 21.1046 3.10463 20 2.00006C18.8955 0.895489 17.1046 0.895488 16 2.00006L2.79373 15.2063C2.49955 15.5005 2.35246 15.6476 2.24064 15.8172C2.14143 15.9676 2.06301 16.1308 2.00751 16.3022C1.94496 16.4955 1.92198 16.7022 1.87604 17.1157L1.5 20.5001Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </Button>
                </>
                :
                <></>
            }
          </div>
        </h4>
        {
          haveValue(event?.contriType) ?
            <><div className='price-collected'>
              <h4>{getTotalContri(event?.totalContri)}<span className='graybar'>Collected </span></h4>
            </div></>
            : ''
        }

        <UserPerviewList usersData={usersData} eventData={event} navigateTo={`/contributionlist/${event?._id}`} />

        {
          haveValue(event?.contriType) ?
            <>
              <div className='savebtn'>
                {
                  alreadyContributed || event?.isConfirmed ?
                    ''
                    :
                    <><div className='moneyaddedbar'>
                      <img src='/images/moneyGive.png' alt='moneygive' />
                    </div>
                      {
                        event && !event?.isConfirmed ?
                          (
                            (parseInt(event?.totalContri) + parseInt(event?.contriPerPerson)) <= parseInt(event?.giftCard?.maximumAmount) ?
                              <>
                                {
                                  event?.contriPerPerson > 0 ?
                                    <button onClick={handlePayment} type="button" className='btn-pink' id="ebz-checkout-btn">
                                      Contribute {
                                        event?.contriType === "fixed" ?
                                          getTotalContri(event?.contriPerPerson)
                                          : ''
                                      }  now
                                    </button>
                                    :
                                    <></>
                                }
                              </>
                              :
                              <p>The contribution has reached its maximum limit.</p>
                          ) : (
                            ""
                          )
                      }
                    </>
                }
                {/* <Link onClick={handlePayment} type="button" className='btn-pink' id="ebz-checkout-btn">
                                    Contribute {
                                      event?.contriType === "fixed" ?
                                        getTotalContri(event?.contriPerPerson)
                                        : ''
                                    }  now
                                  </Link> */}
                {event?.isSolo ?
                  <></>
                  :
                  <Link to={`/contributionlist/${event?._id}`} className='btngray'>
                    View all contributers
                  </Link>
                }
              </div>
            </>
            :
            <>
              <div className='moneyaddedbar'>
                <img src='/images/moneyGive.png' alt='moneygive' />
              </div>
              <div className='savebtn'>
                {
                  disabledButton || window.location.pathname.startsWith("/participant") ?
                    <>
                      <Button disabled onClick={contributionPage} className='btn-pink'>
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.0421 6.5H3.08333C1.93274 6.5 1 5.56726 1 4.41667C1 3.26607 1.93274 2.33333 3.08333 2.33333H11.0421M5.95791 15.6667H13.9167C15.0673 15.6667 16 14.7339 16 13.5833C16 12.4327 15.0673 11.5 13.9167 11.5H5.95791M1 13.5833C1 15.1942 2.30584 16.5 3.91667 16.5C5.5275 16.5 6.83333 15.1942 6.83333 13.5833C6.83333 11.9725 5.5275 10.6667 3.91667 10.6667C2.30584 10.6667 1 11.9725 1 13.5833ZM16 4.41667C16 6.0275 14.6942 7.33333 13.0833 7.33333C11.4725 7.33333 10.1667 6.0275 10.1667 4.41667C10.1667 2.80584 11.4725 1.5 13.0833 1.5C14.6942 1.5 16 2.80584 16 4.41667Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Configure Contributions
                      </Button>
                    </>
                    :
                    <>
                      <Button onClick={contributionPage} className='btn-pink'>
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.0421 6.5H3.08333C1.93274 6.5 1 5.56726 1 4.41667C1 3.26607 1.93274 2.33333 3.08333 2.33333H11.0421M5.95791 15.6667H13.9167C15.0673 15.6667 16 14.7339 16 13.5833C16 12.4327 15.0673 11.5 13.9167 11.5H5.95791M1 13.5833C1 15.1942 2.30584 16.5 3.91667 16.5C5.5275 16.5 6.83333 15.1942 6.83333 13.5833C6.83333 11.9725 5.5275 10.6667 3.91667 10.6667C2.30584 10.6667 1 11.9725 1 13.5833ZM16 4.41667C16 6.0275 14.6942 7.33333 13.0833 7.33333C11.4725 7.33333 10.1667 6.0275 10.1667 4.41667C10.1667 2.80584 11.4725 1.5 13.0833 1.5C14.6942 1.5 16 2.80584 16 4.41667Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Configure Contributions
                      </Button>
                    </>
                }
              </div>
            </>
        }
        {
          event?.contriPerPerson == null || event?.contriType !== "fixed" || event?.contriPerPerson == 0 ?
            ''
            :
            <div className='per-person'>

              <h6>{getTotalContri(event?.contriPerPerson)} /person</h6>

            </div>
        }

      </div >

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showContributionSetting} onHide={handleClosedateadded}>
        <Modal.Header closeButton>
          Money Contribution Settings
        </Modal.Header>
        <Modal.Body>
          {
            event?.contriType === "fixed" ?
              <><div className='FixedAmount'>
                <img src="/images/budget.png" alt='icon' />
                <h4>Fixed Amount</h4>
                <p>All Participants pay the same amount.</p>
                <Form.Group className="fixform" controlId="formBasicEmail">
                  <Form.Label>Enter per person contribution</Form.Label>
                  <div className='amount-type'>
                    <Form.Control type="number" disabled placeholder={getTotalContri(event?.contriPerPerson)} />
                    <span className='perperson'> / person</span>
                  </div>
                </Form.Group>
                <div className='note-error'>
                  <AiOutlineInfoCircle />
                  {/* <p>Cannot update this as the contributions have already started.</p> */}
                  <p>Cannot configure as the contributions have already started or participants have joined.</p>
                </div>
              </div></>
              :
              ''
          }
          {
            event?.contriType === "any" ?
              <><div className='FixedAmount'>
                <img src="/images/budget.png" alt='icon' />
                <h4>Any Amount</h4>
                {
                  haveValue(event?.minimumContriAmount) ?
                    <>
                      <Form.Group className="fixform" controlId="formBasicEmail">
                        <Form.Label>Minimum Contribution</Form.Label>
                        <div className='amount-type'>
                          <Form.Control type="number" disabled placeholder={getTotalContri(event?.minimumContriAmount)} />
                          <span className='perperson'> / person</span>
                        </div>
                      </Form.Group>
                    </>
                    :
                    ''
                }
                <div className='note-error'>
                  <AiOutlineInfoCircle />
                  <p>Cannot update this as the contributions have already started.</p>
                </div>
              </div></>
              :
              ''
          }

        </Modal.Body>
      </Modal>
      <Modal className="Createevent-modal fixamountpopup opacity-full dateadded-popup" show={showContributeNow} onHide={handleClosedateadded}>
        <Modal.Header closeButton>
          <h6>You will be redirected to</h6>
        </Modal.Header>
        <Modal.Body>
          {
            event?.contriType === "fixed" ?
              <><div className='FixedAmount'>
                <p>Our Secure payment gateway provider</p>
                <h4>Pay {getTotalContri(event?.contriPerPerson)}</h4>
              </div>
              </>
              :
              <>
                <Form.Group className="fixform" controlId="formBasicEmail">
                  <Form.Label>Enter amount</Form.Label>
                  <div className='amount-type'>
                    <BiRupee />
                    <Form.Control className={error ? 'error' : ""} type="number" onChange={(e) => setAmount(e?.target.value)} />
                  </div>
                </Form.Group>
                <FieldError error={error} />
                <div className='FixedAmount'>
                  <h4>Pay a minimum amount of {getTotalContri(event?.minimumContriAmount)}</h4>
                </div>
              </>
          }
          <Button onClick={handlePayment} type="button" className='btn-pink'>
            Continue & Pay
          </Button>
        </Modal.Body>
      </Modal>
      <div id="errorToastNumber">
        <Modal className="Createevent-modal dateadded-popup" show={numberError?.visible} onHide={() => setNumberError({ ...numberError, visible: false })}>
          <Modal.Body>
            <div className="dateaddedbar">
              <UnSuccess />
              <p>{numberError?.title}</p>
            </div>
            <Button className='btn-pink' onClick={handleNumberErrorClose}>
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default MoneyCollectBox;
