import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LandingHeader from "./LandingHeader";
import FaqPage from "../../Components/FAQ's/Index";
import GroupGiftBox from "../../Components/GroupGiftBox/Index";
import Footer from "../../Components/Footer/Index";
import { Link } from "react-router-dom";

const LandingAboutUs = () => {
  return (
    <>
      <div className="Landing-block">
        <Container>
          <Row>
            <Col lg={7} className="p-0">
              <LandingHeader />
              <div className="banner-content about-content">
                <h1>Who we are?</h1>
                <p>
                  That is correct, ‘Passion led us here’. We are a forward
                  thinking company with a straight forward vision. The founders
                  embarked this journey with a strong belief in ‘group’. We
                  believe there is a better way of bringing groups of friends
                  and family together to do virtual shopping together .
                </p>
                <div className="banner-shape">
                  <Link to="/getStarted" className="btn-pink">
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="padding-right-90">
                <div className="howwework-title">
                  <h4>Our Vision</h4>
                  <p>
                    Harness the human connect to help create memorable moments!
                  </p>
                </div>
                <div className="howwework-title">
                  <h4>Our Mission</h4>
                  <p>
                    Provide an innovative platform for sharing the joy of gifting.
                  </p>
                </div>
                <div className="landing-call-component">
                  <FaqPage />
                  <Footer className="landingfooter" />
                </div>
              </div>
            </Col>
            <Col lg={5} className="p-0">
              <div className="landing-right-bar">
                <img src="/images/home-create.jpg" alt="create event image" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingAboutUs;
