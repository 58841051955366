import React from 'react';
import TestimonialMain from './TestimonialMain';

function AllTestimonial(props) {
  const { testimonial, limit, viewOnly } = props;
  return (
    <>
      <div className='testimonial-tabs-content'>

        {
          limit ?
            testimonial?.length > 0 ?
              testimonial?.map((item, i) => {
                return (<>
                  {i < limit ?
                    <TestimonialMain onEdit={props.onEdit} onRefresh={props.onRefresh} testimonials={item} viewOnly={viewOnly} />
                    : ''}
                </>)
              })
              : <>
                <div className='when-no-wish'> No Wishes Found</div>
              </>
            :
            testimonial?.length > 0 ?
              testimonial?.map((item) => {
                return <>
                  <TestimonialMain onEdit={props.onEdit} onRefresh={props.onRefresh} testimonials={item} viewOnly={viewOnly} />
                </>
              })
              : <>
                <div className='when-no-wish'> No Wishes Found</div>
              </>
        }

      </div>
    </>
  );
}

export default AllTestimonial;
