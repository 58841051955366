import React from 'react';
import { Link } from 'react-router-dom';
import { HiArrowRight } from 'react-icons/hi';
import OnboardSlider from './OnboardSlider';

function OnBoard() {
    return (
        <>
            <div className='onboardbar'>
                <div className='onboard-head'>
                    <div className='logobar'>
                        <Link to="/getStarted">
                            <img src='/images/weGroup.png' alt='logo' />
                        </Link>
                        <p className='logo-text'>Group Gifting made easy</p>
                    </div>
                    <OnboardSlider />
                    <div className='started-btn'>
                        <Link to='/login' type='button' className='btn-pink'>Get started<HiArrowRight className='arrow-icon' /></Link>
                    </div>
                </div>

            </div>
        </>
    );
}

export default OnBoard;
