import React, { useEffect, useState, useContext } from 'react';
import EventStates from "../../Components/EventStates/Index";
import FaqPage from "../../Components/FAQ's/Index";
import Footer from "../../Components/Footer/Index";
import GroupGiftBox from "../../Components/GroupGiftBox/Index";
import HomeHeader from "../../Components/Header/HomeHeader";
import EventPartially from "../../Components/Home-Screen/EventPartially";
import FirstTimeUser from "../../Components/Home-Screen/FirstTimeUser";
import InviteParticipant from "../../Components/Home-Screen/InviteParticipant";
import Participant from "../../Components/Home-Screen/Participant";
import TestimonialSlider from "../../Components/Testimonials/TestimonialSlider";
import AuthAction from "../../Actions/auth.service"
import publicAction from "../../Actions/public.service"
import { useNavigate, useParams } from 'react-router-dom';
import { hideLoader, showLoader } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';


const Invite = (props) => {
    const { id } = useParams()
    const [eventDetails, setEventDetails] = useState({});

    const { showErrorToast } = useContext(ThemeContext);

    const navigate = useNavigate();

    useEffect(() => {
        showLoader();
        publicAction.getInvitationDetails(id).then(res => {
            setEventDetails(res);
            hideLoader();
        }).catch(err => {
            showErrorToast(err?.message);
            hideLoader();
            navigate('/getStarted');
        });
    }, [id]);

    return (
        <>

            <div className="homebar">
                <HomeHeader />

                <InviteParticipant eventDetails={eventDetails} hash={id} />

                <FirstTimeUser />


                <GroupGiftBox />
            </div>
            <FaqPage />
            {/* <EventStates /> */}
            <TestimonialSlider />
            <Footer />
        </>
    );
}

export default Invite;