import { React, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsThreeDots, BsPencil } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineDelete } from "react-icons/ai";
import TestimonialAction from "../../Actions/testimonial.service";
import { hideLoader, showLoader } from "../../utils/helpers";
import { toast } from 'react-toastify';
import { ThemeContext } from '../../hooks/useThemeContext';
import { AuthContext } from '../../Context/AuthContext';
function EditDelete(props) {
    const navigate = useNavigate();
    const { testimonial } = props;
    const [eventId, setEventId] = useState('');
    const [testimonialList, setTestimonialList] = useState();
    const [audios, setAudios] = useState(0);
    const [photos, setPhotos] = useState(0);
    const [videos, setVideos] = useState(0);
    const { type } = props;


    const { showSuccessToast } = useContext(ThemeContext);
    const { setIsSidebarVisible, setIsLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        setEventId(testimonial?.eventId);
    }, []);

    const deleteTest = async () => {
        TestimonialAction.deleteTestimonial(testimonial._id).then(res => {
            props.onRefresh();
            hideLoader();
            showSuccessToast('Wish Deleted');
        }).catch(err => {
            hideLoader();
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);
                navigate('/');
            }
        });
    }
    const editTest = async () => {
        if (testimonial?.mediaType === "image") {
            showLoader();
        }
        if (testimonial?.mediaType === "gif") {
            showLoader();
        }
        props.onEdit();
    }

    return (
        <div className='modify-list'>
            <Dropdown>
                <Dropdown.Toggle className='btntrans' id="dropdown-basic">
                    <BsThreeDots />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <div onClick={() => { editTest(); }} >
                            <BsPencil />Edit</div></Dropdown.Item>
                    <Dropdown.Item>
                        <div onClick={() => { deleteTest(); }} >
                            <AiOutlineDelete />Delete</div></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div >
    );
}

export default EditDelete;
