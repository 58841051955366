import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { BiRupee } from "react-icons/bi";
import { Link } from 'react-router-dom';
// import { AiOutlineInfoCircle } from "react-icons/ai";
import Switch from "react-switch";

function AnyAmount() {
  // Switch State
  const [activeSwitch, setActiveSwitch] = useState(false)
  return (
    <>
      <div className='FixedAmount'>
        <img src="/images/anyicon.png" alt='icon' />
        <h4>Any Amount</h4>
        <p>Participants can choose to pay any amount they are comfortable with.</p>
        <div className='anyamount'>
          <Switch onChange={(prevState) => setActiveSwitch(!prevState)} checked={activeSwitch}
            offColor="#f2f4f7"
            onColor="#e31854"
            onHandleColor="#ffffff"
            offHandleColor="#ffffff"

            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={24}
            width={44}
            className="react-switch"
            id="material-switch" />
          <p>Set a minimum amount</p>
        </div>
        <Form.Group className="fixform" controlId="formBasicEmail">
          <Form.Label>Minimum contribution</Form.Label>
          <div className='amount-type'>
            <BiRupee />
            <Form.Control type="number" placeholder="100" />
            <span className='perperson'>/ person</span>
          </div>
        </Form.Group>
        {/* <div className='note-error'>
          <AiOutlineInfoCircle />
          <p>Cannot update this as the contributions have already started.</p>
        </div> */}
        <Link to='' type='button' className='btn-pink'>Update</Link>

      </div>
    </>
  );
}

export default AnyAmount;
