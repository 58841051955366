import React from 'react';
import Lottie from 'react-lottie';
import * as sparkleAnimationData from './Sparkles.json'
function Sparkle() {
  return (
      <>
      <div className='sparkles-bg'>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: sparkleAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                    height={200}
                    width={"100%"}
                    isStopped={false} />
                </div>
      </>
  );
}

export default Sparkle;
