import React, { useState, useEffect, useContext } from "react";
import { BsThreeDots, BsPencil } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineDelete } from "react-icons/ai";
import moment from 'moment';
import config from "../../../Config/Config";
import { AuthContext } from './../../../Context/AuthContext';
import EditDelete from './../editDelete';
import { ShowImage, withBackendUrl, showLoader } from '../../../utils/helpers';


function PhotosTestimonial(props) {
  const { loggedInUserId } = useContext(AuthContext);
  const { testimonial, viewOnly } = props;

  const handleEdit = () => {
    localStorage.setItem('editTest', true);
    localStorage.setItem('captionTesti', testimonial?.caption);
    localStorage.setItem('testimonialId', testimonial?._id);
    if (testimonial?.media.startsWith('http')) {
      localStorage.setItem('isGif', true);
    }
    props.onEdit();
  }

  return (
    <>
      <div className='Testi-content'>

        <div className='mediauploaded'>
          {
            testimonial?.media && testimonial?.media.startsWith('http') ?
              <img src={testimonial?.media} alt='girl.png' />
              :
              <img src={withBackendUrl(testimonial?.media)} alt='girl.png' />
          }
        </div>
        <div className='testimonial-content-head'>
          <div className='headleft'>
            <img src={ShowImage(testimonial?.userId?.profilePhoto, testimonial?.userId?.fullName)} alt='rs' />
            <h6>{testimonial?.userId?.fullName}</h6>
          </div>
          <div className='headryt'>
            <p className='date'>{moment(testimonial?.createdAt).format("MMM DD YYYY")}</p> |
            <p className='time'>{moment(testimonial?.createdAt).format("hh:mm A")}</p>
          </div>
        </div>
        <div className='testimonial-content-text'>
          <p>{testimonial?.caption ? testimonial?.caption : ''}</p>
        </div>
        {
          viewOnly === "true" || viewOnly === true ?
            ''
            :
            <>
              {
                (loggedInUserId === testimonial?.userId?._id) || (testimonial.eventId.createdBy === loggedInUserId) ?
                  <EditDelete onEdit={handleEdit} onRefresh={props.onRefresh} testimonial={testimonial} type="image" />

                  :
                  ''
              }
            </>
        }
      </div>
    </>
  );
}

export default PhotosTestimonial;
