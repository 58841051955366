import React from 'react';
import Lottie from 'react-lottie';
import * as successAnimationData from './success.json'
function Success() {
    return (
        <>
            <div className='Success-bg'>
                <Lottie options={{
                    loop: false,
                    autoplay: true,
                    animationData: successAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                    height={160}
                    width={160}
                    isStopped={false} />
            </div>
        </>
    );
}

export default Success;
