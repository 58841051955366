import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Success from '../../Animations/Successfull';

function EventCancleUnsuccessful() {

  return (
    <>
      {/* EventCancleUnsuccessful */}
      <div className='EventCancleUnsuccessful'>
        <div className='topbar'></div>
        <div className='centerbar'>
        <Success/>
        <p>This event has been cancelled successfully</p>
        </div>
        <div className='bottombar'>
        <Link to="/getStarted" className='btn-pink' type="submit"><img src='/images/users.png' alt='user'/>Start a new event</Link>
        <Link to="/getStarted" className='btngray' type="submit">Go to home page</Link>
        </div>
      </div>
    </>
  );
}

export default EventCancleUnsuccessful;
