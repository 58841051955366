import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate, useParams } from 'react-router-dom';
import EventAction from '../../Actions/event.service';
import { withBackendUrl, haveValue, showLoader, hideLoader, getTotalContri } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';
import { AuthContext } from '../../Context/AuthContext';


function AllGiftCard() {
    const { id } = useParams();
    const [data, setData] = useState({});
    const { setBackUrlContext } = useContext(ThemeContext)
    const { loggedInUserData } = useContext(AuthContext);
    useEffect(() => {
        showLoader();
        getEvent();
        setBackUrlContext(`/recipient/${id}`)
        hideLoader();
    }, [])

    const getEvent = () => {
        EventAction.viewEvent(id).then(res => {
            setData(res);
        }).catch(err => {
            console.log(err)
        });
    }

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    // Open Calender Popup
    const [showgiftcard, setgiftcardShow] = useState(false);
    const GiftCardClose = () => setgiftcardShow(false);
    const GiftCardShow = () => setgiftcardShow(true);

    return (
        <>
            <h4 className="titleevent">Gifts</h4>
            <div className='allcards allgiftscard_bar'>
                <div className='cards-head'>
                    <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='AllGiftCard'>
                                    <img src={withBackendUrl(data?.giftCard?.icon)} alt='giftcard' />
                                    <div className='giftcardbasic'>
                                        <span className='giftcardamount'>
                                            Amount: <b>{getTotalContri(data?.totalContri)}</b>
                                        </span>
                                        <span className='giftcardexpire'>
                                            {
                                                haveValue(data?.giftCardExpiry) &&
                                                <p>Gift card expires on <b>{data?.giftCardExpiry}</b></p>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item>
                            {
                                haveValue(loggedInUserData?.email) && haveValue(data?.email) ?
                                    // loggedInUserData?.email == data?.email || loggedInUserData?.phoneNumber == "+".data?.phone ?
                                    <>
                                        <div className='carddetail'>
                                            <div className='detailin d-flex-justify mt-4'>
                                                <div className='inleft'>
                                                    <label>Gift Card ID</label>
                                                    <h4>{data?.giftCardId}</h4>
                                                </div>
                                                <div className='copybtn'>
                                                    <button className='btn' onClick={(e) => copyTextToClipboard(data?.giftCardId)}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.25 1.5H11.1667C13.0335 1.5 13.9669 1.5 14.68 1.86331C15.3072 2.18289 15.8171 2.69282 16.1367 3.32003C16.5 4.03307 16.5 4.96649 16.5 6.83333V12.75M4.16667 16.5H10.9167C11.8501 16.5 12.3168 16.5 12.6733 16.3183C12.9869 16.1586 13.2419 15.9036 13.4017 15.59C13.5833 15.2335 13.5833 14.7668 13.5833 13.8333V7.08333C13.5833 6.14991 13.5833 5.6832 13.4017 5.32668C13.2419 5.01308 12.9869 4.75811 12.6733 4.59832C12.3168 4.41667 11.8501 4.41667 10.9167 4.41667H4.16667C3.23325 4.41667 2.76654 4.41667 2.41002 4.59832C2.09641 4.75811 1.84144 5.01308 1.68166 5.32668C1.5 5.6832 1.5 6.14991 1.5 7.08333V13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5Z" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='detailin d-flex-justify mt-4'>
                                                <div className='inleft'>
                                                    <label>Passcode</label>
                                                    <h4>{data?.giftCardPassCode}</h4>
                                                </div>
                                                <div className='copybtn'>
                                                    <button className='btn' onClick={(e) => copyTextToClipboard(data?.giftCardPassCode)}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.25 1.5H11.1667C13.0335 1.5 13.9669 1.5 14.68 1.86331C15.3072 2.18289 15.8171 2.69282 16.1367 3.32003C16.5 4.03307 16.5 4.96649 16.5 6.83333V12.75M4.16667 16.5H10.9167C11.8501 16.5 12.3168 16.5 12.6733 16.3183C12.9869 16.1586 13.2419 15.9036 13.4017 15.59C13.5833 15.2335 13.5833 14.7668 13.5833 13.8333V7.08333C13.5833 6.14991 13.5833 5.6832 13.4017 5.32668C13.2419 5.01308 12.9869 4.75811 12.6733 4.59832C12.3168 4.41667 11.8501 4.41667 10.9167 4.41667H4.16667C3.23325 4.41667 2.76654 4.41667 2.41002 4.59832C2.09641 4.75811 1.84144 5.01308 1.68166 5.32668C1.5 5.6832 1.5 6.14991 1.5 7.08333V13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5Z" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            {/* </Accordion.Body> */}
                        </Accordion.Item>
                        <Accordion className="modalaccordion" defaultActiveKey="0">
                            <Accordion.Item className='mt-3' eventKey="description">
                                <Accordion.Header>Description</Accordion.Header>
                                <Accordion.Body>
                                    <p
                                        className="post__content"
                                        dangerouslySetInnerHTML={{ __html: data?.giftCard?.description }}
                                    ></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='mt-3' eventKey="condition">
                                <Accordion.Header>Terms & Conditions</Accordion.Header>
                                <Accordion.Body>
                                    <p
                                        className="post__content"
                                        dangerouslySetInnerHTML={{
                                            __html: data?.giftCard?.termAndCondition,
                                        }}
                                    ></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='mt-3' eventKey="redeem">
                                <Accordion.Header>How to Redeem</Accordion.Header>
                                <Accordion.Body>
                                    <p
                                        className="post__content"
                                        dangerouslySetInnerHTML={{ __html: data?.giftCard?.howToRedeem }}
                                    ></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion>

                </div>
                <div className='cards-foot'>
                    {/* <p>How to redeem this
                        <Button onClick={GiftCardShow} className='cardpopup'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5763 6.49996C6.77222 5.94302 7.15893 5.47338 7.66793 5.17424C8.17693 4.87509 8.77538 4.76574 9.35728 4.86555C9.93918 4.96536 10.467 5.2679 10.8472 5.71957C11.2274 6.17124 11.4355 6.74289 11.4346 7.33329C11.4346 8.99996 8.93464 9.83329 8.93464 9.83329M9.0013 13.1666H9.00964M17.3346 8.99996C17.3346 13.6023 13.6037 17.3333 9.0013 17.3333C4.39893 17.3333 0.667969 13.6023 0.667969 8.99996C0.667969 4.39759 4.39893 0.666626 9.0013 0.666626C13.6037 0.666626 17.3346 4.39759 17.3346 8.99996Z" stroke="#98A2B3" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Button>
                    </p> */}
                </div>
            </div>

            {/* Modal */}
            {/* Calender Modals */}
            <Modal className="giftcard-modal" show={showgiftcard} onHide={GiftCardClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Steps to redeem your gift
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="EventPartially-list">
                        <li><span>1</span>Copy the Gift Card ID & Passcode.</li>
                        <li><span>2</span>Go to Add Gift Card section on your amazon app / website.</li>
                        <li><span>3</span>Enter in the details to claim your gift.</li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AllGiftCard;
