import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { AuthContext } from './../../Context/AuthContext';
import AuthAction from './../../Actions/auth.service';
import * as Joi from "joi-browser";
import { useNavigate } from "react-router-dom";
import { validateJOIProperty, validateJOIFormField, hideLoader, showLoader } from './../../utils/helpers';
import FieldError from "./../../../src/Components/Common/FieldError";
import { ThemeContext } from "../../hooks/useThemeContext";

const Contact = () => {
    const navigate = useNavigate();

    const { isLoggedIn, setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);
    const [details, setDetails] = useState({});
    const [errors, setErrors] = useState({});
    const [url, setUrl] = useState('/landing');
    const { showErrorToast, showSuccessToast, setBackUrlContext } = useContext(ThemeContext);

    useEffect(() => {
        setUrl(localStorage.getItem('redirectBackTo'));
        setBackUrlContext(`${localStorage.getItem('redirectBackTo') ?? "/getStarted"}`)
        localStorage.removeItem('redirectBackTo')
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            AuthAction.getUserData().then(res => {
                handleForm(res?.fullName, 'name');
                handleForm(res?.email, 'email');
            }).catch(err => {
                console.log(err)
                if (err?.response?.data?.message == "Unauthorized User.") {
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                    setIsSidebarVisible(false);

                    navigate('/');
                }
            });
        }
    }, []);
    const schema = {
        name: Joi.string().required().label("Name"),
        email: Joi.string().required().label("Email"),
        description: Joi.string().required().label("Desciption")
    };

    const handleForm = (e, name) => {
        let fieldName = name;
        let fieldValue = e;
        let errorData = { ...errors };
        const errorMessage = validateJOIProperty(schema, fieldName, fieldValue);
        if (errorMessage) {
            errorData[fieldName] = errorMessage;
        } else {
            delete errorData[fieldName];
        }
        setErrors(errorData);
        setDetails(existingValue => ({
            ...existingValue,
            [fieldName]: fieldValue
        }));
    };

    const submitHandler = () => {
        let errors = validateJOIFormField(details, schema);
        if (errors == null) {
            const data = new FormData();
            data.append("name", details?.name);
            data.append("description", details?.description);
            data.append("email", details?.email);
            showLoader();
            AuthAction.contactUsEmail(data).then(res => {
                hideLoader();
                showSuccessToast('Message Sent');
                setTimeout(() => {
                    navigate('/getStarted');
                }, 3000);
            }).catch(err => {
                showErrorToast("Failed to send Message");
                hideLoader();
                console.log(err)
            });
        } else {
            setErrors(errors);
        }
    }

    return (
        <>
            <div className="Contact-block">
                <h4 className="titleevent">Send feedback</h4>
                <Form>
                    {
                        isLoggedIn ?
                            <>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your Name" value={details?.name} readOnly disabled />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Your Email address</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" value={details?.email} readOnly disabled />
                                </Form.Group>
                            </>
                            :
                            <> <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your Name" onChange={(e) => handleForm(e?.target.value, "name")} value={details?.name} />
                                <FieldError error={errors.name} />
                            </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Your Email address</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" onChange={(e) => handleForm(e?.target.value, "email")} value={details?.email} />
                                    <FieldError error={errors.email} />
                                </Form.Group>
                            </>
                    }

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Feedback</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleForm(e?.target.value, "description")} value={details?.description} />
                        <FieldError error={errors.description} />
                    </Form.Group>
                    <button type='button' class="btn-pink" onClick={submitHandler}>Submit</button>
                </Form>
            </div>
        </>
    );
}

export default Contact;