import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../hooks/useThemeContext';
import BottomBtn from '../../Components/BottomToTop';

function Privacy() {
    const [url, setUrl] = useState('/landing');
    const { setBackUrlContext } = useContext(ThemeContext);
    useEffect(() => {
        setUrl(localStorage.getItem('redirectBackTo'));
        setBackUrlContext(`${localStorage.getItem('redirectBackTo') ?? "/getStarted"}`)
        localStorage.removeItem('redirectBackTo')
    }, [])

    return (
        <>
            <div className=' terms max-width-window '>
                <div className='body-wrapper'>
                    <div class="Headerbar"><ul><li><a class="backtobasic" href={`${url ?? "/getStarted"}`}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></a></li><li></li></ul></div>
                    <div >
                        <h2 className='titleevent text-center'>WEBSITE PRIVACY POLICY</h2>
                    </div>
                    <div >
                        <h4>SCOPE</h4>
                        <p>This website (“Platform”) is operated by a Private Limited Company, Radipro
                            Technologies India Pvt Ltd(“Radipro Technologies”).
                            This Privacy policy (“Privacy Policy”) succinctly describes how we collect and use your
                            information through a variety of digital means. You are advised to read the Privacy Policy
                            carefully and provide your consent as appropriate when accessing or using this website
                            on any computer, mobile phone, tablet, console or other device (collectively, “device”.
                            This Privacy Policy applies to current and former visitors of the Website. By way of the
                            Website, Radipro Technologies is inter alia engaged in the business of providing to its
                            customers including you, end to end stored value card solutions by managing gift cards
                            and facilitating distribution of gift cards through various channels (collectively
                            “Services”) This Privacy Policy is incorporated into, and is subject to, the Terms of Use.</p>
                        <h4>INFORMATION THAT IS OR MAY BE COLLECTED FROM YOU</h4>
                        <p>We may automatically receive and collect certain anonymous information in standard
                            usage logs through our server, including computer-identification information obtained
                            from "cookies" sent to your browser from various sources as detailed out in the Cookie
                            notice section. We may actively collect the following personally identifiable information
                            about you:</p>
                        <ol>
                            <li>
                                name including first and last name;
                            </li>
                            <li>
                                email address;
                            </li>
                            <li>
                                mobile phone number and contact details;
                            </li>
                            <li>
                                address and postal code;
                            </li>
                            <li>
                                Identity Proof;
                            </li>
                            <li>
                                opinions of features on this Website;
                            </li>
                            <li>
                                IP address
                            </li>
                            <li>
                                product / service preferences; and    </li>
                            <li>
                                other information may be collected as per our registration process and as
                                stipulated by applicable law, ancillary to availing of the different classes of
                                Services provided by us. We may also actively collect the following personal
                                data as below:    </li>
                            <li>
                                about the pages you visit/access on this Website;    </li>
                            <li>
                                the links you click on this Website;    </li>
                            <li>
                                the number of times you access a particular page on this Website; and    </li>
                            <li>
                                the number of times you have interacted on this Website.    </li>
                        </ol>
                        <p>
                            We may collect your name and billing address when you make payment for the Services.
                            All payment information barring the aforesaid (i.e. name and address) will be obtained
                            and processed by our online payment partners and payment gateways. They have
                            access to personal data needed to perform their functions,but may not use it for other
                            purposes. Further, they must process the personal data in accordance with this Privacy
                            Policy and as permitted by applicable law. </p>
                        <p>
                            We may work with third-party analytics services to help us understand how this Website
                            is being used, such as tracking the frequency and duration of use of the Sites. We may
                            use tracking tools including, but not limited to, Google Analytics, Google Webmaster,
                            browser cookies and web beacons to collect information about your use of this Site.
                            These Analytics Tools may use cookies to collect information about the content you view,
                            what websites you visit immediately prior to and after visiting this Site, and your system
                            information and geographic information. The information generated by these cookies
                            about your use of this Website will be transmitted to and stored by the applicable
                            analytics services. The information collected by these analytics services allows us to
                            analyse your use of this Site. The Analytics Tools may also transfer this information to
                            third parties where required to do so by law, or where such third parties process the
                            information on their behalf. You may refuse the use of cookies by selecting the
                            appropriate settings on your browser, however, please note that if you do this you may
                            not be able to use the full functionality of this Website.
                        </p>
                        <h4>METHOD OF COLLECTING INFORMATION</h4>
                        <p>We may collect information from you passively. We use tracking tools including, but not
                            limited to, Google Analytics, Google Webmaster, browser cookies and web beacons for
                            collecting information about your usage of the Website. Our advertisers may collect
                            anonymous traffic information from their own assigned cookies to your browser. This
                            Website may contain links to other websites. We are not responsible for the privacy
                            practices of websites which we do not own, manage or control.
                            Please find more details about our Cookie Policy here
                            - <Link to='/cookies' >https://www.wegroup4.com/cookie-policy</Link>
                            &nbsp; METHOD OF USING INFORMATION COLLECTED
                            We use your personal data to:</p>
                        <ol>
                            <li>
                                tailor-make our Website to suit your interest.
                            </li>
                            <li>
                                carry out our obligations arising from any contracts entered into between
                                you and us and to provide you with the information and Services that you
                                request from us;    </li>
                            <li>
                                respond to your inquiries or to process your requests in relation to your
                                information;    </li>
                            <li>
                                optimize the Website for you based on your usage pattern;    </li>
                            <li>
                                to get in touch with you when necessary;    </li>
                            <li>
                                carry out market research campaigns;    </li>
                            <li>
                                to preserve social history as governed by existing law or policy; and    </li>
                            <li>
                                For auditing and logging purposes    </li>
                            <li>
                                We use personal data internally to:    </li>
                            <li>
                                send you confirmations, notifications, acknowledgments and other details
                                pertaining to the Services you have placed on the Website;    </li>
                            <li>
                                administer our Website and for internal operations, including
                                troubleshooting, data analysis, testing, research, statistical and survey
                                purposes;    </li>
                            <li>
                                direct our efforts to improve features and facilities, including privacy
                                features; and    </li>
                            <li>
                                send you materials regarding:    </li>
                            <ol>
                                <li>
                                    upcoming offers; and/or
                                </li>
                                <li>
                                    any information that we deem material in order for you to be a
                                    fully informed user and customer of, and visitor to the Website.        </li>
                            </ol>
                        </ol>
                        <h4>SHARING OF INFORMATION</h4>
                        <p>We will not use information collected by you, for any purpose other than to complete a
                            transaction with you. We do not rent, sell or share your personal data and we will not
                            disclose any of your personally identifiable information to third parties unless:</p>
                        <ol>
                            <li>
                                we have your permission;
                            </li>
                            <li>
                                it is to provide and facilitate provision of the Services;    </li>
                            <li>
                                it is to help investigate, prevent or take action regarding unlawful and
                                illegal activities; suspected fraud, potential threat to the safety or security of
                                any person, violations of our Terms of Use or to defend against legal claims;    </li>
                            <li>
                                it is with a successor to all or part of our business;    </li>
                            <li>
                                special circumstances such as compliance with court orders,
                                requests/order, notices from legal authorities or law enforcement agencies
                                compel us to make such disclosure; and    </li>
                            <li>
                                it forms part of the anonymous information we share with our sub-
                                distributors and channel partners and/or advertisers on an aggregate basis.    </li>
                        </ol>
                        <h4>THIRD PARTY PLATFORMS</h4>
                        <p>You may be directed to third-party applications from our Website which we do not
                            control. This Privacy Policy solely and exclusively governs your usage and experience of
                            our Website. Accordingly, we undertake no liability whatsoever as regards your usage of
                            these third-party applications and you may visit them at your own risk. We strongly
                            encourage that you read the privacy policies of any third-party website / application you
                            are directed to before using the same.</p>
                        <p>
                            If you choose to use chat rooms or other message areas or leave feedback, we will
                            collect that information you provide to us. We retain this information as necessary to
                            resolve disputes, provide customer support and troubleshoot problems as permitted by
                            law.</p>
                        <p>
                            This Website and its contents do not give any party additional rights or remedies and
                            should not be construed as a binding agreement.
                            This section is to explain who will have access to your personal data. Your personal data
                            will only be seen or used by our employees or third parties who have a legitimate
                            business need to access your personal data for the purposes set out in this Privacy
                            Policy </p>
                        <p>
                            We may communicate your personal data to the following categories of third parties for
                            the purposes mentioned but not limited to in this notice:</p>
                        <ol>
                            <li>
                                service providers who provide us data centre services.
                            </li>
                            <li>
                                authorized authorities, agencies, or administrations, as required by
                                applicable local law such as respective IOCs’ of the country    </li>
                            <li>
                                e mail notification service providers, SMS service providers to send
                                transaction alerts to end customers    </li>
                            <li>
                                CRM tools used for Customer grievances and support issues    </li>
                            <li>
                                Helpdesk and chat support tools    </li>
                        </ol>
                        <p>
                            In case you require more specific details of this, you may write to us
                            at <a>support@wegroup4.com</a>
                            We may also pass aggregate information on the usage of our Website (e.g., we might
                            disclose the median ages of visitors to our Website, or the numbers of visitors to our
                            Website that come from different geographic areas) to third parties, but this will not
                            include information that can be used to identify you personally.
                            If a business transfer or change of business ownership takes place or is envisaged, we
                            may transfer your personal data to the new owner (or a prospective new owner). If this
                            happens, you will be informed of this transfer following the completion of such transfer or
                            change of business ownership.
                        </p>
                        <h4>
                            DUE DILIGENCE AND SAFEGUARDS
                        </h4>
                        <p>
                            Radipro Technologies uses reasonable physical, technical and administrative measures
                            to safeguard personal data you provide through the Website or in connection with its
                            Services.</p>
                        <p>
                            We apply these measures based on the sensitivity of the personal data we collect, use,
                            and store, and the current state of technology. We protect your personal data through
                            technical and organizational security measures to minimize risks associated with data
                            loss, misuse, unauthorized access, and unauthorize disclosure and alteration.
                            Unfortunately, the transmission of your personal data via the internet is not completely
                            secure and although we do our best to protect your personal data, we cannot guarantee
                            the security of your data transmitted to us over the internet and you acknowledge that
                            any transmission is at your own risk.</p>
                        <p>
                            Radipro Technologies uses commercially reasonable efforts to evaluate the data
                            protection practices of third parties that process electronic documents and files and will
                            or may have access to or process personal data. Radipro Technologies requires such
                            third parties to provide, at a minimum, the level of data protection required of Radipro
                            Technologies under applicable data protection laws and regulations, including, but not
                            limited to, the requirements to:
                        </p>
                        <ol>
                            <li>
                                Use commercially reasonable security measures in providing services to
                                Radipro Technologies to preserve the security, integrity, and confidentiality of
                                personal data, and to protect against unauthorized access and anticipated
                                threats or hazards to personal data;
                            </li>
                            <li>
                                Use personal data only for Radipro Technologies to provide its services,
                                and not process personal data for any other purpose;
                            </li>
                            <li>
                                Handle and maintain personal data in compliance with applicable data
                                privacy and protection laws, rules, and regulations;
                            </li>
                            <li>
                                Comply with obligations as required by all applicable data privacy and
                                protection laws, rules, and regulations;
                            </li>
                            <li>
                                Immediately notify Radipro Technologies about any actual or potential
                                security breach affecting personal data processed on behalf of Radipro
                                Technologies;
                            </li>
                            <li>
                                Assist and support Radipro Technologies in dealing with requests from
                                governmental authorities, data controllers, data subjects or data protection
                                authorities, as applicable;
                            </li>
                            <li>
                                Not transfer personal data to a third country unless expressly authorized to
                                do so by Radipro Technologies; and
                            </li>
                            <li>
                                Not engage another data processor without prior specific authorization of
                                Radipro Technologies.
                            </li>
                        </ol>
                        <h4>
                            PROCEDURE FOR CORRECTING INACCURACIES IN THE INFORMATION
                        </h4>
                        <p>
                            You may have certain rights related to your personal data, subject to local &
                            applicable data protection laws. These rights may include:
                        </p>
                        <ol>
                            <li>
                                You can access and review personal data associated with your account at
                                any time by sending us an e-mail to submit this request with us: e-mail
                                ID: support@wegroup4.com
                            </li>
                            <li>
                                You also can request the following information: how we collect and use
                                your personal data and why; the categories of personal data involved; the
                                categories of recipients of your personal data ; how we received your personal
                                data and its source; our business purpose for using your personal data ; and
                                how long we use or store your personal data  or the manner in which we
                                determine relevant retention periods.
                            </li>
                            <li>
                                You have a right to correct your personal data by sending us an e-mail to
                                submit this request with us: e-mail ID: support@wegroup4.com
                            </li>
                            <li>
                                In certain situations as per the applicable law, you can ask that we
                                delete/erase or stop using your personal data (and object to use of your
                                personal data ) or export your personal data . Please send us an e-mail to
                                submit this request with us: e-mail ID: support@wegroup4.com
                            </li>
                            <li>
                                Where we rely on your consent to process your personal data , you have
                                the right to decline consent and/or if provided, to withdraw consent at any time.
                                This will not affect the lawfulness of processing prior to the withdrawal of your
                                consent. At any time, you can request that we stop using your personal data for
                                direct marketing purposes. See our Cookie notice, How can I control my cookies
                                section of our Privacy Policy for more information on your choices.
                            </li>
                            <li>
                                You have a right to provide us with guidance on the use, storage, and
                                deletion of your personal data  after your death
                            </li>
                            <li>
                                You have a right to raise questions or complaints with your local data
                                protection authority at any time.
                            </li>
                        </ol>
                        <p>
                            We will take reasonable steps to verify your identity. If you have an account with us, we
                            may verify you through your login of your account. If you do not have an account with us,
                            we may seek a confirmation by email or seek other identification information, including
                            government-issued identification, to verify your identity.
                            You may authorize an agent to make a request to us on your behalf and we will verify the
                            identity of your agent or authorized legal representative by either seeking confirmation
                            from you or documents that establish the agent’s authorization to act on your behalf.
                            Please note your rights and choices vary depending upon your location. Certain personal
                            data may be exempt from such requests under applicable law. We need certain types of
                            personal data so that we can provide the Services to you. If you ask us to delete it, you
                            may no longer be able to access or use our Services.
                            Please send an e-mail to: support@wegroup4.com
                        </p>
                        <h4>
                            HOW LONG DO WE KEEP YOUR PERSONAL DATA?
                        </h4>
                        <p>
                            We will keep your personal data for no longer than necessary, for the purposes we have
                            set out above. We will retain your information for as long as needed in order to provide
                            the relevant Services to you or perform any contract we have with you and for a limited
                            period afterwards in order to deal with any queries or complaints.
                            Any third parties that we engage will keep your data stored on their systems for as long
                            as is necessary to provide the relevant Services to you or us. If we end our relationship
                            with any third party providers, we will make sure that they securely delete or return your
                            personal data to us.</p>
                        <p>
                            We may retain personal data about you for statistical purposes (for example, to help us
                            better advertise our Services). Where data is retained for statistical purposes it will
                            always be anonymized, meaning that you will not be identifiable from that data.
                        </p>
                        <h4>POLICY UPDATES</h4>
                        <p>This Privacy Policy was last updated on 01-JUN 2023. We reserve the right to change or
                            update this policy at any time. Such changes shall be effective immediately upon posting
                            on this Website. Please check it regularly on our Website(s) for any updates.</p>
                        <h4>GRIEVANCE OFFICER</h4>
                        <p>In accordance with the Indian Information Technology Act, 2000 and the rules made
                            thereunder, the name and contact details of the grievance redressal officer are provided
                            below: Mr. Sumesh Kampassi, ,  NK-I/605, FF-2, Indirapuram, Ghaziabad, Uttar Pradesh -
                            201014
                        </p>
                        <p>Email: support@wegroup4.com . If you have any other questions about this Privacy Policy
                            or other privacy concerns, you can also email us at support@wegroup4.com</p>
                        <h4>
                            JURISDICTION
                        </h4>
                        <p>If you choose to visit the Website, your visit and any dispute over privacy is subject to
                            this Privacy Policy and the Website’s Terms of Use. Regardless of where you live, you
                            consent to have your personal data transferred, processed and stored in India. In
                            addition to the foregoing, any disputes arising under this Privacy Policy shall be governed
                            by the laws of India and the courts at Bangalore shall have exclusive jurisdiction.</p>
                        <h4>
                            TERMS OF USE
                        </h4>
                        <p>
                            Your use of and access of the Website including any disputes arising therefrom, is subject
                            to this Privacy Policy as well as our Terms of Use. Please read our Terms of Use
                            - https://www.wegroup4.com/termsofuse carefully in order to obtain information on the
                            terms governing the use of and access to the Website.
                        </p>
                    </div>


                </div>
            </div >
            <BottomBtn/>
        </>
    );
};

export default Privacy;
