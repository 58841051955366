import '../src/Css/style.css';
import OnBoard from './Onboard/Index';
import Login from './Auth/Login';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import SiteLayout from './Components/Layout/SiteLayout';
import OnBoardLayout from './Components/Layout/OnBoardLayout';
import EnterOtp from './Auth/EnterOtp';
import InfoFill from './Auth/InfoFill';
import Home from './Pages/Home';
import EventPartially from './Components/Home-Screen/EventPartially';
import HomeLayout from './Components/Layout/HomeLayout';
import EventStep1 from './Pages/Events/EventStep1';
import EventStep2 from './Pages/Events/EventStep2';
import EventStep3 from './Pages/Events/EventStep3';
import EventSuccess from './Pages/Events/EventSuccess';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import PaymentFailed from './Pages/Payment/PaymentFailed';
import ProfileLayout from './Components/Layout/ProfileLayout.js';
import Profile from './Pages/Profile/Index';
import ProfileMain from './Pages/Profile/ProfileMain';
import EditProfile from './Pages/Profile/EditProfile';
import AddTestimonial from './Components/Testimonials/AddTestimonial';
import TestimonialList from './Components/Testimonials/TestimonialList';
import TestimonialListRecipient from './Components/Testimonials/TestimonialListRecipient';
import ContributionPage from './Pages/ContributionPage';
import { useEffect, useState } from 'react';
import ContriAccordion from './Components/Contribution/ContriAccordion';
import FixedAmount from './Pages/ContributionPage/FixedAmount';
import AnyAmount from './Pages/ContributionPage/AnyAmount';
import Organiser from './Pages/Dashboards/Organiser';
import Recepient from './Pages/Dashboards/Recepient';
import OpenGiftCard from './Components/GiftCard/OpenGiftCard';
import AllGiftCard from './Components/GiftCard/AllGiftCard';
import WellWisher from './Components/WellWishers';
import OrganiserDashboard from './Pages/Dashboards/Organiser/OrganiserDashboard';
import SelectGiftCard from './Components/GiftCard/SelectGiftCard';
import GiftcardInput from './Components/GiftCard/GiftcardInput';
import ParticipantList from './Pages/Participant-List/Index';
import RecipientntList from './Pages/Recipient-List/index';
import ContributionList from './Pages/Contribution-List/Index';
import Invite from './Pages/Invite.js';
import { AuthContext } from "./Context/AuthContext";
import { useContext } from 'react';
import Participant from "./Pages/Dashboards/Participant/index";
import { showLoader } from "./utils/helpers";
import Audio from './Components/Audio';
import UserPerviewList from './Components/UserPerviewList';
import EventCancelState from './Components/EventCancelState/Index';
import EventCancleUnsuccessful from './Components/EventCancelState/EventCancleUnsuccessful';
import LandingPageLayout from './Components/Layout/LandingPageLayout';
import Landing from './Pages/Landing';
import Contact from './Pages/Contact';
import LandingAboutUs from './Pages/Landing/landingaboutus';
import Terms from './Pages/Terms'
import Privacy from './Pages/Privacy'
import Cookies from './Pages/Cookies';
import AllFaq from './Pages/FAQ/AllFaq';
import TotalContribute from './Components/TotalContribute/Index';
import LandingBlogs from './Pages/Landing/landingblogs';


function AppRouter() {
  const { isLoggedIn } = useContext(AuthContext);

  const history = useNavigate();

  useEffect(() => {
    document.body.setAttribute("data-page", window?.location?.pathname);
  }, [history]);

  let inititalEventState = {
    name: "",
    occasion: "",
    date: "",
    time: "",
    phone: "",
    email: "",
    icon: "",
    eventDescription: "",
    timeZone: "",
    eventRawDate:"",
    eventRawTime:"",
  }

  const [event, setEvent] = useState(inititalEventState);

  let location = useLocation();

  useEffect(() => {
    if(!location.search.toString().includes("contribute=true")){
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>

      <Routes>
        <Route element={<LandingPageLayout />}>
          <Route exact="true" path='/landing' element={<Landing />} />
          <Route exact="true" path='/landingaboutus' element={<LandingAboutUs />} />
          <Route exact="true" path='/landingblogs' element={<LandingBlogs />} />
          <Route exact="true" path='/privacy' element={<Privacy />} />
          <Route exact="true" path='/terms' element={<Terms />} />
          <Route exact="true" path='/cookies' element={<Cookies />} />
          <Route exact="true" path='/faqs' element={<AllFaq />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route exact="true" path='/getStarted' element={<Home event={event} setEvent={setEvent} />} />
          {/* <Route exact="true" path='/home' element={<Home />} /> */}
          <Route exact="true" path='/invitation/:id' element={<Invite />} />
        </Route>
        <Route element={<SiteLayout />}>
          <Route exact="true" path='/recipient/:id' element={<Recepient />} />
          <Route exact="true" path='/wellwisher/:id' element={<WellWisher />} />
        </Route>
        {
          isLoggedIn ?
            <>
              <Route element={<SiteLayout />}>
                <Route exact="true" path='/eventstep1' element={<EventStep1 event={event} setEvent={setEvent} />} />
                <Route exact="true" path='/eventstep3' element={<EventStep3 event={event} setEvent={setEvent} />} />
                <Route exact="true" path='/eventstep2' element={<EventStep2 event={event} setEvent={setEvent} />} />
                <Route exact="true" path='/eventsuccess' element={<EventSuccess />} />
                <Route exact="true" path='/editprofile' element={<EditProfile />} />
                <Route exact="true" path='/testimoniallist/:id' element={<TestimonialList />} />
                <Route exact="true" path='/testimoniallistRec/:id' element={<TestimonialListRecipient />} />
                <Route exact="true" path='/contriaccordion' element={<ContriAccordion />} />
                <Route exact="true" path='/contributionpage/:id' element={<ContributionPage />} />
                <Route exact="true" path='/contributionlist/:id' element={<ContributionList />} />
                <Route exact="true" path='/organiser/:id' element={<Organiser />} />
                <Route exact="true" path='/participant/:id' element={<Participant />} />
                <Route exact="true" path='/organiserdashboard' element={<OrganiserDashboard />} />
                <Route exact="true" path='/opengiftcard' element={<OpenGiftCard />} />
                <Route exact="true" path='/allgiftcard/:id' element={<AllGiftCard />} />
                <Route exact="true" path='/wellwisher' element={<WellWisher />} />
                <Route exact="true" path='/participantlist/:id' element={<ParticipantList />} />
                <Route exact="true" path='/recipientlist' element={<RecipientntList />} />
                <Route exact="true" path='/gift-goal/:id' element={<GiftcardInput />} />
                <Route exact="true" path='/selectgiftcard/:id' element={<SelectGiftCard />} />
                <Route exact="true" path='/EventPartially' element={<EventPartially />} />
                <Route exact="true" path='/audio/:id' element={<Audio />} />
                <Route exact="true" path='/userperviewlist' element={<UserPerviewList />} />
                <Route exact="true" path='/paymentsuccess/:id' element={<PaymentSuccess />} />
                <Route exact="true" path='/paymentfailed/:id' element={<PaymentFailed />} />
                <Route exact="true" path='/eventcancelstate' element={<EventCancelState />} />
                <Route exact="true" path='/eventcancleunsuccessful' element={<EventCancleUnsuccessful />} />
                <Route exact="true" path='/totalcontribution/:id' element={<TotalContribute />} />
                {/* <Route exact="true" path='/addmedia/:id' element={<AddMedia />} /> */}
                <Route exact="true" path='/contact' element={<Contact />} />
              </Route>
              <Route path="*" element={<Navigate to="/getStarted" />} />


            </> : <>
              <Route element={<SiteLayout />}>
                <Route exact="true" key="login" path='/login' element={<Login />} />
                <Route exact="true" path='/enterotp' element={<EnterOtp />} />
                <Route exact="true" path='/infofill' element={<InfoFill />} />
                <Route exact="true" path='/contact' element={<Contact />} />
                <Route exact="true" path='/eventstep1' element={<EventStep1 event={event} setEvent={setEvent} />} />
                <Route exact="true" path='/eventstep3' element={<EventStep3 event={event} setEvent={setEvent} />} />
                <Route exact="true" path='/eventstep2' element={<EventStep2 event={event} setEvent={setEvent} />} />
              </Route>


              <Route element={<ProfileLayout />}>
                <Route exact="true" path='/profile' element={<Profile />} />
                <Route exact="true" path='/profilemain' element={<ProfileMain />} />
                <Route exact="true" path='/fixedamount' element={<FixedAmount />} />
                <Route exact="true" path='/anyamount' element={<AnyAmount />} />
              </Route>

              <Route path="*" element={<Landing />} />

            </>}
        {/* <Route path="*" element={<Navigate to="/home" />} /> */}

        <Route element={<OnBoardLayout />}>
          <Route exact="true" path='/works' element={<OnBoard />} />
        </Route>

      </Routes>
    </>
  );
}

export default AppRouter;