import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { AuthContext } from "../../Context/AuthContext";
import { showLoader, hideLoader, withBackendUrl } from "../../utils/helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ThemeContext } from "../../hooks/useThemeContext";
import EventAction from "../../Actions/event.service";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import GiftcardInput from "./GiftcardInput";
import BottomBtn from "../BottomToTop";
import useSoloEvent from "../../hooks/useSoloEvent";

function SelectGiftCard() {
  // Gift Modal Open
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isSolo = useSoloEvent();
  const { backUrlContext, setBackUrlContext } = useContext(ThemeContext);
  const { eventData, setIsLoggedIn, setIsSidebarVisible } =
    useContext(AuthContext);
  const { showSuccessToast } = useContext(ThemeContext);

  const [Giftshow, setGiftShow] = useState(false);
  const [giftModal, setGiftModal] = useState([]);
  const [giftCardType, setGiftCardType] = useState("");
  const [giftCardTypeList, setGiftCardTypeList] = useState([]);
  const [giftCardTypelistOriginal, setGiftCardTypelistOriginal] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setBackUrlContext(`/${localStorage.getItem("role") ?? "organiser"}/${id}`);
    getGiftCards();
  }, []);

  const handleGiftClose = () => setGiftShow(false);
  const handleGiftShow = (giftId) => {
    setGiftModal(giftId);
    setGiftShow(true);
  };

  const getGiftCards = () => {
    showLoader();
    EventAction.getGiftCards()
      .then((res) => {
        // const target = searchParams.get('target') ?? 0;
        // const filteredGiftCards = res.filter((item) => parseInt(item?.maximumAmount) >= target);
        // setGiftCardTypeList([...giftCardTypeList, ...filteredGiftCards]);
        setGiftCardTypeList(res);
        setGiftCardTypelistOriginal(res);
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);
          navigate("/");
        }
        hideLoader();
      });
  };

  const submitgiftCardType = async () => {
    showLoader();
    const data = new FormData();
    data.append("giftCardType", giftCardType);
    EventAction.selectGiftCard(data, eventData?._id)
      .then((res) => {
        hideLoader();
        showSuccessToast("Gift Card has been Selected");
        navigate(`/organiser/${eventData?._id}`);
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  const handleSearchChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchValue(inputValue);
    let duplicateGiftCardTypeList = [...giftCardTypelistOriginal];
    const filteredGiftCards = duplicateGiftCardTypeList.filter((giftCard) =>
      // giftCard.name.toLowerCase().includes(inputValue)
      giftCard.name.toLowerCase().startsWith(inputValue)
    );
    setGiftCardTypeList(filteredGiftCards);
  };

  const handleCloseModal = (e) => {
    setShowModal(false);
  }

  return (
    <>
      <h4 className="titleevent">Gift Options</h4>
      <div className="SelectGiftCard">
        <h4>Select Gift Card</h4>
        <input
          type="text"
          className="form-control"
          placeholder="Search.."
          onChange={(e) => handleSearchChange(e)}
          value={searchValue}
        />
        <p>Currently we allow to choose only one gift card</p>
        <div className="allcardslist">
          {giftCardTypeList.length > 0 ? (
            giftCardTypeList.map((element, index) => (
              <>
                <label
                  className={
                    element?.isPriority
                      ? "CardCustomcheck topLabel"
                      : "CardCustomcheck"
                  }
                >
                  <Form.Control
                    type="radio"
                    name="radio"
                    value={element?.name}
                    onChange={(e) => setGiftCardType(e?.target?.value)}
                    onClick={(e) => handleGiftShow(element)}
                  />
                  <div className="check-detail">
                    <div className="carddetail">
                      <img src={withBackendUrl(element?.icon)} alt="giftlogo" />
                    </div>
                    <div className="cardheaderbar">
                      <div className="cardheader">
                        {/* <div className="circle">
                                                        <span className="dot"></span>
                                                    </div> */}
                        {element?.name}
                      </div>
                      {/* <button
                                                className="viewbtn"
                                                type="button"
                                                onClick={(e) => handleGiftShow(element)}
                                            >
                                                View Details
                                            </button> */}
                    </div>
                  </div>
                  <span className="checkmark"></span>
                </label>
              </>
            ))
          ) : (
            <div className="when-no-wish"> No Gift Cards Found</div>
          )}
        </div>
        <BottomBtn />
      </div>

      <Modal
        className="Createevent-modal giftcardmodal"
        show={Giftshow}
        onHide={handleGiftClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Gift Card Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalgiftcard-wrapper">
            <div className="gift-img">
              <img
                src={withBackendUrl(giftModal?.icon)}
                alt="gift card image"
              />
            </div>
            <div className="denominatebar">
              <h5>Denomination</h5>
              <ul>
                <li>
                  Minimum ₹
                  {parseFloat(giftModal?.minimumAmount).toLocaleString("en-US")}
                </li>
                <li>
                  Maximum ₹
                  {parseFloat(giftModal?.maximumAmount).toLocaleString("en-US")}
                </li>
              </ul>
            </div>
            <Accordion className="modalaccordion" defaultActiveKey="0">
              <Accordion.Item eventKey="description">
                <Accordion.Header>Description</Accordion.Header>
                <Accordion.Body>
                  <p
                    className="post__content"
                    dangerouslySetInnerHTML={{ __html: giftModal?.description }}
                  ></p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="condition">
                <Accordion.Header>Terms & Conditions</Accordion.Header>
                <Accordion.Body>
                  <p
                    className="post__content"
                    dangerouslySetInnerHTML={{
                      __html: giftModal?.termAndCondition,
                    }}
                  ></p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="redeem">
                <Accordion.Header>How to Redeem</Accordion.Header>
                <Accordion.Body>
                  <p
                    className="post__content"
                    dangerouslySetInnerHTML={{ __html: giftModal?.howToRedeem }}
                  ></p>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="offers">
                <Accordion.Header>Offers</Accordion.Header>
                <Accordion.Body>
                  <p
                    className="post__content"
                    dangerouslySetInnerHTML={{ __html: giftModal?.offers }}
                  ></p>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
            <br />
            <Button
              type="button"
              className="btn-pink"
              onClick={(e) => {
                if (!isSolo) {
                  setShowModal(true);
                  handleGiftClose();
                } else {
                  submitgiftCardType()
                }
              }}
            >
              Confirm gift for {eventData?.name}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="faviconadded" closeButton>
          weGroup
        </Modal.Header>
        <Modal.Body>
          Alert: When selecting a gift card from the catalog in our beta version, please be aware that the chosen gift card cannot be changed after sending invitation to participants. Ensure group consensus before making a choice. Continue?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={submitgiftCardType} className="btn-pink">Yes</button>
          <Button className='btngray' onClick={handleCloseModal} type="button">No</Button >
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectGiftCard;
