import React, { useEffect, useState, useContext } from "react";
import EventStates from "../../Components/EventStates/Index";
import FaqPage from "../../Components/FAQ's/Index";
import Footer from "../../Components/Footer/Index";
import GroupGiftBox from "../../Components/GroupGiftBox/Index";
import HomeHeader from "../../Components/Header/HomeHeader";
import EventBox from "../../Components/Events/EventBox";
import FirstTimeUser from "../../Components/Home-Screen/FirstTimeUser";
import TestimonialSlider from "../../Components/Testimonials/TestimonialSlider";
import AuthAction from "../../Actions/auth.service";
import { AuthContext } from "../../Context/AuthContext";
import { hideLoader, showLoader } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Home = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("redirectBackTo", "/home");
    localStorage.removeItem('backToHome')
  }, []);

  const { setEvent } = props;
  const [allEvents, setAllEvents] = useState([]);
  const { isLoggedIn, setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);

  useEffect(() => {
    setEvent({
      name: "",
      occasionId: "",
      date: "",
      time: "",
      phone: "",
      email: "",
      icon: "",
      eventDescription: "",
    });
  }, [allEvents]);

  useEffect(() => {
    showLoader();
    if (isLoggedIn) {
      AuthAction.getEventData()
        .then((res) => {
          setAllEvents(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem('token');
            toast.dismiss();
            setIsLoggedIn(false);
            setIsSidebarVisible(false);
            navigate('/');
          }
          hideLoader();
        });
    } else {
      hideLoader();
    }
    localStorage.removeItem('backToEvent')
  }, [isLoggedIn]);

  return (
    <>
      <div className="homebar">
        <HomeHeader />
        {allEvents.length > 0 ? (
          <>
            {allEvents.map((item) => {
              return (
                <>
                  <EventBox event={item} />
                </>
              );
            })}
          </>
        ) : null}
        <FirstTimeUser showEventHints={allEvents.length <= 0 ? true : false} />
        <GroupGiftBox />
      </div>
      <FaqPage />
      {/* <EventStates /> */}
      <TestimonialSlider />
      <Footer />
    </>
  );
};

export default Home;
