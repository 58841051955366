import React from 'react';
import { BallTriangle } from 'react-loader-spinner'


const Loader = (props) => {
    return (
        <div className='loaderbar'>
            <BallTriangle
                height={80}
                width={80}
                radius={5}
                color="#e32854"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    );
}

export default Loader;