import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Calendar from 'react-calendar';
import TimeKeeper from 'react-timekeeper';
import { Link } from 'react-router-dom';
import { haveValue, isNotEmpty, isValidDate } from '../../utils/helpers';
import FieldError from '../Common/FieldError';
import Moment from 'moment';

function DateTimeBox(props) {
  const [value, onChange] = useState(new Date());
  const [showcalenderbar, setCalenderShow] = useState(false);
  const [showTimebar, setTimeShow] = useState(false);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [selectedTime, setSelectedTime] = useState(''); // Track selected time here

  function subOneDay(date = new Date()) {
    date.setDate(date.getDate() - 1);
    return date;
  }

  const dateCurr = new Date();
  const result = subOneDay(dateCurr);

  function tileDisabled({ activeStartDate, date, view }) {
    return new Date(date) < new Date(result);
  }

  const printDate = () => {
    if (isValidDate(props?.date)) {
      return Moment(props?.date).format("LL");
    } else {
      return "Select Date";
    }
  }

  useEffect(() => {
    let dt = new Date();
    const selectedDate = new Date(props?.date);
    if (isValidDate(props?.date)) {
      if (Moment(selectedDate).format("LL") === Moment(dt).format("LL")) {
        setFrom(`${dt.getHours()}:${dt.getMinutes()}`);
        setTo('23:59');
      } else {
        setFrom('00:00');
        setTo('23:59');
      }
    }
  }, [props?.date]);

  useEffect(() => {
    if (haveValue(props?.time)) {
      setSelectedTime(props?.time)
    } else {
      setSelectedTime("")
    }
  }, [props])


  // Handle time selection and update selectedTime state
  const handleTimeSelection = (newTime) => {
    // setIsValid(newTime.isValid);
    setSelectedTime(newTime.formatted);
  };

  const handleTimeModalClose = () => {
    setTimeShow(false);
  };

  const handleApplyTime = () => {
    if (!haveValue(selectedTime) || selectedTime == '12:00 pm')
      props.handleChangeTime("12:00 pm");
    else
      props.handleChangeTime(selectedTime);
    handleTimeModalClose();
  };

  return (
    <>
      <div className='datetimebox'>
        <Form.Group className="formgroup" controlId="formBasicEmail">
          <h4>
            <div className='leftbar'>
              <Form.Label>When is the occasion?</Form.Label>
            </div>
          </h4>
          <div className="datetimepicker">
            <Button onClick={() => setCalenderShow(true)} className="btngray">
              {printDate()}
            </Button>
            <FieldError error={props?.errors?.date} />
            <Button onClick={() => setTimeShow(true)} className="btngray">
              {selectedTime || "Select Time"}
            </Button>
            <FieldError error={props?.errors?.time} />
          </div>
        </Form.Group>
      </div>

      {/* Calendar Modal */}
      <Modal className="datepicker-modal" show={showcalenderbar} onHide={() => setCalenderShow(false)} centered>
        <Modal.Body>
          <Calendar tileDisabled={tileDisabled} onChange={(e) => props.handleChangeDate(e)} value={props?.date ? new Date(props.date) : null} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={() => setCalenderShow(false)}>
            Cancel
          </Button>
          <Button className="btn-pink" onClick={() => setCalenderShow(false)}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Time Modal */}
      <Modal className="datepicker-modal" show={showTimebar} onHide={handleTimeModalClose} centered>
        <Modal.Body>
          <div className="timebar">
            <TimeKeeper
              onChange={handleTimeSelection}
              switchToMinuteOnHourSelect
              // disabledTimeRange={{ from: from, to: to }}
              time={selectedTime || '12:00 pm'} // Set the initial time
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={handleTimeModalClose}>
            Cancel
          </Button>
          <Button className="btn-pink" onClick={handleApplyTime}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default DateTimeBox;
