import React from 'react';
import FaqAccordion from './FaqAccordion';
import FaqQuestionBox from './FaqQuestionBox';
import FaqTitle from './FaqTitle';

function FaqPage() {
  return (
      <>
      <div className='FaqPage'>
        <FaqTitle/>
        <FaqAccordion/>
        <FaqQuestionBox/>
      </div>
      </>
  );
}

export default FaqPage;
