import React from "react";



const UploadImage = (props) => {
    const { editProfile = false } = props
    return (
        <>
            <div className="mediablock">
                {
                    props.icon ?

                        <><div className="uploadmedia imageuploaded">
                            <img src={URL.createObjectURL(props?.icon)} alt="img" />
                            <input type='file' className='uploadinput' accept="image/*" onChange={(e) => props?.handleChangeIcon(e.target.files[0])} />
                        </div></>
                        :
                        <div className="uploadmedia">
                            <div className="image-up">
                                <input type='file' className='uploadinput' accept="image/*" onChange={(e) => props?.handleChangeIcon(e.target.files[0])} />
                                <span>Add Photo</span>
                            </div>
                            <div className="upload-text">
                                <h5>Tap to select</h5>
                                {
                                    editProfile ?
                                        <p>{`Upload ${props?.name} Photo`}</p>
                                        :
                                        <p>{`Upload ${props?.name} Photo (Optional)`}</p>
                                }
                            </div>
                        </div>
                }

            </div>
        </>
    );
}

export default UploadImage;