import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function TestimonialSlider() {
  return (
    <>
      <div className='Testimonials'>
        <h4>Don’t just take our word for it</h4>
        <p>Hear from some of our amazing people who are using weGroup</p>
        <Carousel>
          <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>Using weGroup made group gifting a breeze! Coordinating with friends to contribute for a gift was seamless and hassle-free.</h5>
              <div className='userdetail'>
                <img src='/images/meenakshi.jpeg' />
                <div class="detail-content">
                  <h6>Meenakshi Sharma</h6>
                <p>Delhi</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>weGroup simplified organizing group gifts. The platform's features streamlined the process, making it easy to manage contributions and keep everyone in the loop.</h5>
              <div className='userdetail'>
                <img src='/images/koshni.jpeg' />
                <div class="detail-content">
                  <h6>Koshni Sharma</h6>
                <p>Delhi</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>Receiving a collective gift through weGroup was fantastic! It felt personalised, and I appreciated the effort everyone put into making it special. Definitely a great experience.</h5>
              <div className='userdetail'>
                <img src='/images/vipul.jpeg' />
                <div class="detail-content">
                  <h6>Vipul Manchanda</h6>
                <p>Gurgaon</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>weGroup took the stress out of organizing group gifts. The real-time updates and communication tools helped me coordinate effortlessly, ensuring everyone felt involved and excited about the gift.</h5>
              <div className='userdetail'>
                <img src='/images/mayank.jpeg' />
                <div class="detail-content">
                  <h6>Mayank Gangrade</h6>
                <p>Delhi</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>232 of my friends wished me on my birthday, it was a great experience.</h5>
              <div className='userdetail'>
                <img src='/images/avatar-180.png' />
                <div class="detail-content">
                  <h6>Ruturaj Das Gupta</h6>
                <p>Bangalore</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='Testimonial-block'>
              <img src="/images/Stars.png" alt='stars' />
              <h5 className='review'>23 of my friends wished me on my birthday, it was a great experience.</h5>
              <div className='userdetail'>
                <img src='/images/girl.png' />
                <div class="detail-content">
                  <h6>Ruturaj Das Gupta</h6>
                <p>Bangalore</p>
                </div>
              </div>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
    </>
  );
}

export default TestimonialSlider;
