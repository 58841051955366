import React, { useContext, useEffect, useState } from "react";
import EventOwnerBox from "./EventOwnerBox";
import EventParticipantBox from "./EventParticipantBox";
import { AuthContext } from "../../Context/AuthContext";
import { haveValue } from "../../utils/helpers";

const EventBox = (props) => {
  const { loggedInUserId } = useContext(AuthContext);
  const { event } = props;
  const [isOrganiser, setIsOrganiser] = useState(false);

  useEffect(() => {
    if (haveValue(event?.createdBy?._id) && haveValue(loggedInUserId)) {
      checkRole();
    }
  }, [event, loggedInUserId]);

  const checkRole = () => {
    console.log(
      event?.createdBy?._id,
      "====event?.createdBy?._id===",
      loggedInUserId,
    );
    if (event?.createdBy?._id === loggedInUserId) {
      console.log("Inside organiser");
      setIsOrganiser(true);
    }
  };

  // const isCurrentUserEventOwner = haveValue(event?.createdBy) && haveValue(loggedInUserId) && loggedInUserId === event?.createdBy?._id;
  console.log(event?._id, isOrganiser, `checkOwnerShip${event?._id}`)
  if (haveValue(isOrganiser) && isOrganiser) {
    return <EventOwnerBox event={event} />;
  } else {
    return <EventParticipantBox event={event} />;
  }
};

export default EventBox;
