import React from 'react';
import { Link } from 'react-router-dom';

function FaqQuestionBox() {
  return (
    <>
      <div className='FaqQuestionBox'>
        <h4>Have a feedback for us?</h4>
        {/* <p>Can’t find the answer you’re looking for? Please get in touch with our friendly team.</p> */}
        <Link to='/contact' className='callus'>Send feedback</Link>
      </div>
    </>
  );
}

export default FaqQuestionBox;
