import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { BiPencil } from 'react-icons/bi';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { haveValue, hideLoader, showLoader, validateJOIFormField, validateJOIProperty } from "./../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import * as Joi from "joi-browser";
import FieldError from "./../../../src/Components/Common/FieldError";
import EventAction from "../../Actions/event.service";
import { ThemeContext } from "../../hooks/useThemeContext";
import Moment from 'moment';
import { AuthContext } from "../../Context/AuthContext";

const EventStep3 = (props) => {

    const navigate = useNavigate();

    const { event, setEvent } = props;
    const [errors, setErrors] = useState({});
    const { isLoggedIn, setEventData } = useContext(AuthContext);
    const { showErrorToast, setBackUrlContext } = useContext(ThemeContext);

    const schema = {
        eventDescription: Joi.string().required().label("Event Description"),
    };

    useEffect(() => {
        var inputValue = "";

        if (event?.occasionId == 'Anniversary') {
            inputValue = `Join us in celebrating ${event?.firstName} ${event?.lastName}'s anniversary!Your participation is valued, and together, we'll make this celebration even more special.`
        }
        else if (event?.occasionId == 'Graduation') {
            inputValue = `Celebrate a milestone with us! Join in creating a special graduation gift for ${event?.firstName} ${event?.lastName}. Let's honor their achievement together!`
        }
        else if (event?.occasionId == 'Baby Shower') {
            inputValue = `Celebrate the upcoming arrival with us! Join in creating a special baby shower gift for ${event?.firstName} ${event?.lastName}. Let's shower them with love!`
        }
        else if (event?.occasionId == 'Farewell') {
            inputValue = `Join us in bidding farewell to ${event?.firstName} ${event?.lastName}! Your participation will make this farewell a heartfelt moment. Let's wish them success in their new chapter!`
        }
        else if (event?.occasionId == 'Promotion') {
            inputValue = `Join us in celebrating the success of ${event?.firstName} ${event?.lastName}! Your participation will add to the joy of this significant accomplishment. Let's come together to honor their hard work and achievement!`
        }
        else if (event?.occasionId == 'House Warming') {
            inputValue = `Celebrate the warmth of a new home with us! Join in creating a special housewarming gift for ${event?.firstName} ${event?.lastName}. Let's share in the excitement of this fresh start!`
        }
        else if (event?.occasionId == 'Birthday') {
            inputValue = `Celebrate another year of ${event?.firstName} ${event?.lastName}'s life with us! Your presence will add to the joy of their celebration. Let's make it a birthday to remember!`
        }
        else if (event?.occasionId == 'Wedding') {
            inputValue = `Celebrate the beginning of a beautiful journey! Your participation will add to the joy of this significant milestone.Let's toast to their happiness!`
        }
        else {
            inputValue = `We are celebrating ${event?.firstName} ${event?.lastName}'s ${event?.occasionId}. Please join to celebrate and make this event memorable.`
        }

        setBackUrlContext(`/eventstep2`);
        if (haveValue(inputValue))
            handleForm(inputValue, "eventDescription")
    }, [])


    const eventSuccess = () => {
        let errors = validateJOIFormField(event, schema);
        if (errors == null) {
            if (isLoggedIn) {
                const selectedDate = new Date(event?.date.concat(", ", event?.time))
                const utcTime = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000)
                const data = new FormData();
                data.append("name", event?.firstName + " " + event?.lastName);
                data.append("eventDescription", event?.eventDescription);
                data.append("email", event?.email);
                data.append("occasionId", event?.occasionId);
                // data.append("eventStart", Moment(event?.date).format("LL"));
                // data.append("eventTime", event?.time);
                data.append("eventStart", Moment(utcTime).format("LL"));
                data.append("eventTime", Moment(utcTime).format("h:mm A"));
                data.append("eventRawDate", event?.eventRawDate);
                data.append("eventRawTime", event?.eventRawTime);
                data.append("phone", event?.phone);
                data.append("timeZone", event?.timeZone);
                data.append("isSolo", localStorage.getItem("soloGift"));

                if (event?.icon) {
                    data.append("file", event?.icon);
                }
                showLoader();
                EventAction.createEvent(data).then(res => {
                    setEventData(res);
                    hideLoader();
                    navigate('/eventSuccess');
                }).catch(err => {
                    showErrorToast("Failed to create Event");
                    hideLoader();
                    console.log(err)
                });
            } else {
                const temp_event = { ...event }
                temp_event.isSolo = localStorage.getItem("soloGift")
                setEventData(temp_event);
                console.log(temp_event, "eventData when checking if loggedin or not")
                localStorage.setItem("byPassLogin", true);
                navigate('/login');
            }
        } else {
            setErrors(errors);
        }
    }

    const handleForm = (e, name) => {
        const fieldName = name;
        let fieldValue = e;
        setEvent(existingValue => ({
            ...existingValue,
            [fieldName]: fieldValue
        }));
    };

    return (
        <>
            <div className="eventbar">
                <h4 className="titleevent">Almost There</h4>
                <div className="rangebar">
                    <img src="/images/step3.png" alt="step3" />
                    <h4>Step 3 of 3</h4>
                </div>
                <Form>
                    <Form.Group className="formgroup" controlId="formBasicEmail">
                        <div className="Event-addbar">
                            <div className="eventleft">
                                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 9.69019H1M19 11.1902V8.49019C19 6.81003 19 5.96995 18.673 5.32821C18.3854 4.76373 17.9265 4.30479 17.362 4.01717C16.7202 3.69019 15.8802 3.69019 14.2 3.69019H5.8C4.11984 3.69019 3.27976 3.69019 2.63803 4.01717C2.07354 4.30479 1.6146 4.76373 1.32698 5.32821C1 5.96995 1 6.81003 1 8.49019V16.8902C1 18.5703 1 19.4104 1.32698 20.0522C1.6146 20.6166 2.07354 21.0756 2.63803 21.3632C3.27976 21.6902 4.11984 21.6902 5.8 21.6902H10.5M14 1.69019V5.69019M6 1.69019V5.69019M15.4976 15.4021C14.7978 14.623 13.6309 14.4134 12.7541 15.1269C11.8774 15.8403 11.7539 17.0332 12.4425 17.877C13.131 18.7208 15.4976 20.6902 15.4976 20.6902C15.4976 20.6902 17.8642 18.7208 18.5527 17.877C19.2413 17.0332 19.1329 15.8328 18.2411 15.1269C17.3492 14.4209 16.1974 14.623 15.4976 15.4021Z" stroke="#E31B54" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <div className="detail">
                                    <h5><span className="name">{event?.firstName} {event?.lastName}</span>'s <span className="eventname">{event?.occasionId}</span></h5>
                                    <h6 className="eventdate">{event?.date} {event?.time ? `| ${event?.time}` : ''}</h6>
                                </div>
                            </div>
                            <Link className='editpage' to='/eventstep1'><BiPencil /></Link>
                        </div>
                    </Form.Group>
                </Form>
                <div className="uploadmedia imageupload-editbar">
                    <div className="uploadimg">
                        {
                            event?.icon
                                ?
                                <><img src={URL.createObjectURL(event?.icon)} alt="img" />
                                    <div className="detailbar">
                                        <h5>{event?.firstName} {event?.lastName}</h5>
                                        <p>+{event?.phone}</p>
                                        <p>{event?.email}</p>
                                    </div>
                                </>
                                :
                                <><div className="image-up">
                                    <input type='file' className='uploadinput' onChange={(e) => handleForm(e.target.files[0], "icon")} />
                                    <span>Add Photo</span>
                                </div><div className="detailbar">
                                        <h5>{event?.firstName} {event?.lastName}</h5>
                                        <p>+{event?.phone}</p>
                                        <p>{event?.email}</p>
                                    </div></>
                        }
                    </div>
                    <Link to='/eventstep2' className='editbar'><BiPencil /></Link>
                </div>
                <Form>
                    <Form.Group className="formgroup" controlId="formBasicEmail">
                        <Form.Label>Event Description</Form.Label>
                        <Form.Control
                            className={errors.eventDescription ? 'error' : ""}
                            as="textarea"
                            placeholder={`We are celebrating ${event?.firstName} ${event?.lastName}'s ${event?.occasionId}. Please join to celebrate and make this event memorable.`}
                            style={{ height: '134px' }}
                            onChange={(e) => handleForm(e.target.value, "eventDescription")} value={event?.eventDescription}
                        />
                    </Form.Group>
                    <FieldError error={errors.eventDescription} />
                    <Button onClick={eventSuccess} className="btn-pink">Save & Create event</Button>
                </Form>
            </div>

        </>
    );
}

export default EventStep3;