import React, { useEffect, useContext, useState } from 'react';
import Sparkle from '../../../Animations/Sparkle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MoneyCollectBox from '../../../Components/Dashboard/MoneyCollectBox/Index';
import AddParticipant from '../../../Components/Dashboard/AddParticipant/Index';
import AddGiftBox from '../../../Components/Dashboard/AddGiftBox/Index';
import AddTestimonialBox from '../../../Components/Dashboard/AddTestimonialBox/Index';
import EventAction from "./../../../Actions/event.service";
import config from "../../../Config/Config";
import moment from 'moment';
import { AuthContext } from "../../../Context/AuthContext";
import Countdown from 'react-countdown';
import { hideLoader, isNotEmpty, showLoader, isValidDate, ShowImage, getDateLocal } from '../../../utils/helpers';
import TestimonialAction from "../../../Actions/testimonial.service";
import contributionAction from "../../../Actions/contribution.service";
import { ThemeContext } from '../../../hooks/useThemeContext';

function Participant() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { eventData, setEventData, setIsSidebarVisible, setIsLoggedIn, loggedInUserId, isLoggedInUserId } = useContext(AuthContext);
  const { setBackUrlContext } = useContext(ThemeContext)
  const date = getDateLocal(eventData?.eventStart, eventData?.eventTime)

  const [testimonialList, setTestimonialList] = useState();
  const [alreadyContributed, setAlreadyContributed] = useState(false);
  const [audios, setAudios] = useState(0);
  const [photos, setPhotos] = useState(0);
  const [videos, setVideos] = useState(0);
  const [text, setText] = useState(0);
  const [gif, setGif] = useState(0);

  let result = {};

  const getTestimonials = () => {
    TestimonialAction.testimonialList(id, 'all', "").then(res => {
      setTestimonialList(res.testimonials);
      setAudios(res.audioCount);
      setPhotos(res.imageCount);
      setVideos(res.videoCount);
      setText(res.textCount);
      setGif(res.gifCount);
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }

  const viewEvent = () => {
    showLoader();
    EventAction.viewEvent(id).then(res => {
      hideLoader();
      result = res;
      setEventData(result);
      getTestimonials();
    }).catch(err => {
      hideLoader();
      console.log(err)
    });
  }

  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight);
    localStorage.setItem('roleId', `${id}`);
    localStorage.setItem('backToEvent', true);
    setBackUrlContext(`home`)
    viewEvent();
    contributionsList();
  }, [])

  useEffect(() => {
    showLoader();
    console.log("NOT EMPTY", isNotEmpty(eventData?._id), eventData?._id)
    if (isNotEmpty(eventData?._id))
      calledAwaitEffect();
    hideLoader();
  }, [eventData])

  const calledAwaitEffect = async () => {
    if (window.location.search.toString().includes("contribute=true")) {
      const element = document.getElementById(`MoneyCollectBox`);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }
    } else {
      console.log(await isLoggedInUserId(eventData?.createdBy?._id), "CHECK VAL HERE FOR THIS", eventData)
      if (await isLoggedInUserId(eventData?.createdBy?._id)) {
        localStorage.setItem('role', 'organiser');
      } else if (isNotEmpty(eventData?.participants)) {
        const newFilter = Object.keys(eventData?.participants).reduce((result, key) => {
          if (eventData?.participants[key]?.userId?._id.includes(loggedInUserId)) {
            localStorage.setItem('role', 'participant');
          };
        }, []);
      } else {
        // navigate('/home')
      }
      if (!window.location.pathname.startsWith(localStorage.getItem('role'))) {
        navigate(`/${localStorage.getItem('role')}/${id}`);
      }
    }
  }


  const then = moment(eventData?.eventStart);
  const now = moment();
  var difference = moment.duration(now.diff(then));

  const hideNumber = (phone) => {
    if (isNotEmpty(phone) && phone.length > 9) {
      let prefixLength = 6;
      let suffixLength = 2;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;

      return formattedPhone
    } else {
      return phone
    }
  }
  const hideEmail = (email) => {
    if (isNotEmpty(email)) {
      let emailSplit = email.split('@')
      let newEmail = emailSplit[0]
      let prefixLength = 1;
      let suffixLength = 1;

      let prefix = newEmail.substring(0, prefixLength);
      let suffix = newEmail.slice(-suffixLength);
      let nbStars = newEmail.length - (prefixLength + suffixLength);
      let formattedEmail = prefix + "*".repeat(nbStars) + suffix + '@' + emailSplit[1]

      return formattedEmail
    } else {
      return email
    }
  }

  const contributionsList = () => {
    showLoader();
    contributionAction.contributionList(id).then(res => {
      res.map((itemc) =>
        itemc?.eventParticipantId?.userId?._id === loggedInUserId ?
          setAlreadyContributed(true)
          :
          ""
      )
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }


  return (
    <>
      <div className='Participantbar screen1 EventPartially'>
        <div className="avatar-img">
          <img src={ShowImage(eventData?.photo, eventData?.name)} alt="img" />
        </div>
        <Sparkle />
        <h4><span>{eventData?.name}</span>’s <br></br>{eventData?.occasionId} Celebration</h4>
        <h6 className="date">{moment(date).format("LL")} {moment(date).format('h:mm a')} </h6>

        <div className="user-name-email-bar">
          <p> {'+' + hideNumber(eventData?.phone)} </p><span>|</span> {hideEmail(eventData?.email)}

        </div>
        <p className='message-description'> {eventData?.eventDescription} </p>

        <div className="timeevent">
          <p>Time to event</p>
          <h5>
            {
              eventData && eventData.eventStart && eventData.eventTime ? <>
                <h3>  <Countdown date={moment(`${date}`).format()} /> </h3>
              </> : 'To be Decided'
            }
          </h5>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <AddGiftBox event={eventData} />
          </div>
        </div>
        <div className='boxwithnumeric-block' id="MoneyCollectBox">
          <div className='boxwithnumeric'>
            <MoneyCollectBox event={eventData} id={id} />
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <AddParticipant id={id} event={eventData} />
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <AddTestimonialBox alreadyContributed={alreadyContributed} text={text} testimonials={testimonialList} audios={audios} photos={photos} videos={videos} gif={gif} />
          </div>
        </div>
        <div className='view_bottom'>
          <Link className='btn-pink' to={`/recipient/${id}`}>Recipient view</Link>
        </div>
      </div>
    </>
  );
}

export default Participant;
