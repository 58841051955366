import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ShowImage } from '../../../utils/helpers';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';

function TextCardTestimonial(props) {
  const { testimonial } = props;

  const [showMoreText, setShowMoreText] = useState('')
  const [showMore, setShowMore] = useState(false)


  const getCardClass = (index) => {
    const colors = ['bg_lightpink', 'bg_mustard', 'bg_sky', 'bg_grey'];
    const colorIndex = index % colors.length;
    return 'card_body ' + colors[colorIndex];
  }

  const handleShowMore = (caption) => {
    setShowMoreText(caption)
    setShowMore(true)
  }

  const handleClose = () => {
    setShowMoreText('')
    setShowMore(false)
  }

  return (
    <>
      <div className={testimonial?.length === 1 ? "colorful_cards btn-none" : "colorful_cards"} >
        <Carousel >
          {
            testimonial?.length > 0 && testimonial.map((wish, index) => {
              return (
                <Carousel.Item className='min-height-settle'>
                  <div className={getCardClass(index)}>
                    <div className='slider-topbar'>
                      <div className="user_name">
                        <div className="avatar-image">
                          <img src={ShowImage(wish?.userId?.profilePhoto, wish?.userId?.fullName)} alt='stars' />
                        </div>
                        <h4>{wish?.userId?.fullName}</h4>
                      </div>

                      <div className='user_detail'>
                        {/* <p>{wish?.caption}</p> */}
                        <p>
                          {wish?.caption.length > 200 ? wish?.caption.slice(0, 200) : wish?.caption}
                          {
                            wish?.caption.length > 200 &&
                            <a className='see_more_anchor' href={undefined} onClick={e => handleShowMore(wish?.caption)} > See More...</a>
                          }
                        </p>
                      </div>
                    </div>
                    <div className="time_date">
                      <small>
                        {moment(wish?.createdAt).format("MMM DD YYYY")} | {moment(wish?.createdAt).format("hh:mm a")}
                      </small>
                    </div>
                  </div>
                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </div >

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showMore} onHide={handleClose}>
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body className='wishes_div'>
          {showMoreText}
        </Modal.Body>
        <Modal.Footer>
          <Button className='btngray' onClick={handleClose} type="button">Close</Button >
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TextCardTestimonial;
