import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Index";
import publicAction from "../../Actions/public.service";
import { showLoader, hideLoader } from '../../utils/helpers';
import { ThemeContext } from "../../hooks/useThemeContext";
import BottomBtn from "../../Components/BottomToTop";

function AllFaq() {
  const [faqList, setFaqList] = useState([]);
  const { setBackUrlContext } = useContext(ThemeContext);

  useEffect(() => {
    showLoader();
    setBackUrlContext(`${localStorage.getItem('redirectBackTo') ?? "/getStarted"}`)
    localStorage.removeItem('redirectBackTo')
    publicAction.getFaqs().then(res => {
      setFaqList(res);
      hideLoader();
    }).catch(err => {
      hideLoader();
      console.error(err, "==error");
    });
  }, []);

  return (
    <>
      <div className="terms max-width-window">
        <Header />
        <div className="body-wrapper">
          <h2 className="titleevent">Frequently asked questions</h2>
          {faqList.map((item, index) => (
            <div className="faq-list">
              <h4>{item?.question} </h4>
              <p className="post__content" dangerouslySetInnerHTML={{ __html: item?.answer }}></p>
            </div>
          ))}
        </div>
      </div>
      <BottomBtn/>
    </>
  );
}

export default AllFaq;
