import React from 'react'

const cookies = () => {
    return (
        <>
            <div className='terms max-width-window '>
                <div className='body-wrapper'>
                    <div class="Headerbar"><ul><li><a class="backtobasic" href="/getStarted"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></a></li><li></li></ul></div>
                    <div>
                        <h2 className='titleevent text-center'>
                            Cookie Policy
                        </h2>
                        <h4>What Are Cookies?</h4>
                        <p>
                            A ‘cookie’ is a small piece of data sent to a user’s computer (through a web browser) by a web server
                            (website) to store certain information about a user when a website is accessed by such user. Such a
                            cookie may contain information about a user’s visit to the website, including information which a user
                            may have contributed voluntarily to the website. Our Platform may store and use ‘cookies’ to collect
                            and   receive   certain   information.   Cookies   are   used   for   User   administration,   research   and
                            development, technical administration of the Platform, and also to track and administer traffic on the
                            Platform. Our Platform may collect and use the data contained in the cookies. The information in the
                            cookies   helps   provide   additional   functionality   to   the   Platform,   such   as   cookies   might   help   in
                            remembering your login credentials and obviates the need to re-enter again every time you access
                            the Platform. The cookies may help in evaluating browsing habits and usage behaviour, areas of the
                            Platform that are visited. We routinely use such information in the aggregate in order to administer
                            and improve the Platform and ensure security, and for other purposes as may be specified hereof.
                            Cookies are sent to a user's web browser from a web server by way of small amounts of data that is
                            eventually   stored   on   a   user's   device.   A   user   has   the   option   to   disable   the   cookies   through   the
                            appropriate functioning of its browsers. Cookies are classified based on functionality.  Each of such
                            classifications   of   cookies   has   its   function.
                            The cookies used by Us on the Platform can either be of the two typ
                        </p>
                        <ol>
                            <li>
                                <b>First-Party Cookies –
                                </b>&nbsp;
                                These are the cookies that are sent and set directly by the Platform
                                You are visiting, and are often associated directly with the functioning of the Platform and
                                its features.
                            </li>
                            <li>
                                <b>Third-Party Cookies –
                                </b>&nbsp;
                                These are the cookies that are sent and set by the domains other
                                than the Platform You are visiting and are often targeted for specific purposes such as
                                visitor analytics and others.
                            </li>
                        </ol>
                        <p>
                            The   Cookies   used   by   Us   are   more   specifically   categorized   based   on   functionality,   each   of   such
                            category of cookies is enlisted herein below and has its specified function:
                        </p>
                        <h4>
                            Types of Cookies Used
                        </h4>
                        <ol>
                            <li>
                                Strictly Necessary Cookies: These cookies are necessary for You to visit the Platform
                                and   access   all   its   features.   These   cookies   are   essential   for   the   functioning   of   the
                                Platform. In the event, You decide to block such cookies, You may not be able to access
                                all or any parts of the Platform. You can use your browser to block, or alert you about,
                                such cookies. Such cookies do not store any personal identifiable information. These are
                                the First-Party Cookies and are directly attributed to us.
                            </li>
                            <li>
                                <b>Functionality Cookies :</b>&nbsp;These   cookies   enhance   the   functionality   of   the   Platform   by
                                bringing personalization and remembering Your previous choices and selections on the
                                Platform,   such   as   remembering   Your   login   credentials   so   that   You   might   not   need   to
                                enter the same every time You visit the Platform. These can either be First-Party or Third-
                                Party cookies.
                            </li>
                            <li>
                                <b>Performance Cookies :
                                </b>&nbsp;
                                These cookies allow us to count visits on the Platform, and also
                                to measure the traffic sources. The cookies enable us to improve the performance of the
                                Platform   by   gathering   the   usage   analytics,   including   evaluating   the   most   popular   and
                                least   popular   pages.   The   information   collected   by   the   cookies   does   not   contain   any
                                personally identifiable information and is aggregated and anonymous. These can either
                                be First-Party or Third-Party cookies.
                            </li>
                        </ol>
                        <h4>
                            Your Choices
                        </h4>
                        <p>
                            You have the right to control and manage the cookies on your device. You have the right to control
                            the cookies in either or both of the following manner:
                        </p>
                        <ol>
                            <li>
                                <b> Cookie Settings :
                                </b>&nbsp;
                                The   Cookie   Settings   option   may   be   accessed   either   through   the
                                banner that may appear when you first visit the Platform or by accessing the same at the
                                footer of any webpage. The Cookie Settings allows you to see the cookies generated
                                under each of the categories mentioned above. You have the right to enable or disable
                                any of the cookie categories by making a relevant selection within the Cookie Settings. It
                                may be noted that disabling a cookie category may have a corresponding effect on the
                                functionality and performance of the Platform, and resultantly, you may not be able to
                                access certain features or information on the Platform.
                            </li>
                            <li>
                                <b>Web browser :</b>&nbsp; Most web browser allows you to see the cookies stored on your device.
                                You   have   the   option   to   delete   all   or   any   of   them.   Additionally,   based   on   your   web
                                browser, You might have the option to block cookies from a specific website, or to block
                                third-party cookies, deleting all cookies upon closing your web browser, and others. Each
                                web browser has different functionality in this regard; therefore, it is advised to check the
                                relevant user guide. Also, it is recommended to always use the latest version of the web
                                browser..
                            </li>
                        </ol>

                    </div>
                </div>
            </div>
        </>
    )
}

export default cookies