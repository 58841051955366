import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import HelpBar from "../../Components/WeGroup/HelpBar/Index";
import SocialMedia from "../../Components/WeGroup/SocialMedia/Index";
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import AuthAction from "../../Actions/auth.service";
import { hideLoader, ShowImage, showLoader, haveValue } from "../../utils/helpers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillQuestionCircle, AiFillCheckCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { auth } from "../../../src/firbase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";

const ProfileMain = (props) => {
  const navigate = useNavigate();

  const {
    isLoggedIn,
    setIsLoggedIn,
    setIsSidebarVisible,
    emailVerified,
    setEmailVerified,
  } = useContext(AuthContext);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const [userExistsFirebase, setUserExistsFirebase] = useState(false);
  const [verifyshow, EmailverifysetShow] = useState(false);

  useEffect(() => {
    if (haveValue(isLoggedIn) && isLoggedIn) {
      AuthAction.getUserData()
        .then((res) => {
          setMyUserInfo(res);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);
            navigate("/");
          }
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    checkIfEmailVerified();
  }, [myUserInfo]);

  useEffect(() => {
    checkIfEmailVerified();
  }, [verifyshow]);

  useEffect(() => {
    setEmailVerified(emailVerified);
  }, [emailVerified]);

  setTimeout(() => {
    console.log("checking...");
    checkIfEmailVerified();
  }, 5000);

  const verifyEmailClose = () => EmailverifysetShow(false);

  const checkIfEmailVerified = () => {
    if (haveValue(myUserInfo?.email)) {
      fetchSignInMethodsForEmail(auth, myUserInfo?.email)
        .then(function (signInMethods) {
          if (signInMethods.length > 0) {
            console.log("User exists");
            setUserExistsFirebase(true);
            signInWithEmailAndPassword(
              auth,
              myUserInfo?.email,
              "password",
            ).then((userCredential) => {
              const user = userCredential.user;
              setEmailVerified(user.emailVerified);
              auth.signOut();
            });
          } else {
            console.log("User does not exist");
            setEmailVerified(false);
          }
        })
        .catch(function (error) {
          console.log("Error checking user:", error);
        });
    }
  };
  const verifyEmail = async () => {
    showLoader();
    createUserWithEmailAndPassword(auth, myUserInfo?.email, "password")
      .then((userCredential) => {
        // send verification mail.
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            // Email verification sent!
            auth.signOut();
            EmailverifysetShow(true);
            hideLoader();
          })
          .catch(alert);
      })
      .catch((err) => {
        console.log({ err }, "err");
        // alert(err)
      });
  };

  const resendEmail = async () => {
    showLoader();
    signInWithEmailAndPassword(auth, myUserInfo?.email, "password").then(
      (userCredential) => {
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            auth.signOut();
            EmailverifysetShow(true);
            hideLoader();
          })
          .catch(alert);
        auth.signOut();
      },
    );
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setIsSidebarVisible(false);

    navigate("/");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Email is not verified.
    </Tooltip>
  );

  return (
    <>
      <div className="profile-tophead">
        <h4 className="titleevent">Profile</h4>
        <div className="uploadmedia">
          <div className="avatar-img">
            <img
              src={ShowImage(myUserInfo?.profilePhoto, myUserInfo?.fullName)}
              alt="img"
            />
          </div>
        </div>
        <div className="userinfo-bar">
          <h4>{myUserInfo?.fullName}</h4>
          {myUserInfo?.phoneNumber ? <p>{myUserInfo?.phoneNumber}</p> : ""}
          {myUserInfo?.email ? (
            <p className="email-verify">
              {myUserInfo?.email}
              {emailVerified ? (
                <>
                  <Button
                    className="verify-btn verified-btn"
                    style={{ cursor: "pointer" }}
                  >
                    <AiFillCheckCircle />
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <div className="verifybar">
                      {userExistsFirebase ? (
                        <>
                          {" "}
                          <Button className="verify-btn" onClick={resendEmail}>
                            <AiFillQuestionCircle />
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button className="verify-btn" onClick={verifyEmail}>
                            <AiFillQuestionCircle />
                          </Button>
                        </>
                      )}
                      <Modal
                        className="Createevent-modal fixamountpopup "
                        show={verifyshow}
                        onHide={verifyEmailClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Email Verification</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Email has been sent.</Modal.Body>
                        <Modal.Footer>
                          {/* <Button
                            className="btn-pink"
                            onClick={verifyEmailClose}
                          >
                            Close
                          </Button> */}
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </OverlayTrigger>
                </>
              )}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="eventbar">
        <Link to="/editprofile" type="button" className="btngray">
          Edit Profile
        </Link>
        <Link to="/eventstep1" onClick={() => localStorage.setItem("soloGift", false)} className="btn-pink mt-3">
          Create a Group Event
        </Link>
        <Link to="/eventstep1" onClick={() => localStorage.setItem("soloGift", true)} className="btn-pink mt-3">
          Create a Solo Event
        </Link>
        {isLoggedIn ? (
          <Link to="/recipientlist" className="btn_outline mt-3">
            Gifts Received
          </Link>
        ) : (
          ""
        )}
      </div>
      <HelpBar />
      <SocialMedia />
      <div className="btnlogout">
        <button onClick={logout} type="button" className="btn-pink">
          <FiLogOut />
          Logout
        </button>
      </div>
    </>
  );
};

export default ProfileMain;
