import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Header/Index";


const SiteLayout = () => {


    return (
        <>
            <div className="max-width-window">
                <Header />
                <div className='body-wrapper'>
                    <Outlet />
                </div>
            </div>

        </>
    );
}

export default SiteLayout;