import React, { useState, useEffect, useContext, useRef } from "react";
import SearchInput from '../SearchInput/Index';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllTestimonial from './TestimonialTabs/AllTestimonial';
import { haveValue, hideLoader, isAnImage, isVideo, showLoader } from "../../utils/helpers";
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { AiOutlineSend, AiOutlineAudio, AiOutlineGif, AiOutlinePlusCircle } from 'react-icons/ai';
import { BiImageAdd } from 'react-icons/bi';
import { FiVideo } from 'react-icons/fi';
import TestimonialAction from "../../Actions/testimonial.service";
import contributionAction from "../../Actions/contribution.service";
import EventAction from "../../Actions/event.service";
import { Button, Modal } from 'react-bootstrap';
import { ThemeContext } from "../../hooks/useThemeContext";
import { AuthContext } from "../../Context/AuthContext";
import VideoRecorder from "../VideoRecorder";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { BiPlus } from "react-icons/bi";

function TestimonialList(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const inputFileRef = useRef();

  const [testimonialList, setTestimonialList] = useState([]);
  const [testimonialListMain, setTestimonialListMain] = useState([]);
  const [caption, setCaption] = useState("");
  const [type, setType] = useState('all');
  const [typeToSubmit, setTypeToSubmit] = useState('text');
  const [eventId, setEventId] = useState('');
  const [isImage, setIsImage] = useState(false);
  const [alreadyContributed, setAlreadyContributed] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [uploadButton, setUploadButton] = useState(false);
  const [file, setFile] = useState('');
  const [value, setValue] = useState("");
  const [testimonialId, setTestimonialId] = useState('');
  const [editTest, setEditTest] = useState(false);
  const [participantShowModal, setParticipantShowModal] = useState(false);
  const [eventData, setEventData] = useState(false);
  const [isGiphy, setIsGiphy] = useState(false);
  const [searchGif, setSearchGif] = useState('');
  const [gifs, setGifs] = useState([]);
  const [textCount, setTextCount] = useState(0);
  const [audioCount, setAudioCount] = useState(0);
  const [photoCount, setPhotoCount] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [gifCount, setGifCount] = useState(0);
  const textareaRef = useRef(null)


  const { showSuccessToast, setBackUrlContext } = useContext(ThemeContext);
  const { setIsSidebarVisible, setIsLoggedIn, loggedInUserId, isLoggedInUserId } = useContext(AuthContext);

  useEffect(() => {
    contributionsList();
  }, [])

  const contributionsList = () => {
    showLoader();
    contributionAction.contributionList(id).then(res => {
      res.map((itemc) =>
        itemc?.eventParticipantId?.userId?._id === loggedInUserId ?
          setAlreadyContributed(true)
          :
          ''
      )
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }

  const eventInfo = () => {
    showLoader();
    setBackUrlContext(`/${localStorage.getItem('role') ?? 'organiser'}/${id}`);
    setEventId(id);
    EventAction.viewEvent(id)
      .then(res => {
        hideLoader();
        setEventData(res);

        if (haveValue(res?.testimonials) && res?.testimonials.length > 0) {
          const mediaCounts = res.testimonials.reduce((counts, media) => {
            if (media?.userId === loggedInUserId) {
              switch (media?.mediaType) {
                case "text":
                  counts.textCount++;
                  break;
                case "voice":
                  counts.audioCount++;
                  break;
                case "video":
                  counts.videoCount++;
                  break;
                case "image":
                  counts.photoCount++;
                  break;
                case "gif":
                  counts.gifCount++;
                  break;
                default:
                  counts.textCount++;
              }
            }
            return counts;
          }, {
            textCount: 0,
            audioCount: 0,
            videoCount: 0,
            photoCount: 0,
            gifCount: 0,
          });
          setTextCount(mediaCounts.textCount);
          setAudioCount(mediaCounts.audioCount);
          setVideoCount(mediaCounts.videoCount);
          setPhotoCount(mediaCounts.photoCount);
          setGifCount(mediaCounts.gifCount);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };


  useEffect(() => {
    eventInfo()
    getTestimonials(type);
  }, [type, value])

  let videofile = '';

  const uploadGifToWishes = (gif) => {
    const data = new FormData();
    data.append("eventId", eventId);
    data.append("mediaType", "gif");
    data.append("media", gif)
    showLoader();
    TestimonialAction.createTestimonial(data, eventId).then(res => {
      setCaption("");
      eventInfo()
      hideLoader();
      showSuccessToast('Wish Added');
      getTestimonials('all');
      setTimeout(() => {
        toast.dismiss();
        giphyModalClose();
        localStorage.setItem("backUrl", `/${localStorage.getItem('role') ?? 'organiser'}/${eventId}`);
      }, 1000);
    }).catch(err => {
      hideLoader();
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }

  const saveTestimonial = () => {
    setButtonPlusClicked(false);
    const data = new FormData();
    data.append("caption", caption);
    data.append("eventId", eventId);
    data.append("mediaType", typeToSubmit);
    if (file) {
      if (typeToSubmit == "video") {
        videofile = new File([file], "videofile.mp4", {
          type: "video/mp4",
        });
        data.append("file", videofile);
      } else {
        data.append("file", file);
      }
    }
    showLoader();
    TestimonialAction.createTestimonial(data, eventId).then(res => {
      eventInfo()
      hideLoader();
      showSuccessToast('Wish Added');
      setCaption("");
      setType("all");
      setTimeout(() => {
        toast.dismiss();
        setFile('');
        setIsImage(false);
        setIsVideoRecording(false);
        setTypeToSubmit('text');
        getTestimonials('all');
        localStorage.setItem("backUrl", `/${localStorage.getItem('role') ?? 'organiser'}/${eventId}`);
      }, 1000);
    }).catch(err => {
      hideLoader();
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const updateGifInWishes = (gif) => {
    showLoader();
    const data = new FormData();
    data.append("eventId", eventId);
    data.append("mediaType", "gif");
    data.append("media", gif)
    TestimonialAction.updateTestimonial(data, testimonialId).then(res => {
      setEditTest(false);
      setType("all");
      eventInfo();
      setCaption("");
      setFile('');
      setIsImage(false);
      setIsVideoRecording(false);
      setTypeToSubmit('text');
      hideLoader();
      showSuccessToast('Wish Updated');
      setTimeout(() => {
        giphyModalClose()
        toast.dismiss();
        getTestimonials('gif');
      }, 1000);
    }).catch(err => {
      hideLoader();
      setCaption("");
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const updateTestimonial = () => {
    showLoader();
    const data = new FormData();
    data.append("caption", caption);
    data.append("mediaType", typeToSubmit);
    if (file) {
      if (typeToSubmit == "video") {
        videofile = new File([file], "videofile.mp4", {
          type: "video/mp4",
        });
        data.append("file", videofile);
      } else {
        data.append("file", file);
      }
    }

    TestimonialAction.updateTestimonial(data, testimonialId).then(res => {
      setCaption("");
      setEditTest(false);
      setFile('');
      setIsImage(false);
      setIsVideoRecording(false);
      setTypeToSubmit('text');
      eventInfo();
      hideLoader();
      showSuccessToast('Wish Updated');
      setTimeout(() => {
        toast.dismiss();
        getTestimonials('all');
      }, 1000);
    }).catch(err => {
      setEditTest(false);
      hideLoader();
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const getTestimonials = (typeTemp) => {
    showLoader();
    TestimonialAction.testimonialList(id, typeTemp, value).then(res => {
      if (typeTemp === "all") {
        setTestimonialListMain(res.testimonials);
      }
      setTestimonialList(res.testimonials);
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const handleRefreshTestimonials = () => {
    eventInfo();
    getTestimonials(type);
    setEditTest(false);
  }

  const uploadFile = (file) => {
    setIsImage(true);
    setFile(file);
    if (isVideo(file?.name)) {
      setTypeToSubmit('video');
    } else if (isAnImage(file?.name)) {
      setTypeToSubmit('image');
    } else {
      setTypeToSubmit('image');
    }
    hideLoader()
  }

  const VideoRecording = () => {
    setIsVideoRecording(true);
    setParticipantShowModal(false);
  }

  const handleVideoRecording = (videoBlob) => {
    setUploadButton(true);
    setFile(videoBlob);
  }

  const uploadVideoRecording = () => {
    setTypeToSubmit('video');
    setIsImage(true);
  }

  useEffect(() => {
    if (isImage) {
      document.body.classList.add("media-viewer");
    } else {
      if (isVideoRecording) {
        document.body.classList.add("media-viewer");
      } else {
        document.body.classList.remove("media-viewer");
      }
    }
  }, [isImage, isVideoRecording]);


  const handleOnChange = (searchWord) => {
    // setValue(searchWord);
    // testimonialList.filter(caption => caption.includes(event)).map(abc => (
    //   console.log(abc, "==")
    // ))
    let resultA = [];
    const newFilter = Object.keys(testimonialListMain).reduce((result, key) => {
      if (testimonialListMain[key].caption.includes(searchWord)) {
        resultA.push(testimonialListMain[key]);
      };
      setTestimonialList(resultA);
    }, []);
  };

  const handleEdit = () => {
    if (localStorage.getItem('editTest') === "true") {
      setEditTest(true);
      if (localStorage.getItem('video') === "true") {
        VideoRecording();
        hideLoader();
      } else if (localStorage.getItem('isGif') === "true") {
        setIsGiphy(true);
        handleSearch()
        hideLoader();
      } else if (localStorage.getItem('isText') === "true") {
        hideLoader();
      } else {
        inputFileRef.current.click();
        hideLoader();
      }
      setTestimonialId(localStorage.getItem('testimonialId'));
      setCaption(localStorage.getItem('captionTesti') ?? "");
      localStorage.removeItem('editTest');
      localStorage.removeItem('captionTesti');
      localStorage.removeItem('testimonialId');
      localStorage.removeItem('video');
      localStorage.removeItem('isGif');
      localStorage.removeItem('isText');
    } else {
      setEditTest(false);
    }
  };

  const giphyModalClose = () => {
    setParticipantShowModal(false);
    setEditTest(false);
    setCaption("");
    setIsGiphy(false);
    setSearchGif('');
    setGifs([]);
  };
  const handleSearch = async () => {
    try {
      console.log(haveValue(searchGif), searchGif)
      if (haveValue(searchGif)) {
        const response = await axios.get(
          `https://api.giphy.com/v1/gifs/search?api_key=MLRuERfeViyawJOljUfIAMP5o5DwtY0V&q=${searchGif}&limit=20`
        );
        setGifs(response.data.data);
      } else {
        const response = await axios.get(
          `https://api.giphy.com/v1/gifs/search?api_key=MLRuERfeViyawJOljUfIAMP5o5DwtY0V&q=congratulations&limit=20`
        );
        setGifs(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching GIFs:', error);
    }
  };

  const [buttonPlusClicked, setButtonPlusClicked] = useState(false);

  const handleButtonClick = () => {
    setButtonPlusClicked(prevState => !prevState);
  };

  // const textAreaAdjust = (e) => {
  //   e.target.style.height = '50px';
  //   e.target.style.height = e.target.scrollHeight + 'px';
  // };

  function nearestMultipleOf24Plus48(number) {
    // Calculate the nearest multiple of 24 plus 48
    return Math.floor((number - 48) / 24) * 24 + 48;
  }


  const handleInputChange = () => {
    const textarea = textareaRef.current;
    const defaultHeight = 48
    // textarea.style.height = 'auto'; // Reset height to auto to recalculate scrollHeight
    var newHeight = textarea.scrollHeight;
    const nearest = nearestMultipleOf24Plus48(newHeight);

    console.log(nearest);
    textarea.style.height = `${nearest}px`; // Set the height to the calculated scrollHeight
  };
  return (
    <>
      {
        isImage ?
          <div className='addmedia-wrapper'>
            <div className='headbar'>
              <div className='crossbar'><Link onClick={(e) => setIsImage(false)}>
                {/* <img src='./../images/cross-audio.png' alt='icon' /> */}<RxCross2 />
              </Link></div>
              <div className='media-wraper'>
                {
                  typeToSubmit == 'image' ?
                    <img src={file ? URL.createObjectURL(file) : './images/girl.png'} alt='photo' />
                    :
                    ''
                }

                {
                  typeToSubmit == 'video' ?
                    <>
                      {/* <iframe src={file ? URL.createObjectURL(file) : ''} title="Video Testimonial" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                      <video controls autoPlay loop muted>
                        <source src={file ? URL.createObjectURL(file) : ''} type="video/mp4"></source>
                      </video></>
                    :
                    ''
                }
              </div>
            </div>
            <div className='media-message-box'>
              <Form>
                <Form.Control
                  className=''
                  as="textarea"
                  placeholder="Add a message"
                  style={{ height: '140px' }}
                  value={caption ? caption : ''} onChange={(e) => setCaption(e.target.value)}
                />
                {
                  editTest ?
                    <Button onClick={updateTestimonial} className="btn-pink">Upload</Button>
                    :
                    <Button onClick={saveTestimonial} className="btn-pink">Upload</Button>
                }
              </Form>

            </div>
          </div >
          :
          <>
            {
              isVideoRecording ?
                <div className="videoRecording">
                  <div className='crossbar'><Link onClick={(e) => setIsVideoRecording(false)}><RxCross2 /></Link></div>
                  <VideoRecorder onRefresh={handleRefreshTestimonials} edit={editTest} testId={testimonialId} handleVideoRecordingState={(e) => setIsVideoRecording(false)} />
                </div>
                :
                <>
                  {
                    testimonialListMain.length > 0 ? <>
                      <h4 className="titleevent">Wishes</h4>
                      <div className='TestimonialList'>
                        <SearchInput handleSearch={(e) => handleOnChange(e)} />
                        <div className='testimonial-tabs'>
                          <Tabs
                            defaultActiveKey="all" onSelect={(index) => setType(index)}>
                            <Tab eventKey="all" title="All">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                            <Tab eventKey="video" title="Videos">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                            <Tab eventKey="image" title="Photos">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                            <Tab eventKey="voice" title="Audio">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                            <Tab eventKey="text" title="Text">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                            <Tab eventKey="gif" title="Gif">
                              <AllTestimonial onEdit={handleEdit} onRefresh={handleRefreshTestimonials} testimonial={testimonialList} />
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </> : <>
                      {/* <h4 className="titleevent">Add Testimonial</h4>
                      <div className='AddTestimonial'>
                        <div className='review-empty'></div>
                      </div> */}
                      <h4 className="titleevent">Wishes</h4>
                      <div className='TestimonialList'>
                        <div className="nowish-illustration">
                          <img src='/images/nowish.svg' alt='wishes illustration' />
                          <h4>No wishes given yet!</h4>
                          {
                            isLoggedInUserId(eventData?.createdBy?._id) || (!isLoggedInUserId(eventData?.createdBy?._id) && alreadyContributed) ?
                              <Button onClick={handleButtonClick} className="btn-pink" type='button'>Click here to send a Wish</Button>

                              :
                              ""
                          }
                        </div>
                      </div>
                    </>
                  }
                  {
                    isLoggedInUserId(eventData?.createdBy?._id) || (!isLoggedInUserId(eventData?.createdBy?._id) && alreadyContributed) ? (
                      <div className='add-review-bar'>
                        <div className='inputbar'>
                          {
                            haveValue(editTest) && editTest ?
                              <>
                                <Button disabled type='button' className='btnsend'><AiOutlineSend /></Button>
                                <Form.Control disabled type="text" placeholder="Type here" value="" />
                              </>
                              :
                              <>
                                <Button onClick={saveTestimonial} type='button' className='btnsend'><AiOutlineSend /></Button>
                                <Form.Control id="message_text" ref={textareaRef} as="textarea" style={{ minHeight: '50px', maxHeight: '100px', height: "50px" }} placeholder="Type here" value={caption ? caption : ''} onChange={(e) => { setCaption(e.target.value); handleInputChange() }} />
                                {/* <div contentEditable="true" className="">
                                  hello
                                </div> */}
                              </>
                          }
                        </div>
                        {audioCount > 0 && (photoCount > 0) && videoCount > 0 && gifCount > 0 ? (
                          <>
                            <button type='button' className='btnrecord d-none'>
                              <Form.Control type="file" className='uploadinput d-none' ref={inputFileRef} accept="image/jpg,image/png" onChange={(e) => uploadFile(e.target.files[0])} />
                              <BiImageAdd />
                            </button>
                          </>
                        ) : (
                          <div className={buttonPlusClicked ? 'addplusbar menuopened' : 'addplusbar'}>
                            <button onClick={handleButtonClick} className="addtbns"><BiPlus /></button>
                            <ul className='recorded-btn'>
                              {audioCount > 0 ? (
                                <></>
                              ) : (
                                <li><button type='button' onClick={() => navigate(`/audio/${eventId}`)} className='btnrecord'><AiOutlineAudio /></button></li>
                              )}
                              {gifCount > 0 ? (
                                <></>
                              ) : (
                                <li><button type='button' onClick={() => { setIsGiphy(true); handleSearch() }} className='btnrecord'><AiOutlineGif /></button></li>
                              )}
                              {videoCount > 0 ? (
                                <></>
                              ) : (
                                <li><button type='button' className='btnrecord' onClick={(e) => setParticipantShowModal(true)} ><FiVideo /></button></li>
                              )}
                              <li>
                                {
                                  haveValue(photoCount) && photoCount > 0 ?
                                    <>
                                      <button type='button' className='btnrecord d-none'>
                                        <Form.Control type="file" className='uploadinput d-none' ref={inputFileRef} accept="image/jpg,image/png" onChange={(e) => uploadFile(e.target.files[0])} />
                                        <BiImageAdd />
                                      </button>
                                    </>
                                    :
                                    <>
                                      <button type='button' className='btnrecord'>
                                        <Form.Control type="file" className='uploadinput' ref={inputFileRef} accept="image/jpg,image/png" onChange={(e) => uploadFile(e.target.files[0])} />
                                        <BiImageAdd />
                                      </button>
                                    </>
                                }
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )
                  }

                </>
            }
          </>
      }

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={participantShowModal} onHide={giphyModalClose}>
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          It is recommended to record the video in Portrait mode.
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={VideoRecording} className="btn-pink">Ok</Button>
        </Modal.Footer>
      </Modal>

      <Modal className="datepicker-modal gifbar" show={isGiphy} onHide={giphyModalClose} centered>
        <Modal.Body className="gifbar">
          <div className="scrollgif">
            {
              editTest ?
                gifs.map((gif) => (
                  <img
                    key={gif.id}
                    src={gif.images.fixed_height.url}
                    alt={gif.title}
                    onClick={(e) => updateGifInWishes(e?.nativeEvent?.srcElement?.currentSrc)}
                  />
                ))
                :
                gifs.map((gif) => (
                  <img
                    key={gif.id}
                    src={gif.images.fixed_height.url}
                    alt={gif.title}
                    onClick={(e) => uploadGifToWishes(e?.nativeEvent?.srcElement?.currentSrc)}
                  />
                ))
            }

          </div>
        </Modal.Body>
        <Modal.Footer>
          <input
            className="form-control"
            type="text"
            value={searchGif}
            placeholder="Congratulations"
            onChange={(e) => setSearchGif(e.target.value)}
          />
          <button className="btn-pink" onClick={handleSearch}>Search</button>
          <Button className="btngray mt-2" onClick={giphyModalClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestimonialList;
