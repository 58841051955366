import React, { useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Success from "../../Animations/Successfull";
import { AuthContext } from "../../Context/AuthContext";


const PaymentSuccess = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { event, isLoggedInUserId } = useContext(AuthContext);

    setTimeout(() => {
        navigate(`/${localStorage.getItem('role')}/${id}`);
    }, 2000);
    return (
        <>
            <div className="eventbar">
                {/* <h4 className="titleevent">Create Group Gift Event</h4> */}
                <div className="successbar">
                    <Success />
                    <h6>Payment Successful</h6>
                </div>
            </div>
                {/* // isLoggedInUserId(event?.createdBy?._id) ?
                //     <Link to={(`/participantlist/${id}`)} className="btn-pink" >Add / Invite people </Link>
                //     :
                //     '' */}
        </>
    );
}

export default PaymentSuccess;