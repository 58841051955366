import Agent from "../Services/RequestInstance";
import { ServerError } from "../utils/helpers";
import * as http from '../Services/http';


import config from "../Config/Config";

const BACKEND_URL = config.BACKEND_URL;


function createContribution(payload) {
    return http.post(`/contribution/add`, payload);
}
function contributionList(eventId) {
    return http.get('/contribution/list?eventId=' + eventId);
}
function yetTocontributeList(eventId) {
    return http.get('/contribution/yet-list?eventId=' + eventId);
}
function initiateTransaction(eventId,payload) {
    return http.post(`/contribution/transaction-initiate/${eventId}`,payload);
}
function updateTransaction(eventId,payload) {
    return http.post(`/contribution/transaction-update/${eventId}`,payload);
}
function saveTransaction(eventId,payload) {
    return http.post(`/contribution/transaction/add/${eventId}`,payload);
}
function checkTransaction(payload) {
    return http.post(`/contribution/transaction-check`, payload);
}


export default {
    createContribution,
    contributionList,
    yetTocontributeList,
    saveTransaction,
    initiateTransaction,
    updateTransaction,
    checkTransaction
};