import '../src/Css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeContext } from './hooks/useThemeContext';
import AppRouter from './AppRouter';
import { ToastContainer } from 'react-toastify'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useContext, useEffect } from 'react';
import { AuthContext } from './Context/AuthContext';
import { Button, Modal } from 'react-bootstrap';
import Success from './Animations/Successfull';
import UnSuccess from './Animations/Unsuccessful';
import DisableNav from './Components/Navigation';

function App() {

  const { isLoggedIn } = useContext(AuthContext);
  const { successToast, setSuccessToast, errorToast, setErrorToast, backUrlContext } = useContext(ThemeContext);

  useEffect(() => {
    document.body.setAttribute("logged-in", isLoggedIn);
  }, [isLoggedIn]);

  const handleSuccessToastClose = () => {
    setSuccessToast({
      ...successToast,
      visible: false
    });
  }

  const handleErrorToastClose = () => {
    setErrorToast({
      ...errorToast,
      visible: false
    });
  }

  return (
    <>
      <div id="successToastMain">
        <Modal className="Createevent-modal dateadded-popup" show={successToast?.visible} onHide={handleSuccessToastClose}>
          <Modal.Body>
            <div className="dateaddedbar">
              <Success />
              <p>{successToast?.title}</p>
            </div>
            <Button className='btn-pink' onClick={handleSuccessToastClose}>
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>

      <div id="errorToastMain">
        <Modal className="Createevent-modal dateadded-popup" show={errorToast?.visible} onHide={handleErrorToastClose}>
          <Modal.Body>
            <div className="dateaddedbar">
              <UnSuccess />
              <p>{errorToast?.title}</p>
            </div>
            <Button className='btn-pink' onClick={handleErrorToastClose}>
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>

      <ToastContainer position="bottom-left" />
      <GoogleOAuthProvider clientId="64683006390-s2o7rfnnqsa4c1ulqhgnfd9cu9po588n.apps.googleusercontent.com">
        <Router>
          <DisableNav />
          <AppRouter />
        </Router>
      </GoogleOAuthProvider>

    </>
  );
}

export default App;
