import React from "react";
import { Outlet } from "react-router-dom";


const HomeLayout = () => {


    return (
        <>
            <div className="max-width-window">
                <div className='home-wrapper'>
                    <Outlet />
                </div>
            </div>

        </>
    );
}

export default HomeLayout;