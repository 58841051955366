import React, { useContext, useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { BiRupee } from "react-icons/bi";
import { hideLoader, showLoader } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';

const GiftcardInput = () => {
  const { id } = useParams();
  const [target, setTarget] = useState(0);

  const { setBackUrlContext } = useContext(ThemeContext);

  useEffect(() => {
    showLoader();
    if (setBackUrlContext) {
      setBackUrlContext(`/${localStorage.getItem('role') ?? 'organiser'}/${id}`);
    }
    hideLoader();
  }, [setBackUrlContext])

  return (
    <>
      <div className="new-block">
        <h4 className="titleevent">Gift Card</h4>
        <div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter your goal target</Form.Label>
            <div className='amount-type'>
              <BiRupee />
              <Form.Control type="number" placeholder="5000" value={target} onChange={(e) => setTarget(e?.target?.value)} />
            </div>
          </Form.Group>
        </div>
        <div className="text-center">
          <Link to={`/selectgiftcard/${id}`} className='editbtn text-danger fs-6'>Skip</Link>
        </div>
        <div className='d-flex align-items-center my-2'>
          <Link type="button" className="btn-pink me-2" to={`/selectgiftcard/${id}?target=${target}`}>Submit</Link>
        </div>
      </div>
    </>
  )
}

export default GiftcardInput