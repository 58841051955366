import React from 'react';

function AboutUs() {
  return (
      <>
      <div className='AboutUs'>
        <h4>About weGroup</h4>
        <p>Enim feugiat ut ipsum, neque ut. Tristique mi id elementum praesent. Gravida in tempus feugiat netus enim aliquet a, quam scelerisque. Dictumst in convallis nec in bibendum aenean arcu. </p>
      </div>
      </>
  );
}

export default AboutUs;
