import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Sparkle from "../../Animations/Sparkle";
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';
import EventPartiallyList from '../../Pages/Dashboards/Organiser/EventPartiallyList';
import EventAction from './../../Actions/event.service';
import { getDateLocal, haveValue } from '../../utils/helpers';
import useSoloEvent from '../../hooks/useSoloEvent';

const EventOwnerBox = (props) => {
    const isSolo = useSoloEvent()

    const navigate = useNavigate();
    const { event } = props;
    const checkbuttonandScroll = () => {
        // localStorage.setItem("scroll", `no`);  
        // console.log(localStorage.getItem("scroll"), "scrollll====");
        navigate(`/organiser/${event._id}?preview=true`);
    }
    var date = getDateLocal(event?.eventStart, event?.eventTime)

    useEffect(() => {
        localStorage.setItem('backToHome', true)
    }, [])

    return (
        <>
            <div key={event._id} className="Home-component EventPartially">
                <Sparkle />
                <div className='header_celebration_badge'>
                    <h4 onClick={() => <>{event?.isCancelled != true && navigate(`/organiser/${event._id}`)}</>}><span> {event?.name} </span>'s <br></br>{event?.occasionId} Celebration ({isSolo ? "Solo Event" : "Group Event"})  </h4>

                    {
                        haveValue(event?.isCancelled) && event?.isCancelled == true
                            ?
                            <Badge pill className='badge_red'>
                                Event Cancelled
                            </Badge>
                            :

                            new Date() > new Date(date) && !event?.isConfirmed ?
                                <Badge pill className='badge_red'>
                                    Event Closed
                                </Badge>
                                :
                                new Date() > new Date(date) && event?.isConfirmed ?
                                    <Badge pill className='badge_green'>
                                        Event Successful
                                    </Badge>
                                    :
                                    event?.isConfirmed ?
                                        <Badge pill className='badge_green'>
                                            Event Confirmed
                                        </Badge>
                                        :
                                        ''
                    }
                </div>

                <h6>Time to set up the event.</h6>
                <EventPartiallyList eventData={event} />
                <div className="btnsubmit">

                    {
                        new Date() > new Date(date) && event?.isConfirmed ?
                            <Link className='btn-pink' to={`/recipient/${event?._id}`}>Recipient view</Link>
                            :
                            <>
                            </>
                    }
                    {
                        new Date() > new Date(date) ?
                            ''
                            :
                            event?.isConfirmed || event?.isCancelled == true ?
                                // <Button type="button" className='btngray' onClick={checkbuttonandScroll} >Pre-View Event  </Button>
                                <></>
                                :
                                <>
                                    {/* <Button type="button" className='btngray mb-1' onClick={checkbuttonandScroll} >Pre-View Event  </Button> */}
                                    <Button type="button" className='btn-pink' onClick={() => navigate(`/organiser/${event._id}`)} >Finish setting up event</Button>
                                </>
                    }
                </div>
            </div>
        </>

    );
}



export default EventOwnerBox;