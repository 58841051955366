import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { printUnderScoreText } from '../../utils/helpers';

function ParticipantRowSmall(props) {
    const { participant } = props;
    const [showOptions, setShowOptions] = useState(false);

    const revoke = () => {

    }

    const resendInvite = () => {

    }

    return (
        <>
            <li>
                <h4 className='name'>{participant?.userId == null ? participant?.name : participant?.userId?.fullName}</h4>
                {
                    participant?.role === 'organiser' ? <>
                        <label className='org'>{printUnderScoreText(participant?.role)}</label>
                    </> : <>
                        {
                            participant?.userId == null ? <>
                                <label className='normal'>{printUnderScoreText(participant?.status)}</label>
                            </> : <>
                                <label className='green'>Joined By {printUnderScoreText(participant?.joinType)}</label>
                            </>
                        }
                    </>
                }
            </li>
        </>
    );
}

export default ParticipantRowSmall;
