import React from 'react';
// import Lottie from 'react-lottie';
// import * as unsuccessAnimationData from './unsuccesful.json'
import { AiOutlineClose } from "react-icons/ai";

function UnSuccess() {
    return (
        <>
            <div className='Success-bg'>
                <div className='cross-icon'>
                    <AiOutlineClose />
                </div>
                {/* <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: unsuccessAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                    height={160}
                    width={160}
                    isStopped={false} /> */}
            </div>
        </>
    );
}

export default UnSuccess;
