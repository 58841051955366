import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LandingHeader from "./LandingHeader";
import FaqPage from "../../Components/FAQ's/Index";
import GroupGiftBox from "../../Components/GroupGiftBox/Index";
import Footer from "../../Components/Footer/Index";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { BiCookie } from "react-icons/bi";
import { haveValue, hideLoader, showLoader } from "../../utils/helpers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Landing() {

  const [showCookiesModal, setShowCookiesModal] = useState(false);

  const handleRejectCookies = () => {
    localStorage.setItem('cookieSet', 'false');
    setShowCookiesModal(false);
  };

  const handleAcceptCookies = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 365);
    localStorage.setItem('cookieSet', 'true');
    document.cookie = `cookieSet=true; expires=${expirationDate.toUTCString()}; path=/`;
    setShowCookiesModal(false);
  };

  useEffect(() => {
    showLoader()
    setShowCookiesModal(false);
    const cookieSet = localStorage.getItem('cookieSet');
    if (!cookieSet) {
      setShowCookiesModal(true);
    }
    localStorage.setItem("redirectBackTo", "/landing");
    hideLoader();
  }, []);

  return (
    <>
      <div className="Landing-block">
        <Container>
          <Row>
            <Col lg={8} className="p-0">
              <LandingHeader />

              <div className="banner-content">
                <h1>Group Gifting Simplified</h1>
                <p>
                  Tired of the hassle of coordinating group gifts? We've got you covered!  Introducing weGroup, your go-to group gifting solution, making celebrations memorable and stress-free. Elevate Your Gift-Giving Experience with weGroup!
                </p>
                <br />
                <h5 className="feature-text">Key Features:</h5>
                <ol className="feature-listing">
                  <li><b>Easy Contribution Pooling:</b> Effortlessly collect contributions from friends, family, or colleagues for the perfect gift.</li>
                  <li><b>Collaborative Gift Selection:</b> Vote, suggest, and decide together on a meaningful gift that truly reflects your recipient's wishes.</li>
                  <li><b>Seamless Payment:</b> Secure transactions and flexible payment options ensure a smooth and trustworthy experience for every contributor.</li>
                </ol>
              </div>
              <div className="padding-right-90">
                <div className="banner-shape">
                  <Link to="/getStarted" className="btn-pink">
                    Get Started
                  </Link>
                  <img src="/images/indicator.svg" alt="indicator shape" />
                </div>
                <div className="mob-right-bar">
                  {/* <img
                    src="/images/landing-create-event.jpg"
                    alt="create event image"
                  /> */}
                  <div className="banner-side-slider">
                    <Carousel fade controls={false} indicators={false}>
                      <Carousel.Item interval={2500}>
                        <img
                          className="d-block w-100"
                          src="/images/s1.png"
                          alt="designphone"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={2500}>
                        <img
                          className="d-block w-100"
                          src="/images/s2.png"
                          alt="designphone"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={2500}>
                        <img
                          className="d-block w-100"
                          src="/images/s3.png"
                          alt="designphone"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={2500}>
                        <img
                          className="d-block w-100"
                          src="/images/s4.png"
                          alt="designphone"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={2500}>
                        <img
                          className="d-block w-100"
                          src="/images/s5.png"
                          alt="designphone"
                        />
                      </Carousel.Item>

                    </Carousel>
                  </div>
                </div>
                <div className="howwework-title">
                  <h4>How it works</h4>
                </div>
                <div className="stepping-bar">
                  <div className="iframe-div">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5OJYzHDXEc4" title="How It Works?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                  <ul>
                    <li>1</li>
                    <li>
                      <img src="/images/1users.png" alt="users" />
                      <h5>Create a Group Event & Invite People</h5>
                      <p>
                        Spark the Fun!  Whether it's 2 or 200, our platform syncs everyone for seamless joy and unforgettable memories.
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>2</li>
                    <li>
                      <img src="/images/1reward.png" alt="rewards" />
                      <h5>Everyone contributes money & wishes</h5>
                      <p>
                        Once the event is set up, contribute to the group gift and send high-quality wishes. They'll reach the special one on the chosen day.
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>3</li>
                    <li>
                      <img src="/images/3gifts.png" alt="gifts" />
                      <h5>The Special One Receives the Gift</h5>
                      <p>
                        Collective Effort transforms into shared joy as the special one receives a meaningful gift and heartfelt wishes chosen by everyone on the selected date and time.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="landing-call-component">
                  <GroupGiftBox />
                  <FaqPage />
                  <Footer className="landingfooter" />
                </div>
              </div>
            </Col>
            <Col lg={4} className="p-0">
              <div className="landing-right-bar">
                {/* <img src='/images/landing-create-event.jpg' alt='create event image' /> */}
                <div className="banner-side-slider">
                  <Carousel fade controls={false} indicators={false}>
                    <Carousel.Item interval={2500}>
                      <img
                        className="d-block w-100"
                        src="/images/s1.png"
                        alt="designphone"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                      <img
                        className="d-block w-100"
                        src="/images/s2.png"
                        alt="designphone"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                      <img
                        className="d-block w-100"
                        src="/images/s3.png"
                        alt="designphone"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                      <img
                        className="d-block w-100"
                        src="/images/s4.png"
                        alt="designphone"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                      <img
                        className="d-block w-100"
                        src="/images/s5.png"
                        alt="designphone"
                      />
                    </Carousel.Item>

                  </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {
        !haveValue(localStorage.getItem('cookieSet')) ?
          <>
            <Modal show={showCookiesModal} onHide={handleRejectCookies} className="cookie-modal-full">
              <Modal.Body>
                <div className="cookiesbar">
                  <div className="iconsbar">
                    <BiCookie />
                  </div>
                  <h4>Cookies</h4>
                  <p>This website uses cookies to ensure you get the best experience on our website.</p>
                  <Link to='/cookies' >https://www.wegroup4.com/cookie-policy</Link>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btngray" onClick={handleRejectCookies}>
                  Reject Cookies
                </Button>
                <Button className="btn-pink" onClick={handleAcceptCookies}>
                  Accept Cookies
                </Button>
              </Modal.Footer>
            </Modal>
          </>
          :
          ''
      }
    </>
  );
};

export default Landing;
