import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import config from "../../../Config/Config";
import { AuthContext } from './../../../Context/AuthContext';
import EditDelete from './../editDelete';
import { ShowImage, withBackendUrl,haveValue } from '../../../utils/helpers';
import ReactAudioPlayer from 'react-audio-player';


function AudioTestimonial(props) {
  const navigate = useNavigate();
  const { loggedInUserId } = useContext(AuthContext);
  const { testimonial, viewOnly } = props;
  const [caption, setCaption] = useState('');

  useEffect(() => {
    setCaption(testimonial?.caption);
  }, []);
  const handleEdit = () => {
    localStorage.setItem('editTest', true);
    navigate(`/audio/${testimonial?._id}`);
  }


  return (
    <>
      <div className='Testi-content audio'>
        
         {
            haveValue(testimonial?.media) ?
              <>
                
                <audio controls src={withBackendUrl(testimonial?.media)} autoplay>
                  Your browser does not support the audio element.
                  <source src={withBackendUrl(testimonial?.media)} type="audio/mp3" />
                </audio>
                {/* <iframe src={withBackendUrl(testimonial?.media)} allow="autoplay" id="iframeAudio">
              </iframe> 
              <ReactAudioPlayer
                  src={withBackendUrl(testimonial?.media)}
                  controls
                  onError={(e) => {
                    console.error("Audio playback error:", e);
                  }}
                /> */}
              </>
              :
              ''
          }
        <div className='testimonial-content-head'>
          <div className='headleft'>
            <img src={ShowImage(testimonial?.userId?.profilePhoto, testimonial?.userId?.fullName)} alt='rs' />
            <h6>{testimonial?.userId?.fullName}</h6>
          </div>
          <div className='headryt'>
            <p className='date'>{moment(testimonial?.createdAt).format("MMM DD YYYY")}</p> |
            <p className='time'>{moment(testimonial?.createdAt).format("hh:mm A")}</p>
          </div>
        </div>
        <div className='testimonial-content-text'>
          <p>{testimonial?.caption ? testimonial?.caption : ''}</p>
        </div>
        {
          viewOnly === "true" || viewOnly === true ?
            ''
            :
            <>
              {
                (loggedInUserId === testimonial?.userId?._id) || (testimonial.eventId.createdBy === loggedInUserId) ?
                  <EditDelete onEdit={handleEdit} onRefresh={props.onRefresh} testimonial={testimonial} type="voice" />
                  :
                  ''
              }
            </>
        }
      </div>
    </>
  );
}

export default AudioTestimonial;
