import React from 'react';
import Button from 'react-bootstrap/Button';

function HelpBar() {
  return (
    <>
      <div className='Helpbar'>
        <ul className='Number-email-detail'>
          {/* <li>
            <p>+91 8123477501</p>
            <Button className='btn btn-Tertiary'>Call now</Button>
          </li> */}
          <li>
            <p>info@wegroup4.com</p>
            <Button className='btn btn-Tertiary'>Email us </Button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default HelpBar;
