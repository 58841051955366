import React from 'react';
import Sparkle from '../../../Animations/Sparkle';
import { Link } from 'react-router-dom';
import MoneyCollectBox from '../../../Components/Dashboard/MoneyCollectBox/Index';
import AddParticipant from '../../../Components/Dashboard/AddParticipant/Index';
import AddGiftBox from '../../../Components/Dashboard/AddGiftBox/Index';
import AddTestimonialBox from '../../../Components/Dashboard/AddTestimonialBox/Index';

function OrganiserDashboard() {
  return (
    <>
      {/* EventParticipantBox */}
      <div className="Organiser screen2 Participantbar ">
        <Sparkle />
        <div className="avatar-img">
          <img src="/images/boy-avatar.png" alt="avatar-img" />
        </div>
        <h4><span>Rahul</span>’s <br></br>Birthday Celebration</h4>
        <h6 className="date">18th Aug 2022</h6>
        <div className="timeevent">
          <p>Time to event</p>
          <h5>10d 11h 24m 02s</h5>
        </div>
        <div className='editevent'>
          <Link to='' className='text-btn'>Edit event details</Link>
        </div>
        <div className='preview-wish boxwithnumeric-block' >
          <div className='boxwithnumeric'>
            <p>Preview the wishing screen that Rahul would see on <span>18th Aug at 12:01 pm</span></p>
            <Link className='btn-pink' to='/organiser'>Preview now</Link>
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <MoneyCollectBox />
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            {/* <AddParticipant /> */}
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <AddGiftBox />
          </div>
        </div>
        <div className='boxwithnumeric-block'>
          <div className='boxwithnumeric'>
            <AddTestimonialBox />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganiserDashboard;
