import React, { useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import Success from "../../Animations/Successfull";
import { AuthContext } from "../../Context/AuthContext";
import { concatName, haveValue } from "../../utils/helpers";
import EventHeaderTitle from "./EventHeaderTitle";


const EventSuccess = () => {
    const navigate = useNavigate();
    const { eventData } = useContext(AuthContext);
    let timeoutId;

    const redirectToHome = () => {
        timeoutId = setTimeout(() => {
            navigate('/getStarted');
        }, 4000);
    }

    const redirectToHomeManually = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        navigate('/getStarted');
    }

    useEffect(() => {
        redirectToHome();
    }, [eventData])


    return (
        <>
            <div className="eventbar">
                <EventHeaderTitle />

                <div className="successbar">
                    <Success />
                    {
                        haveValue(eventData?.occasionId) && haveValue(eventData?.name) ?
                            <h6>{eventData?.name}'s {eventData?.occasionId} gifting event created!</h6>
                            :
                            <></>
                    }
                </div>
            </div>
            <Button className="btn-pink" onClick={redirectToHomeManually}  >Continue</Button>

        </>
    );
}

export default EventSuccess;