import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { printUnderScoreText, formateDate, showLoader, hideLoader, haveValue } from '../../utils/helpers';
import eventService from '../../Actions/event.service';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import moment from 'moment';
import { AuthContext } from "../../Context/AuthContext";
import { ThemeContext } from '../../hooks/useThemeContext';

function ParticipantRow(props) {
    const { isLoggedInUserId } = useContext(AuthContext);

    const { participant, event } = props;
    const [showOptions, setShowOptions] = useState(false);
    // const [change, setChange] = useState(false);
    const [RevokeShowclick, RevokeShow] = useState(false);
    const RevokeClose = () => RevokeShow(false);
    const RevokeShowbar = () => RevokeShow(true);
    const [user, setuser] = useState({});


    const { showSuccessToast, showErrorToast } = useContext(ThemeContext);

    useEffect(() => {
    }, [user])

    const revoke = (e) => {

        const id = e._id
        if (window.confirm("Delete the Participant ?")) {
            showLoader();
            eventService.deleteParicipant(id).then(res => {
                if (res.error) {
                    showErrorToast(res.message)
                }
                else {
                    showSuccessToast(res.message)
                }
                RevokeClose();
                props.onUpdate();
                hideLoader();
            }).catch(err => {
                console.log(err, "jhjhjkhkjhkjhjkkj");
                hideLoader();
            });
        }
    }

    const resendInvite = (e) => {
        const id = e._id;
        showLoader();
        eventService.reSendInvitation(id).then(res => {
            hideLoader();
            showSuccessToast('Invitation Resend successfully')
        }).catch(err => {
            console.log(err);
            hideLoader();
        });

    }

    const handleDelete = (e) => {
        setShowOptions(false)
        RevokeShowbar()
        setuser(e)
    }

    const showOption = (e) => {
        if (e != 'joined') {
            setShowOptions(!showOptions)
        }
    }

    return (
        <>


            <li className='revokeli'>
                <div className='li' onClick={() => showOption(participant.status)}>
                    <div className='detail-participant'>
                        <h4>{participant?.userId == null ? participant?.name : participant?.userId?.fullName}</h4>
                        <p>{participant?.userId == null ? participant?.phoneNumber : participant?.userId?.phoneNumber}</p>
                        <p>{participant?.userId == null ? participant?.email : participant?.userId?.email}</p>
                    </div>
                    <div className='btnbar'>
                        {
                            participant?.role === 'organiser' ? <>
                                <label className='org'>{printUnderScoreText(participant?.role)}</label>
                            </> : <>


                                {participant?.status == "joined" ?
                                    <> {isLoggedInUserId(event?.createdBy?._id) ?
                                        <>
                                            {console.log(participant)}
                                            <label className='green'>Joined By {printUnderScoreText(participant?.joinType)}</label>
                                            {
                                                (haveValue(participant?.hasContributed) && participant?.hasContributed == true) ?
                                                    null
                                                    :
                                                    <Button onClick={() => handleDelete(participant)} className='delbtn'><img src='/images/del.png' alt='del' /></Button>
                                            }
                                        </>
                                        :
                                        <label className='green'>Joined By {printUnderScoreText(participant?.joinType)}</label>
                                    }
                                    </>
                                    :
                                    <label className='normal'>{printUnderScoreText(participant?.status)}</label>

                                }

                            </>
                        }
                    </div>
                </div>
                {
                    (showOptions && isLoggedInUserId(event?.participantCanInvite)) ? <>
                        <div className='revokebar'>
                            {participant.status == "declined" ?

                                <Link className='btngray' onClick={() => resendInvite(participant)}>Resend Invite</Link>
                                :
                                <>
                                    <Link className='text-btn' onClick={() => revoke(participant)}>Revoke</Link>
                                    <Link className='btngray' onClick={() => resendInvite(participant)}>Resend Invite</Link>
                                </>
                            }
                        </div>
                    </> : null
                }
                <Modal className='Createevent-modal revokemodal' show={RevokeShowclick} onHide={RevokeClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Remove & Block Participant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='revokebar'>
                            <p>If you feel this participant is not supposed to be in this event, please remove them.</p>
                            <div className='revoke-contain'>
                                <div className='head'>
                                    <p>Invited by: {user?.addedBy?.fullName}</p>
                                    <p>{formateDate(user?.updatedAt)}</p>
                                </div>
                                <div className='revokeweraper'>
                                    <ul>
                                        <li>
                                            <h4>{user?.name}</h4>
                                            <p>{user?.phoneNumber}</p>
                                            <p>{user?.email}</p>
                                        </li>
                                        <li>
                                            <p>Joined via {user?.joinType}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='btnbar'>
                                <Button className='btn-pink' onClick={() => revoke(user)}>
                                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 1.5H11M1 4H16M14.3333 4L13.7489 12.7661C13.6612 14.0813 13.6174 14.7389 13.3333 15.2375C13.0833 15.6765 12.706 16.0294 12.2514 16.2497C11.735 16.5 11.0759 16.5 9.75779 16.5H7.24221C5.92409 16.5 5.26503 16.5 4.74861 16.2497C4.29396 16.0294 3.91674 15.6765 3.66665 15.2375C3.38259 14.7389 3.33875 14.0813 3.25107 12.7661L2.66667 4M6.83333 7.75V11.9167M10.1667 7.75V11.9167" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Remove & Block
                                </Button>
                                <p>This participant will be removed and blocked for this event. Any contribution made either monitory or Wish will be refunded or removed.</p>
                                <Button className='btngray' onClick={RevokeClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </li>
        </>
    );
}

export default ParticipantRow;
