import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useParams } from 'react-router-dom';
import UserPerviewList from '../UserPerviewList'
import useSoloEvent from '../../hooks/useSoloEvent';

function AddKudos(props) {
    const { usersData, event } = props;
    const { id } = useParams();
    const isSolo = useSoloEvent()
    if (!isSolo) {
        return (
            <>
                <div className='AddKudos '>
                    <UserPerviewList usersData={usersData} eventData={event} navigateTo={`/wellwisher/${event?._id}`} />
                    <p>made this gifting event possible</p>

                    <div className='addkudos-form'>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Link className='viewwisher' to={`/wellwisher/${id}`} >View all well wishers</Link>
                            </Form.Group>
                            {/* <Button className='btn-pink' type="submit">Send kudos</Button> */}
                        </Form>
                    </div>


                </div>
            </>
        )

    }

}

export default AddKudos;
