import React from "react";
import { Link } from "react-router-dom";
import AboutUs from "../../Components/WeGroup/AboutUs/Index";
import HelpBar from "../../Components/WeGroup/HelpBar/Index";
import SocialMedia from "../../Components/WeGroup/SocialMedia/Index";


const Profile = () => {
   
    return (
        <>
            <div className="eventbar">
                <Link to='/login' type='button' className='btngray'>Login</Link>
                <Link to="/profilemain"  className='btn-pink mt-3'>Sign Up</Link>
            </div>
            <HelpBar/>
            <SocialMedia/>
            <AboutUs/>
        </>
    );
}

export default Profile;