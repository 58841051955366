import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sparkle from "../../Animations/Sparkle";
import moment from "moment";
import eventService from "../../Actions/event.service";
import { AuthContext } from "./../../Context/AuthContext";
import { ThemeContext } from "../../hooks/useThemeContext";
import { ShowImage, getTotalContri, hideLoader, showLoader, isNotEmpty, haveValue, getDateLocal } from "../../utils/helpers";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const InviteParticipant = (props) => {
    const { eventDetails, hash } = props;
    const { isLoggedIn } = useContext(AuthContext);

    const { id } = useParams();
    const navigate = useNavigate();
    const [viewDetail, setViewDetail] = useState(false);
    const [joinedPart, setJoinedPart] = useState([]);
    const [eventData, setEventData] = useState([]);
    const { showErrorToast, showSuccessToast } = useContext(ThemeContext);
    const [date, setDate] = useState('')

    useEffect(() => {
        setEventData(eventDetails);
        setDate(getDateLocal(eventDetails?.eventStart, eventDetails?.eventTime))
    }, [eventDetails])


    useEffect(() => {
        // showLoader();
        let resultA = [];
        if (isNotEmpty(eventData?.participants)) {
            const newFilter = Object.keys(eventData?.participants).reduce((result, key) => {
                if (eventData?.participants[key].status.includes("joined")) {
                    resultA.push(eventData?.participants[key]);
                };
                setJoinedPart(resultA);
            }, []);
        }
        if (localStorage.getItem('autojoin') && localStorage.getItem('autojoin') == "accept") {
            handleAccept();
        }
        if (isNotEmpty(eventData) && (localStorage.getItem('autojoin') && localStorage.getItem('autojoin') == "reject")) {
            handleDecline();
        }
        hideLoader();
    }, [eventData])

    const handleViewClose = () => {
        setViewDetail(false);
    }


    const handleAccept = () => {
        hideLoader();
        if (haveValue(eventData?.maxParticipants)) {
            if (eventData?.maxParticipants > joinedPart?.length) {
                if (isLoggedIn) {
                    eventService.acceptInvite(hash, "joined").then(res => {
                        showSuccessToast('Invite has been Accepted');
                        localStorage.removeItem('link');
                        localStorage.removeItem('autojoin');
                        setTimeout(() => {
                            toast.dismiss();
                            navigate(`/getStarted`);
                        }, 3000);
                    }).catch(err => {
                        showErrorToast(err.message);
                        setTimeout(() => {
                            toast.dismiss();
                            navigate(`/getStarted`);
                        }, 2000);
                        console.log(err);
                        localStorage.removeItem('link');
                    });
                } else {
                    localStorage.setItem('link', `/invitation/${id}`);
                    localStorage.setItem('autojoin', "accept");
                    navigate('/login');
                }
            } else {
                showErrorToast("Maximum Participant Limit Reached.");
                handleViewClose();
                setTimeout(() => {
                    toast.dismiss();
                    navigate(`/getStarted`);
                }, 2000);
            }
        }
    }

    const handleDecline = () => {
        hideLoader();
        if (isLoggedIn) {
            if (haveValue(hash)) {
                localStorage.removeItem('link');
                localStorage.removeItem('autojoin');
                eventService.rejectInvite(hash, "declined").then(res => {
                    showSuccessToast('Invite has been Declined');
                    setTimeout(() => {
                        toast.dismiss();
                        navigate(`/getStarted`);
                    }, 3000);
                }).catch(err => {
                    localStorage.removeItem('link');
                    localStorage.removeItem('autojoin');
                    console.log(err, "====errror reject")
                    showErrorToast(err.message);
                    setTimeout(() => {
                        toast.dismiss();
                    }, 2000);
                });
            }
        } else {
            localStorage.setItem('link', `/invitation/${id}`);
            localStorage.setItem('autojoin', `reject`);
            navigate('/login');
        }
    }


    return (
        <>

            <div className="Home-component EventPartially Participantbar ">
                <Sparkle />
                <div className="invitecontent">
                    <p><b>{eventData?.createdBy?.fullName}</b> has invited you to this group gifting event.</p>
                </div>
                <h4><span>{eventData?.name}</span>'s <br></br>{eventData?.occasionId} Celebration</h4>
                {
                    eventData?.eventStart && eventData?.eventTime ?

                        <h6 className="date">{moment(date).format("LL")} {moment(date).format('h:mm a')}</h6>
                        :
                        ''
                }

                <div className="btnsubmit ">
                    <button type="button" className='btn-pink' onClick={() => setViewDetail(true)}>
                        Join the group
                    </button>
                    <button type="button" className='btngray' onClick={() => handleDecline()} >Decline Invitation</button>
                </div>
            </div>

            <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={viewDetail} onHide={handleViewClose}>
                <Modal.Header className="faviconadded" closeButton>
                    <img src="./../../../images/favicon.png" />
                    weGroup
                </Modal.Header>
                <Modal.Body>
                    <div className="recipient-title">Recipient Detail</div>
                    <div className="recipient-detail-box">
                        <div className="userdetail">
                            <img src={ShowImage(eventData?.photo)} alt="img" />
                        </div>
                        <div className="namebar">
                            <h4>{eventData?.name}</h4>
                            <p>{eventData?.email}</p>
                            <p>+{eventData?.phone}</p>
                            <p>Contribution: {getTotalContri(eventData?.contriPerPerson)}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={handleAccept} className="btn-pink">Join</Button>
                    <Button className="btngray" onClick={handleViewClose} type="button">Cancel</Button>
                </Modal.Footer >
            </Modal >
        </>

    );
}

export default InviteParticipant;