import React, { useState, useContext, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import AuthAction from "../Actions/auth.service";
import EventAction from "../Actions/event.service";
import FieldError from "../Components/Common/FieldError";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { haveValue, showLoader, hideLoader } from "../utils/helpers";
import Moment from 'moment';
import { ThemeContext } from "../hooks/useThemeContext";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InfoFill = (props) => {
    // State variables
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const { showErrorToast } = useContext(ThemeContext);
    const { setIsLoggedIn, firebaseIdToken, eventData } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        const googleDataI = localStorage.getItem('googleData');
        setEmail(JSON.parse(googleDataI)?.email);
        setFirstName(JSON.parse(googleDataI)?.given_name);
        setLastName(JSON.parse(googleDataI)?.family_name);
    }, [])

    const addEvent = async () => {
        if (localStorage.getItem('byPassLogin') && haveValue(eventData)) {
            const selectedDate = new Date(eventData?.date.concat(", ", eventData?.time))
            const utcTime = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000)
            const data = new FormData();
            data.append("name", eventData?.firstName + " " + eventData?.lastName);
            data.append("eventDescription", eventData?.eventDescription);
            data.append("email", eventData?.email);
            data.append("occasionId", eventData?.occasionId);
            // data.append("eventStart", Moment(eventData?.date).format("LL"));
            // data.append("eventTime", eventData?.time);
            data.append("eventStart", Moment(utcTime).format("LL"));
            data.append("eventTime", Moment(utcTime).format("h:mm A"));
            data.append("phone", eventData?.phone);
            data.append("isSolo", eventData?.isSolo)

            if (eventData?.icon) {
                data.append("file", eventData?.icon);
            }
            showLoader();
            EventAction.createEvent(data).then(res => {
                localStorage.setItem('name', res?.name);
                localStorage.setItem('occasion', res?.occasionId);
                localStorage.setItem('eventId', res?._id);
                localStorage.removeItem('byPassLogin');
                hideLoader();
                navigate('/eventSuccess');
            }).catch(err => {
                showErrorToast("Failed to create Event");
                hideLoader();
                console.log(err)
            });
        }
    }


    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            // Validations
            if (!firstName) return setNameError('Please enter first name')
            else setNameError('')
            if (!email) return setEmailError('Please enter email')
            else setEmailError('')

            if (!EMAIL_REGEX.test(email)) {
                return setEmailError('Please enter valid email')
            }
            let body = {
                "fullName": (haveValue(firstName) ? firstName : "") + " " + (haveValue(lastName) ? lastName : ""),
                "email": email,
                "firebaseIdToken": firebaseIdToken
            };
            AuthAction.saveDetails(body).then(res => {
                localStorage.removeItem('googleData');
                const token = res.token;
                localStorage.setItem("token", token);
                setIsLoggedIn(true);
                addEvent();
                if (localStorage.getItem("link")) {
                    const link = localStorage.getItem('link')
                    navigate(`${link}`)
                } else {
                    if (localStorage.getItem('link')) {
                        const link = localStorage.getItem('link')
                        navigate(`${link}`)
                    } else {
                        navigate("/getStarted");
                    }
                }
            }).catch(err => {
                if (err?.response?.data?.message === "Email already exist Please Enter Other email") {
                    return setEmailError(`${err?.response?.data?.message}`);
                } else {
                    showErrorToast(`${err?.response?.data?.message}`);
                }
            });
        } catch (err) {
            console.log('Check the submit issue - ', err)
        }
    }
    return (
        <>
            <div className="loginform">
                <h4 className="form-title">Complete your profile</h4>
                <Form>
                    <Container>
                        <Row className='col-inline'>
                            <Col>
                                <Form.Group className="formgroup" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your First Name" value={firstName} onChange={e => {
                                        const regex = /^[a-zA-Z0-9 ]*$/;
                                        const inputValue = e.target.value;
                                        if (regex.test(inputValue)) {
                                            setFirstName(inputValue);
                                        }
                                    }
                                    } />
                                </Form.Group>
                                <FieldError error={nameError} />
                            </Col>
                            <Col>
                                <Form.Group className="formgroup" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your Last Name" value={lastName} onChange={e => {
                                        const regex = /^[a-zA-Z0-9 ]*$/;
                                        const inputValue = e.target.value;
                                        if (regex.test(inputValue)) {
                                            setLastName(inputValue);
                                        }
                                    }
                                    } />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                    <Form.Group className="formgroup" controlId="formBasicEmail">
                        <Form.Label>Your Email</Form.Label>
                        {
                            localStorage.getItem('googleData') ?
                                <Form.Control type="email" placeholder="Enter your email" value={email} readOnly disabled />
                                :
                                <Form.Control type="email" placeholder="Enter your email" value={email} onChange={e => {
                                    setEmail(e.target.value)
                                }} />

                        }
                        <p className='error-line'>{emailError}</p>
                    </Form.Group>

                    <div className='started-btn'>
                        <button handleFirebase={(e) => console.log(e)} className='btn-pink' onClick={submitHandler}>Submit & Create Account</button>
                        {/* <Link to="/home" className='btn-pink'>Submit & Create Account</Link> */}
                    </div>
                </Form>
            </div>
        </>
    );
}

export default InfoFill;