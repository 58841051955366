import React from 'react';

function FaqTitle() {
  return (
      <>
      <div className='FaqTitle'>
        <h4>Frequently asked questions</h4>
        <p>Everything you need to know about weGroup</p>
      </div>
      </>
  );
}

export default FaqTitle;
