import React, { useRef, useContext, useState } from "react";
import settings from "../../Config/Config";
import { ShowImage, haveValue, withBackendUrl } from '../../utils/helpers';
import PhotoMasonry from "../Testimonials/TestimonialTabs/PhotoMasonry";
import { BsHourglassSplit, BsShare } from "react-icons/bs";
import { ThemeContext } from '../../hooks/useThemeContext';
import { Button, Modal } from "react-bootstrap";

function UserPerviewListRec(props) {
  const videoRef = useRef(null);

  const { usersData, videoLink, event, hasVideo } = props;
  const { osName } = useContext(ThemeContext);
  const [showAlert, setShowAlert] = useState(false)

  const handleShare = async () => {
    try {
      if (haveValue(videoLink)) {
        const response = await fetch(withBackendUrl(videoLink));
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const file = new File([blob], 'video.mp4', { type: blob.type });
        a.href = url;
        a.download = 'video.mp4';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;


        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        else {
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Share Recipient Video',
              text: 'Check out this video!',
              url: withBackendUrl(videoLink)
            });
          } else {
            alert('Web Share API not supported in this browser. Please use a modern browser.');
          }
        }
      } else {
        console.info('Video not Found');
      }
    } catch (error) {
      alert('Error fetching or sharing the video:', error);
    }
  };

  const handleCheckPlatform = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setShowAlert(true)
    }
    else {
      handleShare()
    }
  }
  const handleDownloadClick = () => {
    setShowAlert(false)
    handleShare()
  }

  return (
    <>
      {
        usersData != null ? <>
          <div className="country-price rec-listing">
            {/* {usersData?.images?.map(item => <> */}
            {
              haveValue(usersData?.label) ?
                <>
                  <ul className="perviewlist">
                    <li>
                      <h6 className="allusers-text">{usersData?.label} wrote Wishes</h6>
                    </li>
                  </ul>
                </>
                :
                <></>
            }

            {
              haveValue(videoLink) ?
                <>
                  <div className="py-2 video-with-share-btn">
                    <video loop controls="true" width='100%' height='100%' ref={videoRef} crossOrigin="anonymous">
                      <source src={withBackendUrl(`${videoLink}#t=0.001`)} type="video/mp4" />
                    </video>
                    <div className="btnshare-video">
                      <button className="bntshare" onClick={handleCheckPlatform}><BsShare /></button>
                    </div>
                  </div>
                </>
                :
                event?.isConfirmed && hasVideo ?
                  <p className="underprocess">Video Under Processing. <BsHourglassSplit /></p>
                  :
                  ''
            }
            <PhotoMasonry />
            {/* </>)} */}

          </div>
        </> : null
      }
      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showAlert} onHide={e => setShowAlert(false)}>
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          Once video is downloaded, you can access it in the Files and share it anywhere you want.
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-pink' onClick={handleDownloadClick}>
            Download
          </Button>
          <Button className='btngray' onClick={e => setShowAlert(false)} type="button">Cancel</Button >
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserPerviewListRec;