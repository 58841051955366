import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Sparkle from "../../Animations/Sparkle";
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';
import { getDateLocal } from '../../utils/helpers';
import useSoloEvent from '../../hooks/useSoloEvent';

const EventPartially = (props) => {
    const isSolo = useSoloEvent()
    const { events } = props;
    const navigate = useNavigate();
    const checkbuttonandScroll = (link) => {
        localStorage.setItem("scroll", `false`);
        navigate(link);
    }

    return (
        <>
            {events.map((item) =>
                <>
                    <div key={item._id} className="Home-component EventPartially">
                        <Sparkle />

                        <h4><span> {item?.name} </span>’s <br></br>{item?.occasionId} Celebration {isSolo ? "Solo Event" : "Group Event"}</h4>
                        <h6>Time to set up the event.</h6>
                        <ul className="EventPartially-list">
                            <li>
                                <span>1</span>
                                {/* {moment(item?.eventStart).format("LLL")} */}
                                {moment(getDateLocal(item?.eventStart, item?.eventTime)).format("LLL")}
                            </li>
                            <li>
                                <span>2</span>
                                Select gift options
                            </li>
                            <li>
                                <span>3</span>
                                Configure & contribute money
                            </li>
                            <li>
                                <span>4</span>
                                Invite {item?.name}'s' friend's and family
                            </li>
                            <li>
                                <span>5</span>
                                Send Wishes
                            </li>
                        </ul>

                        <div className="btnsubmit">
                            <Button type="button" className='btn-pink' onClick={() => navigate(`/organiser/${item._id}`)}  >Finish setting up event</Button>
                            {/* <Button type="button" className='btngray' onClick={checkbuttonandScroll(`/organiser/${item._id}`)} >Pre-View Event</Button> */}
                        </div>

                    </div>
                </>
            )}
        </>
    );
}



export default EventPartially;