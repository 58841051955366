import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import publicAction from "../../Actions/public.service";
import { showLoader, hideLoader } from '../../utils/helpers';

function FaqAccordion() {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    showLoader();
    publicAction.getFaqs().then(res => {
      setFaqList(res);
      hideLoader();
    }).catch(err => {
      hideLoader();
      console.error(err, "==error");
    });
  }, []);
  return (
    <>
      <div className='FaqAccordion'>
        <Accordion defaultActiveKey="0">
          {faqList.slice(0, 4).map((item, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{item?.question}</Accordion.Header>
              <Accordion.Body>
                <p className="post__content" dangerouslySetInnerHTML={{ __html: item?.answer }}></p>
              </Accordion.Body>
            </Accordion.Item>

          ))}
        </Accordion >
        <div className='seeall'>
          <Link className='btn-pink' to='/faqs'>See All FAQ's</Link>
        </div>
      </div >
    </>
  );
}

export default FaqAccordion;
