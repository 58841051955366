import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OtpInput from "react-otp-input";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import AuthAction from "../Actions/auth.service";
import { AuthContext } from "../Context/AuthContext";
import { useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firbase";
import { ThemeContext } from "../hooks/useThemeContext";

const EnterOtp = (props) => {

  const { userData, setUserData } = props;
  const [isError, setIsError] = useState(false);
  let cookie = new Cookies();
  const navigate = useNavigate();
  const { phoneNumber } = useContext(AuthContext);
  let recaptchaWrapperRef;


  const [Terms, setTerms] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [user, setUser] = useState();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [otp, setOtp] = useState('');
  // const [minutes, setMinutes] = useState(1);
  const [times, setTimes] = useState(3);
  const [seconds, setSeconds] = useState(15);
  const [googleToken, setGoogleToken] = useState('');
  const [inCorrect, setIncorrect] = useState(false);

  const { showErrorToast } = useContext(ThemeContext);

  // useReadOTP(setOtp);

  // useEffect(() => {
  // }, [otp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        // if (minutes === 0) {
        clearInterval(interval);
        // } else {
        // setSeconds(59);
        // setMinutes(minutes - 1);
        // }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const GoogleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      setGoogleToken(tokenResponse);
    }
  });
  useEffect(() => {
    if (googleToken != '') {
      googleLoginApi();
    }
  }, [googleToken])
  const googleLoginApi = async () => {
    AuthAction.verifyGoogleSignUp(googleToken).then(res => {
      const token = res.token;
      localStorage.setItem("token", token);
      setIsLoggedIn(true)
      if (res.newUserCreated) {
        navigate("/infofill");
      } else {
        if (localStorage.getItem("link")) {

          const link = localStorage.getItem('link')
          navigate(`${link}`)

        } else {
          navigate("/getStarted");
        }
      }
    }).catch(err => {
    });
  };

  const submitOtp = async () => {

    setLoadingOtp(true);

    if (Terms == false) {
      showErrorToast('Please Agree to terms and conditions');
      setLoadingOtp(false);
      return false;
    }

    if (otp === "" || otp === null) return;
    try {
      let data = await userData.confirm(otp);
      const firebaseIdToken = data._tokenResponse;
      cookie.set("x-access-token-gt", data._tokenResponse.idToken);
      setUser(data._tokenResponse.idToken);
      setLoadingOtp(false);
      setOtp("");
      AuthAction.loginSignup({ firebaseIdToken }).then(res => {
        const token = res.token;
        setIncorrect(false);
        localStorage.setItem("token", token);
        setIsLoggedIn(true);
        if (res.newUserCreated) {
          navigate("/infofill");
        } else {
          if (localStorage.getItem('link')) {
            const link = localStorage.getItem('link')
            navigate(`${link}`)
          } else {
            navigate("/getStarted");
          }
        }


      }).catch(err => {
      });

    } catch (err) {
      // toast("Incorrect OTP", err);
      setIncorrect(true);
      setLoadingOtp(false);
    }
  };
  const submitHandler = async () => {

    if (recaptchaWrapperRef) {
      recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
    }
    const number = "+" + phoneNumber;
    const recaptchaVerifier = await new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    let result = {};
    signInWithPhoneNumber(auth, number, recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        result = confirmationResult;
        let dataToSend = {
          phoneNumber: "+" + phoneNumber,
        };
        setUserData(result)
        setLoadingOtp(false);
        document.getElementById("hellllll").innerHTML =
          "<div id='recaptcha'></div>";
        recaptchaVerifier.clear();
        setIsError(false);
        navigate("/enterotp");
      })
      .catch((error) => {
        setLoadingOtp(false);
        // alert("Auth/invalid-phone-number");
        document.getElementById("hellllll").innerHTML =
          "<div id='recaptcha'></div>";
        recaptchaVerifier.clear();
        setIsError(true);
        // toast("Failed to send OTP. Please check your mobile number.");
      });
  };
  const resendOtp = async () => {
    setTimes(times - 1);
    setSeconds(15);
    submitHandler();
  };


  return (
    <>
      <div className="loginform">
        <h4 className="form-title">Login / Signup</h4>
        <Form>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Enter OTP</Form.Label>
            <div className="otpinputs">
              <OtpInput
                className={inCorrect ? 'otpbar error' : 'otpbar'}
                placeholder="000000"
                value={otp}
                isInputNum={true}
                onChange={setOtp}
                numInputs={6}
                separator={<span>-</span>}
              />
            </div>
            {inCorrect ? <p className='error-line'>Incorrect OTP.</p> : ''}
            <div className="terms-bar">
              <Form.Check type="checkbox" className="checkbox" onChange={e => setTerms(!Terms)} />
              <div className="Terms-text">
                <p>I agree to all the Terms & Conditions</p>
                <Link to="/">Read the terms & conditions</Link>
              </div>
            </div>
          </Form.Group>
          <div className="started-btn">
            {loadingOtp ? (
              <button className="btn-pink" onClick={() => { }}>
                Loading....
              </button>
            ) : (
              <button
                className="btn-pink"
                onClick={(e) => {
                  e.preventDefault();
                  submitOtp();
                }}
              >
                Submit
              </button>
            )}
          </div>
          <div className="resend-otp">
            {
              seconds > 0 ?
                <Button type="button" className="btngray timerbtn">
                  <span className="timer">
                    Resend OTP (
                    {seconds < 10 ? `00:0${seconds}` : `00:${seconds}`}
                    )
                  </span>
                </Button>
                :
                times > 0 ?
                  <Button type="button" className="btngray" onClick={resendOtp}>
                    Resend OTP  {`( ${times} Left )`}
                  </Button>
                  :
                  <Button type="button" className="btngray timerbtn" >
                    <span className="timer">
                      Resend OTP
                    </span>
                  </Button>

            }
          </div>
          {/* <div className="form-divider">
            <span>OR</span>
          </div>
          <button type="button" className="gbtn" onClick={GoogleLogin}>
            <img src="/images/google.png" alt="logo" />
            Sign up with Google
          </button> */}
        </Form>
        <div id="hellllll" ref={(ref) => (recaptchaWrapperRef = ref)}>
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
