import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LandingHeader from "./LandingHeader";
import FaqPage from "../../Components/FAQ's/Index";
import GroupGiftBox from "../../Components/GroupGiftBox/Index";
import Footer from "../../Components/Footer/Index";
import { Link } from "react-router-dom";

const LandingBlogs = () => {
  return (
    <>
      <div className="Landing-block">
        <Container>
          <Row>
            <Col lg={7} className="p-0">
              <LandingHeader />
              <div className="banner-content blog-content">
                <h1>Blogs</h1>
                <div className="banner-shape">
                  <Link to="/getStarted" className="btn-pink">
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="padding-right-90 border-dooted-top">
                <div className="blogs-box">
                  <h4>
                    The Joy of Group Gifting: Creating Lasting Memories
                    Together
                  </h4>
                  <p><b>Dear readers,</b></p>
                  <p>
                    Today, I want to share a heartwarming story that celebrates
                    the power of group gifting and the emotions it brings forth.
                    We all know that gifting is a way to express love,
                    appreciation, and care for our friends and family, but when
                    a group comes together to give a collective present, it
                    becomes an extraordinary experience that creates
                    unforgettable memories.
                  </p>
                  <p>
                    Imagine this scene: a circle of close-knit friends huddled
                    together, their smiles wide with anticipation, as they hand
                    over a beautifully wrapped package to their dear friend on a
                    special occasion. As the recipient unwraps the gift, their
                    eyes light up, not only because of the present itself but
                    also because of the love and thoughtfulness that went into
                    organizing such a meaningful gesture.
                  </p>
                  <p>
                    Group gifting is not just about pooling resources; it's a
                    shared expression of affection and a symbol of unity. Each
                    participant in the group brings their unique touch to the
                    present, and it reflects the varied personalities and
                    relationships within the circle. From carefully selected
                    gifts to heartfelt notes attached, every detail is a
                    testament to the bonds that tie these friends together.
                  </p>
                  <p>
                    But the true magic of group gifting lies in the emotions it
                    elicits. As the recipient receives the gift, they are
                    overwhelmed with joy, knowing that multiple people took time
                    and effort to make their day extra special. The sense of
                    appreciation and love fills the air, and the atmosphere
                    becomes charged with positive energy. It's a moment of
                    connection and understanding that transcends material
                    possessions.
                  </p>
                  <p>
                    Furthermore, group gifting fosters a sense of belonging and
                    inclusivity. It brings everyone together for a common
                    purpose, reinforcing the idea that life's most beautiful
                    moments are best shared with loved ones. In the process,
                    friendships grow stronger, and the ties that bind the group
                    become even tighter.
                  </p>
                  <p>
                    Sometimes, it's not just the recipient who benefits from
                    group gifting. The act of coming together as a group to plan
                    and execute the perfect gift is a heartwarming experience
                    for the givers as well. It's an opportunity to collaborate,
                    communicate, and create something special that goes beyond
                    the sum of its parts.
                  </p>
                  <p>
                    Of course, like any endeavor, group gifting may come with
                    its challenges. Coordinating schedules, budgeting, and
                    ensuring everyone is on the same page requires effort and
                    patience. However, the sense of accomplishment and the sheer
                    joy of giving as a team make it all worthwhile.
                  </p>
                  <p>
                    In a world where material possessions often overshadow
                    emotions, group gifting serves as a beautiful reminder of
                    the true value of human connections. It shows that the
                    strength of our relationships lies not in the cost of the
                    gift but in the sincerity and love behind it.
                  </p>
                  <p>
                    So, dear readers, let us embrace the magic of group gifting
                    and the emotions it evokes. Let us celebrate the joy of
                    creating lasting memories together, one heartfelt present at
                    a time.
                  </p>
                </div>
                <div className="blogs-box">
                  <h4>Group gifting on our Radipro website</h4>
                  <p>
                    Introducing the power of collective love and the joy of
                    shared experiences. Imagine a gift that transcends material
                    possessions, a gift that unites hearts and creates lasting
                    memories. Today, I invite you to embrace the transformative
                    concept of group gifting.
                  </p>
                  <p>
                    In a world where connection matters more than ever, group
                    gifting brings people closer together, embodying the spirit
                    of unity, love, and support. It's about combining the
                    collective contributions of friends, family, and loved ones
                    to create something truly extraordinary.
                  </p>
                  <p>
                    Think of a milestone birthday, a wedding anniversary, or a
                    special celebration. Instead of a single present, imagine
                    the overwhelming joy that comes from receiving a customized
                    gift that embodies the heartfelt sentiments of an entire
                    community. It's an opportunity to express the deepest
                    emotions, strengthen bonds, and ignite a sense of
                    togetherness.
                  </p>
                  <p>
                    By pooling resources, we unlock the power to give something
                    truly meaningful—a gift that reflects the recipient's
                    passions, dreams, and desires. It's a testament to their
                    importance in our lives, a symbol of unwavering support and
                    the belief that dreams can be realized when we stand
                    together.
                  </p>
                  <p>
                    But it's not just about the recipient. Group gifting
                    empowers each contributor to be part of something bigger, to
                    share their love and generosity openly. It allows everyone
                    to play a role, no matter their budget, ensuring that each
                    person's contribution is celebrated and cherished.
                  </p>
                  <p>
                    Imagine the joy on their face when they receive this
                    collective expression of love. Tears of happiness, laughter
                    that echoes through the room, and memories that will be
                    treasured for a lifetime. It's the gift that keeps on
                    giving, woven with the threads of countless hearts, forever
                    binding us in a tapestry of love.
                  </p>
                  <p>
                    Together, we have the power to make dreams come true, to
                    create a gift that surpasses expectations, and to touch the
                    deepest emotions of those we cherish. Let us embark on this
                    journey of shared love, and with each group gift, let us
                    weave a story that will be remembered and celebrated for
                    years to come.
                  </p>
                </div>
                <div className="landing-call-component">
                  <FaqPage />
                  <Footer className="landingfooter" />
                </div>
              </div>
            </Col>
            <Col lg={5} className="p-0">
              <div className="landing-right-bar">
                <img src="/images/home-create.jpg" alt="create event image" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingBlogs;
