import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

function SocialMedia() {

  const handleInstagram = async () => {
    window.open('https://www.instagram.com/social.wegroup4/', '_blank')
  }
  const handleFacebook = async () => {
    window.open('https://www.facebook.com/profile.php?id=61552328150885', '_blank')
  }
  const handleTwitter = () => {
    window.open('https://twitter.com/social_weGroup4')
  }
  const handleYoutube = () => {
    window.open('https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w')
  }

  return (
    <>
      <div className="SocialMedia">
        <h4>Follow us on social media</h4>
        <ul className="Social-bar">
          <li>
            {/* <div className="Leftbar" onClick={handleFacebook}> */}
            <a className="Leftbar" href='https://www.facebook.com/profile.php?id=61552328150885' target='_blank'>
              <img className="social-img" src="/images/Facebook.png" alt="fb" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleInstagram}> */}
            <a className="Leftbar" href='https://www.instagram.com/social.wegroup4/' target='_blank'>
              <img className="social-img" src="/images/instagram.png" alt="instagram" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleTwitter}> */}
            <a className="Leftbar" href='https://twitter.com/social_weGroup4' target='_blank'>
              <img className="social-img" src="/images/TwitterNew.png" alt="twitter" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleYoutube}> */}
            <a className="Leftbar" href='https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w' target='_blank'>
              <img className="social-img" src="/images/youtube.png" alt="twitter" />
            </a>
            {/* </div> */}
          </li>
          <li>
            <div className="Leftbar">
              <img className="social-img" src="/images/linkedin.png" alt="twitter" />
            </div>
          </li>
        </ul>
        {/* <h4>Newsletter subscribe</h4>
        <div className="newsletter-form">
          <Form>
            <Form.Group className="mb-0" controlId="emailaddress">
              <Form.Control type="email" placeholder="Email Address" />
            </Form.Group>
            <div className="formbtn">
              <button className='btn-pink' type="submit">
                Subscribe
              </button>
            </div>
          </Form>
        </div> */}
      </div>
    </>
  );
}

export default SocialMedia;
