import React, { useState, useEffect, useContext } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { hideLoader, showLoader, withBackendUrl } from "../../../utils/helpers";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import TestimonialAction from "../../../Actions/testimonial.service";
import { ThemeContext } from "../../../hooks/useThemeContext";
import { AuthContext } from "../../../Context/AuthContext";

function PhotoMasonry(props) {
  const navigate = useNavigate();

  const { id } = useParams();
  const [testimonialList, setTestimonialList] = useState([]);
  const { showSuccessToast, setBackUrlContext, showErrorToast } = useContext(ThemeContext);
  const { setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);

  const getTestimonials = () => {
    showLoader();
    TestimonialAction.testimonialList(id, "image", "").then(res => {
      setTestimonialList(res.testimonials);
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }
  useEffect(() => {
    getTestimonials()
  }, [])


  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleClose = () => setShow(false);

  const handleShow = (image) => {
    setModalImage(image);
    setShow(true);
  };
  return (
    <>
      {
        testimonialList.length > 0 ?
          <div className="Testi-content photo">
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 767: 2, 900: 3 }}>
              <Masonry className="gallerybar" gutter={10}>
                {testimonialList.map((image, i) => (
                  <img
                    key={i}
                    src={withBackendUrl(image?.media)}
                    style={{ width: "100%", display: "block" }}
                    alt="images"
                    onClick={() => handleShow(image)}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
            <Modal className="masonry-modal" show={show} onHide={handleClose}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <img
                  src={withBackendUrl(modalImage?.media)}
                  style={{ width: "100%", display: "block" }}
                  alt="images"
                />
                <h3>From {modalImage?.userId?.fullName}</h3>
              </Modal.Body>
            </Modal>
          </div>
          :
          ''
      }
    </>
  );
}

export default PhotoMasonry;
