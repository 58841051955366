import React, { useState, useEffect, useContext, useRef } from 'react';
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import UserPerviewList from "../UserPerviewList";
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import EventAction from "./../../Actions/event.service";
import contributionAction from "./../../Actions/contribution.service";
import moment from 'moment';
import { AuthContext } from "../../Context/AuthContext";
import { withBackendUrl, hideLoader, getUserImagesAndName, usersInContributions, ShowImage, showLoader, haveValue, getTotalContri, isNotEmpty, getDateLocal } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';

function TotalContribute(props) {
  const { id } = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedInUserId, setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);
  const { showSuccessToast, showErrorToast, setBackUrlContext, hide, setHide } = useContext(ThemeContext);
  const [usersData, setUsersData] = useState([]);
  const [denyModal, setDenyModal] = useState(false);

  const [yetToContributionList, setYetToContributionList] = useState([]);

  const [alertShow, setAlertShow] = useState(false);
  const [eventData, setEventData] = useState({});
  const [confirmOrderMessage, setConfirmOrderMessage] = useState("Are you sure you want to confirm your order?");
  let result = {};
  const [date, setDate] = useState('')

  useEffect(() => {
    setHide(true);
    yetTocontributeList()
    viewEvent()
    contributionAction.contributionList(id).then(res => {
      let userListData = usersInContributions(res);
      setUsersData(userListData);
      hideLoader();
    }).catch(err => {
      console.log(err)
    });
  }, [])

  const handleClosedateadded = () => {
    setAlertShow(false);
  }

  useEffect(() => {
    setDate(getDateLocal(eventData?.eventStart, eventData?.eventTime))
  }, [eventData])


  const viewEvent = () => {
    showLoader();
    EventAction.viewEvent(id).then(res => {
      hideLoader();
      setEventData(res);
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  };
  const hideNumber = (phone) => {
    if (isNotEmpty(phone) && phone.length > 9) {
      let prefixLength = 6;
      let suffixLength = 2;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;

      return formattedPhone
    } else {
      return phone
    }
  }
  const hideEmail = (email) => {
    if (isNotEmpty(email)) {
      let emailSplit = email.split('@')
      let newEmail = emailSplit[0]
      let prefixLength = 1;
      let suffixLength = 1;

      let prefix = newEmail.substring(0, prefixLength);
      let suffix = newEmail.slice(-suffixLength);
      let nbStars = newEmail.length - (prefixLength + suffixLength);
      let formattedEmail = prefix + "*".repeat(nbStars) + suffix + '@' + emailSplit[1]

      return formattedEmail
    } else {
      return email
    }
  }
  const confirmOrder = () => {
    showLoader();
    EventAction.confirmOrder(id).then(res => {
      showSuccessToast('Event Confirmed');
      setEventData(res);
      setAlertShow(false);
      hideLoader();
      navigate(`/organiser/${id}`)
    }).catch(err => {
      hideLoader();
      console.log(err)
      showErrorToast('Please try again later')
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        toast.dismiss();

        navigate('/');
      }
    });
  };
  const yetTocontributeList = () => {
    showLoader();
    contributionAction.yetTocontributeList(id).then(res => {
      hideLoader();
      setYetToContributionList(res);
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const ConfirmEventhandleShow = () => {
    let resultA = [];
    let resultB = [];
    let organiserExist = false;
    if (haveValue(yetToContributionList)) {
      const newFilter = Object.keys(yetToContributionList).reduce((result, key) => {
        if (!yetToContributionList[key].role.includes("organiser")) {
          resultA.push(yetToContributionList[key]);
        };
        if (yetToContributionList[key].role.includes("organiser")) {
          organiserExist = true;
        };
      }, []);
    }
    if (haveValue(eventData?.participants)) {
      const newFilter = Object.keys(eventData?.participants).reduce((result, key) => {
        if (eventData?.participants[key].status.includes("joined") && !eventData?.participants[key].role.includes("organiser")) {
          resultB.push(eventData?.participants[key]);
        };
      }, []);
    }
    if (resultA && resultA.length > 0) {
      setConfirmOrderMessage('Few participants are yet to contribute.  Are you sure you want to confirm your order?');
      setAlertShow(true);
    } else if (organiserExist) {
      if (eventData?.isSolo) {
        setDenyModal(true);
      } else {
        setConfirmOrderMessage('You have not contributed. Are you sure you want to confirm your order?');
        setAlertShow(true);
      }
    } else if (resultB?.length == 0 && !eventData?.isSolo) {
      setConfirmOrderMessage(`You haven't added any participant.Are you sure you want to confirm your order ? `);
      setAlertShow(true);
    } else {
      setConfirmOrderMessage('Are you sure you want to confirm your order?');
      setAlertShow(true);
    }

  }

  return (
    <>
      <div className="homebar TotalContribute">
        <div className="crossbar">
          <Link to={`/organiser/${id}`}>
            <BiArrowBack />
          </Link>
        </div>
        <div className="contri-text">
          <h5>Total Contribution</h5>
          <h3>{getTotalContri(eventData?.totalContri)}</h3>
        </div>
        <div className="Home-component ">
          <h5 className="titlebar">You're Gifting</h5>
          <div className="allcardslist allgiftscard_bar">
            <div className='AllGiftCard'>
              <img src={withBackendUrl(eventData?.giftCard?.icon)} alt='giftcard' />
              <div className='giftcardbasic'>
                <span className='giftcardamount'>
                  Amount: <b>{getTotalContri(eventData?.totalContri)}</b>
                </span>
              </div>
            </div>
            <div className="recipient-terms-condition">
              <Accordion className="modalaccordion" defaultActiveKey="0">
                <Accordion.Item className='mt-3' eventKey="description">
                  <Accordion.Header>Description</Accordion.Header>
                  <Accordion.Body>
                    <p
                      className="post__content"
                      dangerouslySetInnerHTML={{ __html: eventData?.giftCard?.description }}
                    ></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className='mt-3' eventKey="condition">
                  <Accordion.Header>Terms & Conditions</Accordion.Header>
                  <Accordion.Body>
                    <p
                      className="post__content"
                      dangerouslySetInnerHTML={{
                        __html: eventData?.giftCard?.termAndCondition,
                      }}
                    ></p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className='mt-3' eventKey="redeem">
                  <Accordion.Header>How to Redeem</Accordion.Header>
                  <Accordion.Body>
                    <p
                      className="post__content"
                      dangerouslySetInnerHTML={{ __html: eventData?.giftCard?.howToRedeem }}
                    ></p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <h4 className="titlebar">Sending To</h4>
          <div className="EventPartially Participantbar ">
            <div className="avatar-img">
              <img src={ShowImage(eventData?.photo)} alt="avatar-img" />
            </div>
            <h4>{eventData?.name}</h4>
            {/* <h4><span>{eventData?.name}</span>’s <br></br>{eventData?.occasionId} Celebration</h4> */}
            <div className="user-name-email-bar">
              <p> {'+' + hideNumber(eventData?.phone)} </p><span>|</span> <p>{hideEmail(eventData?.email)}</p>

            </div>
            {/* <h6 className="date">{moment(eventData?.eventStart).format("LL")}</h6> */}
            <div className="timeevent">
              <p>Time to event</p>
              {
                eventData && eventData.eventStart && eventData.eventTime ? <>
                  {console.log(date)}
                  <Countdown date={moment(`${date}`).format()}></Countdown>
                </> : 'To be Decided'
              }
            </div>
            <UserPerviewList usersData={usersData} navigateTo={`/contributionlist/${eventData?._id}`} />

            <div className="peoplesbar">
              <h6>made contribution</h6>
            </div>
            <button className='btn-pink' onClick={ConfirmEventhandleShow}>Confirm Your Order</button>
          </div>
        </div>
      </div>

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={alertShow} onHide={handleClosedateadded}>
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          {confirmOrderMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-pink' onClick={confirmOrder}>
            Confirm
          </Button>
          <Button className='btngray' onClick={handleClosedateadded} type="button">Cancel</Button >
        </Modal.Footer>
      </Modal>
      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={denyModal} onHide={e => setDenyModal(false)}>
        <Modal.Header className="faviconadded" closeButton>
          <img src="./../../images/favicon.png" />
          weGroup
        </Modal.Header>
        <Modal.Body>
          You have not contributed. Cannot proceed to order confirmation without contribution.
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-pink' onClick={e => setDenyModal(false)}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TotalContribute;
