import React from 'react';
import { HiSparkles } from "react-icons/hi2";


function GroupGiftBox() {
  return (
    <>
      <div className='GroupGiftBox'>
        <h4>Why Choose weGroup?</h4>
        <div className='feature-listing-mobile-block'>
          <ul  className="feature-listing px-0 mb-4">
            <li>
              <HiSparkles fill='gold' className='me-1' /><b> Simplicity Redefined</b>: Our user-friendly interface makes the entire process a breeze – from setup to celebrating the moment!
            </li>
            <li>
              <HiSparkles fill='gold' className='me-1' /><b>Celebrating Togetherness</b>: Strengthen your bonds by coming together to create cherished memories through thoughtful, collective gifting.
            </li>
            <li>
              <HiSparkles fill='gold' className='me-1' /><b>Safe and Secure</b>: Trust is our priority. Rest easy knowing your contributions and personal information are handled with the utmost security.
            </li>
          </ul>
          <p>Ready to transform the way you celebrate? Join weGroup now and elevate your group gifting game! 🚀 #GiftTogether #CelebrateAsOne  #UniteToDelight</p>
        </div>
        <div className='landing-align-flex'>
          <div className='greengift-cards'>
            <div className='porse-icon'>
              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.09436 10.2288C5.03221 9.82823 4.99996 9.41786 4.99996 9C4.99996 4.58172 8.60525 1 13.0526 1C17.4999 1 21.1052 4.58172 21.1052 9C21.1052 9.99807 20.9213 10.9535 20.5852 11.8345C20.5154 12.0175 20.4804 12.109 20.4646 12.1804C20.4489 12.2512 20.4428 12.301 20.4411 12.3735C20.4394 12.4466 20.4493 12.5272 20.4692 12.6883L20.8717 15.9585C20.9153 16.3125 20.9371 16.4895 20.8782 16.6182C20.8266 16.731 20.735 16.8205 20.6211 16.8695C20.4911 16.9254 20.3146 16.8995 19.9617 16.8478L16.7765 16.3809C16.6101 16.3565 16.527 16.3443 16.4512 16.3448C16.3763 16.3452 16.3245 16.3507 16.2511 16.3661C16.177 16.3817 16.0823 16.4172 15.893 16.4881C15.0097 16.819 14.0524 17 13.0526 17C12.6344 17 12.2237 16.9683 11.8227 16.9073M6.63158 21C9.59648 21 12 18.5376 12 15.5C12 12.4624 9.59648 10 6.63158 10C3.66668 10 1.26316 12.4624 1.26316 15.5C1.26316 16.1106 1.36028 16.6979 1.53955 17.2467C1.61533 17.4787 1.65322 17.5947 1.66566 17.6739C1.67864 17.7567 1.68091 17.8031 1.67608 17.8867C1.67145 17.9668 1.65141 18.0573 1.61134 18.2383L1 21L3.9948 20.591C4.15827 20.5687 4.24 20.5575 4.31137 20.558C4.38652 20.5585 4.42641 20.5626 4.50011 20.5773C4.5701 20.5912 4.67416 20.6279 4.88227 20.7014C5.43059 20.8949 6.01911 21 6.63158 21Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h5>Team up Group Gift</h5>
            <p>Discover the unique magic of our platform- where whether you're a dynamic duo or a group of 200, staying connected and in sync is effortless.</p>
          </div>
          <div className='greengift-cards'>
            <div className='porse-icon'>
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0008 1L2.09429 11.6879C1.74549 12.1064 1.57108 12.3157 1.56842 12.4925C1.5661 12.6461 1.63457 12.7923 1.7541 12.8889C1.89159 13 2.16402 13 2.70887 13H10.0008L9.00084 21L17.9074 10.3121C18.2562 9.89358 18.4306 9.68429 18.4333 9.50754C18.4356 9.35388 18.3671 9.2077 18.2476 9.11111C18.1101 9 17.8377 9 17.2928 9H10.0008L11.0008 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h5>Deliver high quality wishes</h5>
            <p>Empower your group to share top-notch photos, videos, and heartfelt audio wishes with our all-in-one platform, making your moments even more special for the chosen recipient.</p>
          </div>
          <div className='greengift-cards'>
            <div className='porse-icon'>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 10.0857V11.0057C20.9988 13.1621 20.3005 15.2604 19.0093 16.9875C17.7182 18.7147 15.9033 19.9782 13.8354 20.5896C11.7674 21.201 9.55726 21.1276 7.53447 20.3803C5.51168 19.633 3.78465 18.2518 2.61096 16.4428C1.43727 14.6338 0.879791 12.4938 1.02168 10.342C1.16356 8.19029 1.99721 6.14205 3.39828 4.5028C4.79935 2.86354 6.69279 1.72111 8.79619 1.24587C10.8996 0.770634 13.1003 0.988061 15.07 1.86572M21 3L11 13.01L8 10.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h5>Vote on the gift options</h5>
            <p>Coming soon...</p>
          </div>
          <div className='greengift-cards'>
            <div className='porse-icon'>
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99865 12C7.99865 12 9.31115 13.5 11.4986 13.5C13.6861 13.5 14.9986 12 14.9986 12M14.2486 7H14.2586M8.74865 7H8.75865M11.4986 18C16.1931 18 19.9986 14.1944 19.9986 9.5C19.9986 4.80558 16.1931 1 11.4986 1C6.80423 1 2.99865 4.80558 2.99865 9.5C2.99865 10.45 3.1545 11.3636 3.44202 12.2166C3.55022 12.5376 3.60432 12.6981 3.61408 12.8214C3.62371 12.9432 3.61643 13.0286 3.5863 13.1469C3.5558 13.2668 3.48844 13.3915 3.35374 13.6408L1.71808 16.6684C1.48477 17.1002 1.36812 17.3161 1.39423 17.4828C1.41697 17.6279 1.5024 17.7557 1.62782 17.8322C1.77183 17.9201 2.01595 17.8948 2.50419 17.8444L7.62521 17.315C7.78029 17.299 7.85783 17.291 7.92851 17.2937C7.99802 17.2963 8.04709 17.3029 8.11488 17.3185C8.1838 17.3344 8.27047 17.3678 8.4438 17.4345C9.39185 17.7998 10.4219 18 11.4986 18ZM14.7486 7C14.7486 7.27614 14.5248 7.5 14.2486 7.5C13.9725 7.5 13.7486 7.27614 13.7486 7C13.7486 6.72386 13.9725 6.5 14.2486 6.5C14.5248 6.5 14.7486 6.72386 14.7486 7ZM9.24865 7C9.24865 7.27614 9.02479 7.5 8.74865 7.5C8.47251 7.5 8.24865 7.27614 8.24865 7C8.24865 6.72386 8.47251 6.5 8.74865 6.5C9.02479 6.5 9.24865 6.72386 9.24865 7Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h5>Physical Products</h5>
            <p>At present, we're offering a delightful range of e-gift cards. Stay tuned as we introduce a selection of physical gifts soon. Watch out for this space for exciting updates!</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupGiftBox;
