import React, { useState, useEffect, useContext } from "react";
import moment from 'moment';
import { AuthContext } from './../../../Context/AuthContext';
import EditDelete from './../editDelete';
import { ShowImage, haveValue, withBackendUrl } from '../../../utils/helpers';
import ReactPlayer from 'react-player'


function VideoTestimonial(props) {
  const { loggedInUserId } = useContext(AuthContext);
  const { testimonial, viewOnly } = props;

  const handleEdit = () => {
    localStorage.setItem('editTest', true);
    localStorage.setItem('captionTesti', testimonial?.caption);
    localStorage.setItem('testimonialId', testimonial?._id);
    localStorage.setItem('video', true);
    props.onEdit();
  }
  return (
    <>
      <div className='Testi-content video'>
        <div className='mediauploaded'>
          {
            haveValue(testimonial?.media) ?
              <>
                {/* <video controls="true">
                  <source src={withBackendUrl(`${testimonial?.media}#t=0.001`)} type="video/mp4" />
                </video> */}
                <ReactPlayer url={withBackendUrl(`${testimonial?.media}`)} controls={true} width='100%'
          height='100%' />
                {/* <iframe 
                src={withBackendUrl(`${testimonial?.media}?autostart=false`)}
                allow={false}  
                muted={true}              
                allowFullScreen
              ></iframe> */}
                {/* <iframe src={withBackendUrl(`${testimonial?.media}#t=0.001`)} autoplay="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
              </>
              :
              ''
          }
          {/* <iframe src={testimonial?.media ? withBackendUrl(testimonial?.media) : ''} title="Testimonial" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
        <div className='testimonial-content-head'>
          <div className='headleft'>
            <img src={ShowImage(testimonial?.userId?.profilePhoto, testimonial?.userId?.fullName)} alt='rs' />
            <h6>{testimonial?.userId?.fullName}</h6>
          </div>
          <div className='headryt'>
            <p className='date'>{moment(testimonial?.createdAt).format("MMM DD YYYY")}</p> |
            <p className='time'>{moment(testimonial?.createdAt).format("hh:mm A")}</p>
          </div>
        </div>
        <div className='testimonial-content-text'>
          <p>{testimonial?.caption ? testimonial?.caption : ''}</p>
        </div>
        {
          viewOnly === "true" || viewOnly === true ?
            ''
            :
            <>
              {
                (loggedInUserId === testimonial?.userId?._id) || (testimonial.eventId.createdBy === loggedInUserId) ?
                  <EditDelete onEdit={handleEdit} onRefresh={props.onRefresh} testimonial={testimonial} type="video" />

                  :
                  ''
              }
            </>
        }


      </div>
    </>
  );
}

export default VideoTestimonial;
