import Agent from "../Services/RequestInstance";
import { ServerError } from "../utils/helpers";
import * as http from '../Services/http';


import config from "../Config/Config";

const BACKEND_URL = config.BACKEND_URL;


function createTestimonial(payload, eventId) {
    return http.post(`/testimonial/${eventId}`, payload);
}
function updateTestimonial(payload, testimonialId) {
    return http.post(`/testimonial/update/${testimonialId}`, payload);
}
function testimonialList(eventId, type, value) {
    return http.get('/testimonial/list?eventId=' + eventId + '&type=' + type + '&search=' + value);
}
function testimonialListRec(eventId, type, value) {
    return http.get('/testimonial/list-rec?eventId=' + eventId + '&type=' + type + '&search=' + value);
}
function deleteTestimonial(eventId) {
    return http.get('/testimonial/delete/' + eventId);
}

export default {
    createTestimonial,
    testimonialList,
    testimonialListRec,
    deleteTestimonial,
    updateTestimonial
};