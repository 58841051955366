import React from 'react'
import { useState } from 'react'
import { AiOutlineArrowUp } from 'react-icons/ai'

const BottomBtn = () => {
    const [bottombtn, setbottombtn] = useState(false)
    const btnshow = ()=>{
        window.scrollY>=50?
          setbottombtn(true)
       :
          setbottombtn(false)
      };
      window.addEventListener("scroll", btnshow);
        
  return (
    <div className={bottombtn? 'bottom-to-top btnshow' : 'bottom-to-top'}>
        <button onClick={()=>window.scrollTo({ top: 0, behavior: 'smooth' })}><AiOutlineArrowUp size={20} color='white'/></button>
    </div>
  )
}

export default BottomBtn;
