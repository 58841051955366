import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Calendar from 'react-calendar';
import TimeKeeper from 'react-timekeeper';
import { Link } from 'react-router-dom';
import { haveValue, isNotEmpty, isValidDate } from '../../utils/helpers';
import FieldError from '../Common/FieldError';
import Moment from 'moment';
import { isValidDateValue } from '@testing-library/user-event/dist/utils';
import { AuthContext } from '../../Context/AuthContext';


function DateTimeBoxOuter(props) {
  const {showSave} = props;
  const { isLoggedInUserId } = useContext(AuthContext);
  const [value, onChange] = useState(new Date());
  // Open Calender Popup
  const [showcalenderbar, setCalenderShow] = useState(false);
  const CalenderClose = () => setCalenderShow(false);
  const CalenderShow = () => setCalenderShow(true);
  // Open Time Popup
  const [showTimebar, setTimeShow] = useState(false);
  const TimeClose = () => setTimeShow(false);
  const TimeShow = () => setTimeShow(true);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  // const [timeConverted, setTimeConverted] = useState('');
  const [isValid, setIsValid] = useState(true)
  // const [showSave, setShowSave] = useState(false);
  const [selectedTime, setSelectedTime] = useState(''); // Track selected time here


  function subOneDay(date = new Date()) {
    date.setDate(date.getDate() - 1);
    return date;
  }

  const dateCurr = new Date();

  const result = subOneDay(dateCurr);

  function tileDisabled({ activeStartDate, date, view }) {
    return new Date(date) < new Date(result);
  }


  const printDate = () => {
    if (isValidDate(props?.date)) {
      return Moment(props?.date).format("LL");
    } else {
      return "Select Date";
    }
  }

  useEffect(()=>{
    if(haveValue(props?.time)){
      setSelectedTime(props?.time)
    }else{
      setSelectedTime("")
    }
  },[props])

  const handleTimeSelection = (newTime) => {
    setSelectedTime(newTime.formatted);
  };
  const handleTimeModalClose = () => {
    setTimeShow(false);
  };

  const handleApplyTime = () => {
    props.handleChangeTime(selectedTime);
    handleTimeModalClose();
  };

  useEffect(() => {
    let dt = new Date();
    var selectedDate = new Date(props?.date);
    if (Moment(selectedDate).format("LL") === Moment(dt).format("LL")) {
      setFrom('0:00');
      setTo(dt.getHours() + ":" + dt.getMinutes());
    } else {
      setFrom('');
      setTo('');
    }
  }, [props?.date]);

  const getSelectedDate = () => {
    if (isValidDate(props?.date)) {
      return new Date(props?.date);
    } else {
      return null;
    }
  }
  const editDateProp = () => {
    // setShowSave(!showSave)
    return props.handleShowSaveButton(!showSave);
  }

  console.log(selectedTime,"selectedTimeselectedTime",props)

  return (
    <>
      <div className='datetimebox'>
        <Form.Group className="formgroup" controlId="formBasicEmail">
          <h4>
            <div className='leftbar'>
              <Form.Label>When is the occasion?</Form.Label>
            </div>
            {
              props?.eventData && !props?.eventData?.isConfirmed && isLoggedInUserId(props?.eventData?.createdBy?._id) ?
                <>
                  <div className='settingicon'>
                    <Button onClick={editDateProp} className='settingbtn'>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 9.00006L13 5.00006M1.5 20.5001L4.88437 20.124C5.29786 20.0781 5.5046 20.0551 5.69785 19.9925C5.86929 19.937 6.03245 19.8586 6.18289 19.7594C6.35245 19.6476 6.49955 19.5005 6.79373 19.2063L20 6.00006C21.1046 4.89549 21.1046 3.10463 20 2.00006C18.8955 0.895489 17.1046 0.895488 16 2.00006L2.79373 15.2063C2.49955 15.5005 2.35246 15.6476 2.24064 15.8172C2.14143 15.9676 2.06301 16.1308 2.00751 16.3022C1.94496 16.4955 1.92198 16.7022 1.87604 17.1157L1.5 20.5001Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Button>
                  </div>
                </>
                :
                ''
            }
          </h4>
          <div className="datetimepicker">

            {
              haveValue(showSave) && showSave ?
                <>
                  <Button onClick={CalenderShow} className="btngray" >
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.25 9.58342V7.33342C16.25 5.93328 16.25 5.23322 15.9775 4.69844C15.7378 4.22803 15.3554 3.84558 14.885 3.6059C14.3502 3.33341 13.6501 3.33341 12.25 3.33341H5.25C3.84987 3.33341 3.1498 3.33341 2.61502 3.6059C2.14462 3.84558 1.76217 4.22803 1.52248 4.69844C1.25 5.23322 1.25 5.93328 1.25 7.33341V14.3334C1.25 15.7335 1.25 16.4336 1.52248 16.9684C1.76217 17.4388 2.14462 17.8212 2.61502 18.0609C3.1498 18.3334 3.84987 18.3334 5.25 18.3334H9.16667M16.25 8.33342H1.25M12.0833 1.66675V5.00008M5.41667 1.66675V5.00008M13.75 17.5001V12.5001M11.25 15.0001H16.25" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {printDate()}</Button>
                  <FieldError error={props?.errors?.date} />
                  <Button onClick={TimeShow} className="btngray">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5166 11.0542C17.5602 10.709 17.5827 10.3572 17.5827 10.0001C17.5827 5.39771 13.8517 1.66675 9.24935 1.66675C4.64698 1.66675 0.916016 5.39771 0.916016 10.0001C0.916016 14.6025 4.64698 18.3334 9.24935 18.3334C9.61219 18.3334 9.96961 18.3102 10.3202 18.2652M9.24935 5.00008V10.0001L12.3647 11.5577M15.0827 18.3334V13.3334M12.5827 15.8334H17.5827" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {selectedTime || "Select Time"}</Button>
                  <FieldError error={props?.errors?.time} />
                </>
                :
                <>
                  <Button disabled onClick={CalenderShow} className="btngray" >
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.25 9.58342V7.33342C16.25 5.93328 16.25 5.23322 15.9775 4.69844C15.7378 4.22803 15.3554 3.84558 14.885 3.6059C14.3502 3.33341 13.6501 3.33341 12.25 3.33341H5.25C3.84987 3.33341 3.1498 3.33341 2.61502 3.6059C2.14462 3.84558 1.76217 4.22803 1.52248 4.69844C1.25 5.23322 1.25 5.93328 1.25 7.33341V14.3334C1.25 15.7335 1.25 16.4336 1.52248 16.9684C1.76217 17.4388 2.14462 17.8212 2.61502 18.0609C3.1498 18.3334 3.84987 18.3334 5.25 18.3334H9.16667M16.25 8.33342H1.25M12.0833 1.66675V5.00008M5.41667 1.66675V5.00008M13.75 17.5001V12.5001M11.25 15.0001H16.25" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {printDate()}</Button>
                  <FieldError error={props?.errors?.date} />
                  <Button disabled onClick={TimeShow} className="btngray">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5166 11.0542C17.5602 10.709 17.5827 10.3572 17.5827 10.0001C17.5827 5.39771 13.8517 1.66675 9.24935 1.66675C4.64698 1.66675 0.916016 5.39771 0.916016 10.0001C0.916016 14.6025 4.64698 18.3334 9.24935 18.3334C9.61219 18.3334 9.96961 18.3102 10.3202 18.2652M9.24935 5.00008V10.0001L12.3647 11.5577M15.0827 18.3334V13.3334M12.5827 15.8334H17.5827" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {selectedTime || "Select Time"}</Button>
                  <FieldError error={props?.errors?.time} />
                </>

            }
          </div>
        </Form.Group>
      </div>

      {/* Calender Modals */}
      <Modal className="datepicker-modal" show={showcalenderbar} onHide={CalenderClose} centered>
        <Modal.Body><Calendar tileDisabled={tileDisabled} onChange={(e) => props.handleChangeDate(e)} value={getSelectedDate()} /></Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={CalenderClose}>
            Cancel
          </Button>
          <Button className="btn-pink" onClick={CalenderClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Time */}
      <Modal className="datepicker-modal" show={showTimebar} onHide={TimeClose} centered>
        <Modal.Body>
          <div className="timebar">
            <TimeKeeper
              onChange={handleTimeSelection}
              switchToMinuteOnHourSelect
              time={selectedTime || '12:00 pm'} // Set the initial time
              // disabledTimeRange={{ from: from, to: to }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={TimeClose}>
            Cancel
          </Button>
          <Link className="btn-pink" onClick={handleApplyTime}>
            Apply
          </Link>
        </Modal.Footer>
      </Modal >

    </>
  );
}

export default DateTimeBoxOuter;
