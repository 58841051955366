import React, { useState, useEffect } from "react";
import usePersistedState from "./usePersistedState";
import { osName, isMobile } from 'react-device-detect';


export const ThemeContext = React.createContext();

export function ThemeProvider(props) {


  const [darkMode, setDarkMode] = usePersistedState("darkmode", false);
  const [backUrlContext, setBackUrlContext] = useState('/home');
  const [hide, setHide] = useState(false);

  const [successToast, setSuccessToast] = useState({
    visible: false,
    title: 'Your Request has been Completed'
  });

  const [errorToast, setErrorToast] = useState({
    visible: false,
    title: 'Your Request has been Failed'
  });


  const showSuccessToast = (message) => {
    // showToast(message, 'success'); 
    setSuccessToast({
      ...successToast,
      visible: true,
      title: message
    });
  }

  const showErrorToast = (message) => {
    // showToast(message, 'error');
    setErrorToast({
      ...errorToast,
      visible: true,
      title: message
    });
  }

  const hideErrorToast = (message) => {
    // showToast(message, 'error');
    setErrorToast({
      ...errorToast,
      visible: false,
      title: message
    });
  }

  useEffect(() => {
    localStorage.setItem('backUrl', backUrlContext);
  }, [backUrlContext])

  return (
    <ThemeContext.Provider value={{ hideErrorToast,backUrlContext, setBackUrlContext, darkMode, setDarkMode, successToast, setSuccessToast, errorToast, setErrorToast, showSuccessToast, showErrorToast, osName, isMobile, hide, setHide }}>
      {props.children}
    </ThemeContext.Provider>
  );
}


