import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileMain from "../../Pages/Profile/ProfileMain";
import { AuthContext } from "../../Context/AuthContext";
import { ShowImage, showLoader, hideLoader } from "../../utils/helpers";
import AuthAction from "../../Actions/auth.service";

function HomeHeader() {
  const { isLoggedIn, isSidebarVisible, setIsSidebarVisible, setIsLoggedIn } =
    useContext(AuthContext);
  console.log("🚀 ~ HomeHeader ~ isSidebarVisible:", isSidebarVisible);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    showLoader();
    setIsSidebarVisible(false);
    if (isLoggedIn) {
      AuthAction.getUserData()
        .then((res) => {
          setMyUserInfo(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
        });
    }
  }, [isLoggedIn]);
  return (
    <>
      <ul>
        <li className="logobar">
          <Link to="/" className="brand">
            <img src="/images/wegroup-white.svg" alt="whitelogo" />
            <h6 className="mb-0">BETA</h6>
          </Link>
          <p>Group Gifting made easy</p>
        </li>
        <li className="userbar">
          {isLoggedIn ? (
            <button
              onClick={() => {
                setIsSidebarVisible(true);
              }}
            >
              <img
                className="profimnew"
                src={ShowImage(myUserInfo?.profilePhoto, myUserInfo?.fullName)}
                alt="img"
              />
            </button>
          ) : (
            ""
          )}

          <div className={`sidebar ${isSidebarVisible ? "active" : "hidden"}`}>
            <div className="sidebarin">
              <button
                className="closesidebar"
                onClick={() => {
                  setIsSidebarVisible(false);
                }}
              >
                <img src="/images/cross.png" alt="crossicon" />
              </button>

              <ProfileMain />
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

export default HomeHeader;
