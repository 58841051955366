import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AdminAction from "../Actions/auth.service"
import { haveValue } from "../utils/helpers";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [eventData, setEventData] = useState({});
    const [emailVerified, setEmailVerified] = useState(false);
    let [loggedInUserData, setLoggedInUserData] = useState({});
    const [firebaseIdToken, setFirebaseIdToken] = useState({});
    let [loggedInUserId, setLoggedInUserId] = useState(null);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            setIsLoggedIn(false);
        } else {
            // setIsLoggedIn(true);
            decodedToken();
        }
    }, []);

    useEffect(() => {
        if (haveValue(isLoggedIn) && isLoggedIn) {
            decodedToken();
        } else {
            setLoggedInUserId(null);
            setLoggedInUserData({});
            setIsLoggedIn(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (emailVerified) {
            AdminAction.verifyEmail().then(res => {
                console.log(res, "=== email verify")
            }).catch(err => {
                if (err?.response?.data?.message == "Unauthorized User.") {
                    setIsLoggedIn(false);
                    localStorage.removeItem('token');
                }
            });
        }
    }, [emailVerified])


    const decodedToken = async () => {
        console.log("decodedToken called")
        const token = localStorage.getItem('token')
        const decodedToken = jwt_decode(token);
        let userId = decodedToken.data;
        loggedInUserId = userId;
        setLoggedInUserId(loggedInUserId);
        AdminAction.getUserData(loggedInUserId).then(res => {
            setLoggedInUserData(res);
            setIsLoggedIn(true);
        }).catch(err => {
            console.log(err, "context error")
            if (err?.response?.data?.message == "Unauthorized User.") {
                setIsLoggedIn(false);
                console.log("===setisloggedin")
                localStorage.removeItem('token');
                window.location.reload = '/getStarted';
            }
        });
    }

    const isLoggedInUserId = async (id) => {
        if (loggedInUserId == null) {
            await decodedToken();
        }
        if (loggedInUserId == id) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, isSidebarVisible, setIsSidebarVisible, eventData, setEventData, loggedInUserId, isLoggedInUserId, loggedInUserData, setLoggedInUserData, firebaseIdToken, setFirebaseIdToken, emailVerified, setEmailVerified }}>
            {props.children}
        </AuthContext.Provider>
    );
} 