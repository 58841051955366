import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { validateJOIFormField, validateJOIProperty, hideLoader, showLoader, haveValue, getTotalContri } from "../../utils/helpers";
import AuthAction from "../../Actions/auth.service";
import EventAction from "../../Actions/event.service";
import contributionAction from '../../Actions/contribution.service';
import 'react-toastify/dist/ReactToastify.css';
import ContributionRow from '../../Components/Contribution/ContributionRow';
import { AuthContext } from "../../Context/AuthContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import FieldError from "./../../../src/Components/Common/FieldError";
import { ThemeContext } from '../../hooks/useThemeContext';
import { HiArrowSmLeft } from 'react-icons/hi';

function ContributionList(props) {
  const navigate = useNavigate();
  const { loggedInUserId, setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);
  const { setBackUrlContext, hide } = useContext(ThemeContext);

  const { id } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [contributionList, setContributionList] = useState();
  const [yetToContributionList, setYetToContributionList] = useState();
  const [amount, setAmount] = useState();
  const [alreadyContributed, setAlreadyContributed] = useState(false);
  const [showContributeNow, setShowContributeNow] = useState(false);
  const [showContributionSetting, setShowContributionSetting] = useState(false);
  const [contriNumber, setContriNumber] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [participantLength, setParticipantLength] = useState(0)


  useEffect(() => {
    contributionsList();
    yetTocontributeList();
    setBackUrlContext(`/${localStorage.getItem('role') ?? 'organiser'}/${id}`);
  }, []);

  useEffect(() => {
    EventAction.viewEvent(id).then(res => {
      console.log(res, "RES IS HERE")
      setData(res);
      setTotalParticipants(res?.participants.filter(item => item.status == "joined").length)
      setParticipantLength(res?.participants.length)
    }).catch(err => {
      console.log(err)
    });
  }, []);

  const handleClosedateadded = () => {
    setShowContributionSetting(false);
    setShowContributeNow(false);
  }

  const contributionPage = () => {
    if (haveValue(data?.totalContri) && data?.totalContri > 0) {
      setShowContributionSetting(true)
    } else {
      navigate(`/ContributionPage/${id}`);
    }
  }

  const contributeNow = () => {
    setShowContributeNow(true)
  }

  const continuePay = () => {
    showLoader();
    const formdata = new FormData();
    if (data?.contriType === "fixed") {
      formdata.append("amount", data?.contriPerPerson);
      setAmount(data?.contriPerPerson);
    }
    if (data?.contriType === "any") {

      if (amount >= data?.minimumContriAmount) {
        formdata.append("amount", amount);
        setError();
      } else {
        setError(`Please pay a minimum of ${data?.minimumContriAmount}`);
        hideLoader();
        return false;
      }
    }
    formdata.append("eventId", id);
    contributionAction.initiateTransaction(id, formdata).then(res => {
      hideLoader();
      console.log(res)
      window.location.href = `https://pay.easebuzz.in/v2/pay/${res?.data}`;
      // navigate(`/paymentsuccess/${id}`);
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }


  const contributionsList = () => {
    showLoader();
    contributionAction.contributionList(id).then(res => {
      res.map((itemc) =>
        itemc?.eventParticipantId?.userId?._id === loggedInUserId ?
          setAlreadyContributed(true)
          :
          console.log(itemc?.eventParticipantId?.userId?._id, "=====itemc?.eventParticipantId?.userId?._id", loggedInUserId)
      )
      setContriNumber(res.length);
      setContributionList(res);
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const yetTocontributeList = () => {
    showLoader();
    contributionAction.yetTocontributeList(id).then(res => {
      hideLoader();
      setYetToContributionList(res);
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }

  const handleUpdate = () => {
    contributionsList();
  }


  return (
    <>
      {
        hide ?
          <>
            <div className='Headerbar inside-header'>
              <ul>
                <li>
                  <Link to={`/totalcontribution/${id}`} className='backtobasic'>
                    {
                      <HiArrowSmLeft />
                    }
                  </Link>
                </li>
                <li>
                  <h6>BETA</h6>
                </li>
              </ul>
            </div>
          </>
          :
          <></>
      }
      <h4 className="titleevent">Individual Contributions</h4>

      <div className='Participant-Listall'>
        <div className='individualcontirbution-bar'>
          {/* total amount received */}
          <div className='amount-received'>
            <div className='amountbox'>{getTotalContri(data?.totalContri)} <span>received</span></div>
            <h5><span>{contriNumber} people</span> contributed {data?.createdBy?._id == loggedInUserId ? `out of ${totalParticipants}` : ""}</h5>
          </div>
          <div className='toprow'>
            {
              contributionList && contributionList.length > 0 ?
                (
                  <>
                    <h4 className="titleevent">Contributed</h4>
                    <ul className='participant-list-ul spacebottom'>
                      {
                        data?.createdBy?._id == loggedInUserId ?
                          contributionList.map((item) => {
                            return (<>
                              <ContributionRow onUpdate={handleUpdate} contribution={item} key={item._id} hideAmount={false} />
                            </>)
                          })
                          :
                          contributionList.map((item) => {
                            return (<>
                              <ContributionRow onUpdate={handleUpdate} contribution={item} key={item._id} hideAmount={true} />
                            </>)
                          })
                      }
                    </ul>
                  </>
                )
                :
                ''
            }

            {
              yetToContributionList && yetToContributionList.length > 0 ?
                (
                  <div className='yettocontribute'>
                    <h4 className="titleevent">Yet to Contribute</h4>
                    <ul className='participant-list-ul '>
                      {
                        data?.createdBy?._id != loggedInUserId ?
                          yetToContributionList.map((item) => (
                            (
                              item?.userId?._id == loggedInUserId &&
                              <>
                                <ContributionRow onUpdate={handleUpdate} contribution={item} key={item._id} yet={true} />
                              </>
                            )
                          )) :
                          yetToContributionList.map((item) => {
                            return (<>
                              <ContributionRow onUpdate={handleUpdate} contribution={item} key={item._id} yet={true} />
                            </>)
                          })
                      }
                    </ul>
                  </div>
                )
                :
                ''
            }

          </div>
        </div>

        <div className='buttonfactor'>
          {alreadyContributed || data?.isConfirmed ?
            '' :
            <>
              {
                (parseInt(data?.totalContri) + data?.contriPerPerson) < parseInt(data?.giftCard?.maximumAmount) ?
                  <Button onClick={contributeNow} type="button" className='btn-pink'>
                    Contribute {
                      data?.contriType === "fixed" ?
                        getTotalContri(data?.contriPerPerson)
                        : ''
                    }  now </Button>
                  :
                  ''
              } </>
          }
          {
            console.log(totalParticipants, "totalParticipantstotalParticipantstotalParticipantstotalParticipants")
          }
          {
            haveValue(participantLength) && participantLength < 2 &&
            <Button onClick={contributionPage} className='btngray'>
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0421 6.5H3.08333C1.93274 6.5 1 5.56726 1 4.41667C1 3.26607 1.93274 2.33333 3.08333 2.33333H11.0421M5.95791 15.6667H13.9167C15.0673 15.6667 16 14.7339 16 13.5833C16 12.4327 15.0673 11.5 13.9167 11.5H5.95791M1 13.5833C1 15.1942 2.30584 16.5 3.91667 16.5C5.5275 16.5 6.83333 15.1942 6.83333 13.5833C6.83333 11.9725 5.5275 10.6667 3.91667 10.6667C2.30584 10.6667 1 11.9725 1 13.5833ZM16 4.41667C16 6.0275 14.6942 7.33333 13.0833 7.33333C11.4725 7.33333 10.1667 6.0275 10.1667 4.41667C10.1667 2.80584 11.4725 1.5 13.0833 1.5C14.6942 1.5 16 2.80584 16 4.41667Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Contributions Settings
            </Button>
          }
        </div>
      </div>


      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showContributionSetting} onHide={handleClosedateadded}>
        <Modal.Header closeButton>
          Money Contribution Settings
        </Modal.Header>
        <Modal.Body>
          {
            data?.contriType === "fixed" ?
              <><div className='FixedAmount'>
                <img src="/images/budget.png" alt='icon' />
                <h4>Fixed Amount</h4>
                <p>All Participants pay the same amount.</p>
                <Form.Group className="fixform" controlId="formBasicEmail">
                  <Form.Label>Enter per person contribution</Form.Label>
                  <div className='amount-type'>
                    <Form.Control type="number" placeholder={getTotalContri(data?.contriPerPerson)} />
                    <span className='perperson'> / person</span>
                  </div>
                </Form.Group>
                <div className='note-error'>
                  <AiOutlineInfoCircle />
                  <p>Cannot update this as the contributions have already started.</p>
                </div>
              </div></>
              :
              ''
          }
          {
            data?.contriType === "any" ?
              <><div className='FixedAmount'>
                <img src="/images/budget.png" alt='icon' />
                <h4>Any Amount</h4>
                {
                  haveValue(data?.minimumContriAmount) ?
                    <>
                      <Form.Group className="fixform" controlId="formBasicEmail">
                        <Form.Label>Minimum Contribution</Form.Label>
                        <div className='amount-type'>
                          <Form.Control type="number" disabled placeholder={getTotalContri(data?.minimumContriAmount)} />
                          <span className='perperson'> / person</span>
                        </div>
                      </Form.Group>
                    </>
                    :
                    ''
                }
                <div className='note-error'>
                  <AiOutlineInfoCircle />
                  <p>Cannot update this as the contributions have already started.</p>
                </div>
              </div></>
              :
              ''
          }

        </Modal.Body>
      </Modal>


      <Modal className="Createevent-modal fixamountpopup opacity-full dateadded-popup" show={showContributeNow} onHide={handleClosedateadded}>
        <Modal.Header closeButton>
          <h6>You will be redirected to</h6>
        </Modal.Header>
        <Modal.Body>
          {
            data?.contriType === "fixed" ?
              <><div className='FixedAmount'>
                <p>Our Secure payment gateway provider</p>
                <h4>Pay {getTotalContri(data?.contriPerPerson)}</h4>
              </div>
              </>
              :
              <>
                <Form.Group className="fixform" controlId="formBasicEmail">
                  <Form.Label>Enter amount</Form.Label>
                  <div className='amount-type'>
                    <BiRupee />
                    <Form.Control className={error ? 'error' : ""} type="number" onChange={(e) => setAmount(e?.target.value)} />
                  </div>
                </Form.Group>
                <FieldError error={error} />
                <div className='FixedAmount'>
                  <h4>Pay a minimum amount of {getTotalContri(data?.minimumContriAmount)}</h4>
                </div>
              </>
          }
          <Button onClick={continuePay} type="button" className='btn-pink'>
            Continue & Pay
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContributionList;
