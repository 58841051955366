import React, { useContext, useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { getTotalContri, haveValue, withBackendUrl } from '../../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { Accordion } from 'react-bootstrap';


function OpenGiftCard(props) {
    const { event, isRecipient } = props;
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const checkLoginandRedirect = (link) => {
        localStorage.setItem("link", link);
        localStorage.setItem("backUrl", `/recipient/${event?._id}`);
        navigate('/login');
    }
    
    return (
        <>
            <div className='OpenGiftCard allcards'>
                <h4>Your Gift</h4>
                <div className='giftcard-in allgiftscard_bar'>
                    {
                        event?.giftCard?.icon ?
                            <div className='AllGiftCard'>
                                <img src={withBackendUrl(event?.giftCard?.icon)} alt='giftcard' />
                                <div className='giftcardbasic'>
                                    <span className='giftcardamount'>
                                        Amount: <b>{getTotalContri(event?.totalContri)}</b>
                                    </span>
                                </div>
                            </div>
                            :
                            ''
                    }
                    {
                        <div className='spacer'>
                            {haveValue(event?.giftCardPassCode) && haveValue(event?.giftCardExpiry) && haveValue(event?.giftCardId) && (
                                haveValue(isLoggedIn) && isLoggedIn ? (
                                    haveValue(isRecipient) && isRecipient ? (
                                        <Link to={`/allgiftcard/${event._id}`} className='btn-pink'>Open Gift</Link>
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <Button onClick={(e) => checkLoginandRedirect(`/allgiftcard/${event._id}`)} className='btn-pink'>Open Gift</Button>
                                )
                            )}
                        </div>
                    }
                </div >
            </div >
        </>
    );
}

export default OpenGiftCard;
