import React, { useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import { AuthContext } from "../../Context/AuthContext";
import Lottie from 'react-lottie';
import * as unsuccessAnimationData from '../../Animations/unsuccesful.json'

const PaymentFailed = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { event, isLoggedInUserId } = useContext(AuthContext);

    setTimeout(() => {
        navigate(`/${localStorage.getItem('role')}/${id}`);
    }, 3000);
    return (
        <>
            <div className="eventbar">
                {/* <h4 className="titleevent">Create Group Gift Event</h4> */}
                <div className="successbar">
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: unsuccessAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                    height={160}
                    width={160}
                    isStopped={false} />
                    <h6>Payment Failed</h6>
                </div>
            </div>
        </>
    );
}

export default PaymentFailed;