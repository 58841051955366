import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ContriAccordion from '../../Components/Contribution/ContriAccordion';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { BiRupee } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Switch from "react-switch";
import eventService from '../../Actions/event.service';
import { showLoader, hideLoader, isNotEmpty, getTotalContri } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { Button, Modal } from 'react-bootstrap';
import useSoloEvent from '../../hooks/useSoloEvent';

// State Variables

function ContributionPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState(0)
  const [activeSwitch, setActiveSwitch] = useState(false)
  const [fixedAmount, setFixedAmount] = useState(null)
  const [event, setEvent] = useState({})
  const [contriType, setContriType] = useState(null)
  const [currentContriType, setCurrentContriType] = useState(null)
  const [FieldError1, setFieldError1] = useState(false)
  const [FieldError3, setFieldError3] = useState(false)
  const [FieldError4, setFieldError4] = useState(false)
  const [FieldError2, setFieldError2] = useState('Please Select Any One')
  const [activeSwitchAny, setActiveSwitchAny] = useState(false)
  const [minimumContriAmount, setMinimumContriAmount] = useState(null);
  const [firstTime, setFirstTime] = useState(true)
  const [maxParticipants, setMaxParticipants] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const isSolo = useSoloEvent()

  const { showErrorToast, showSuccessToast, setBackUrlContext } = useContext(ThemeContext);

  useEffect(() => {
    eventData()
    setBackUrlContext(`/${localStorage.getItem('role') ?? 'organiser'}/${id}`);
  }, []);

  useEffect(() => {
    if (event?.isSolo) {
      setCurrentContriType('fixed')
    }
  }, [event])


  const eventData = () => {
    showLoader();
    eventService.viewEvent(id).then(res => {
      setEvent(res);
      setContriType(res?.contriType);
      setFixedAmount(res?.contriPerPerson)
      setActiveSwitchAny(res?.minimumContri)
      setMinimumContriAmount(res?.minimumContriAmount)
      hideLoader();
      if (res?.contriType == 'any') {
        setActiveSwitchAny(true);
        setActiveTab(2);
      }
      if (res?.contriType == 'fixed') {
        setActiveSwitch(true);
        setActiveTab(1);
      }
    }).catch(err => {
      console.log(err);
      hideLoader();
    });

  }

  const checkFixedAmount = (e) => {
    setFixedAmount(e.target.value)
    const inputValue = parseInt(e.target.value); // Convert input to an integer
    const minimumAmount = parseInt(event?.giftCard?.minimumAmount);
    const maximumAmount = parseInt(event?.giftCard?.maximumAmount);

    if (!isNaN(inputValue) && (inputValue > 0) && (inputValue % minimumAmount === 0)) {
      if (inputValue > maximumAmount) {
        setFieldError4(true);
        setMaxParticipants(0);
        return false;
      }
      setFieldError3(false);
      setFieldError4(false);
      const maxParticipants = Math.floor(maximumAmount / inputValue);
      setMaxParticipants(maxParticipants);
    } else {
      setFieldError3(true);
      setFieldError4(false);
      setMaxParticipants(0);
    }
  }

  const handleSwitch = (e) => {
    setActiveSwitch(e)

  }
  useEffect(() => {
    if (activeTab == 1) {
      setFieldError1(false)
    } else if (activeTab == 2) {
      setFieldError1(false)
    }
  }, [currentContriType])

  let payload = {}
  payload["_id"] = id;
  payload["contriType"] = currentContriType;
  payload["minimumContri"] = activeSwitch;
  payload["contriPerPerson"] = fixedAmount;
  payload["minimumContriAmount"] = minimumContriAmount;
  payload["maxParticipants"] = maxParticipants;
  payload["contriUpdated"] = true

  const updateEvent = () => {
    if (currentContriType == null) {
      setFieldError1(true)
    } else {
      if (currentContriType == 'fixed' && isNotEmpty(fixedAmount)) {
        setMinimumContriAmount(null)
        setActiveSwitchAny(false)
        handleEventUpdate()

      } else if (currentContriType == 'any') {
        if (activeSwitch) {
          setFixedAmount(null)
          handleEventUpdate()
          // if (isNotEmpty(minimumContriAmount)) {
          //   setFixedAmount(null)
          //   handleEventUpdate()
          // } else {
          //   showErrorToast('Please Enter Minimun Contribution')
          // }
        } else if (!activeSwitch) {
          setFixedAmount(null)
          setMinimumContriAmount(null)
          handleEventUpdate()
        }

      } else {
        showErrorToast('Please Provide Fixed Amount')
      }
    }
  }


  const handleEventUpdate = () => {
    showLoader();
    setFirstTime(false);
    eventService.updateEventById(payload).then(res => {
      navigate(-1);
      hideLoader();
    }).catch(err => {
      console.log(err);
      hideLoader();
    });
  }

  const handeAccordinChange = (e) => {
    if (e != null) {
      let numberData = parseInt(e);
      setActiveTab((numberData + 1));
    } else {
      setActiveTab(0);
    }
  }

  useEffect((e) => {
    if (activeTab == 0) {
      setCurrentContriType(null)
    } else if (activeTab == 1) {
      setCurrentContriType('fixed')
    } else if (activeTab == 2) {
      setCurrentContriType('any')
    }
  }, [activeTab]);

  const handleFixedAmount = () => {
    let payload = {}
    payload["_id"] = id
    if (isNotEmpty(fixedAmount)) {
      payload["contriPerPerson"] = fixedAmount
      update(payload)
    } else {
      showErrorToast('Please Enter Fixed Amount')
    }
  }

  const update = (data) => {
    showLoader();

    eventService.updateEventById(data).then(res => {
      eventData();
      hideLoader();
      showSuccessToast('Contribution Setting has been Saved')
      navigate('/getStarted')
    }).catch(err => {
      console.log(err);
      hideLoader();
    });
  }

  const handleAnyAmount = () => {
    let payload = {};
    payload["_id"] = id;
    if (activeSwitchAny) {
      if (isNotEmpty(minimumContriAmount)) {
        payload["minimumContri"] = true;

        payload["minimumContriAmount"] = minimumContriAmount;
        update(payload);
      } else {
        showErrorToast('Please Enter minimun Contribution Amount');
      }
    } else {
      payload["minimumContri"] = false;
      payload["minimumContriAmount"] = null;
      update(payload);
    }

  }

  const handleSwitchAny = (e) => {
    setActiveSwitchAny(e)
  }

  const handleCloseModal = (e) => {
    setShowModal(false);
  }

  return (
    <>
      {/* <button onClick={updateEvent}>click</button> */}
      <h4 className="titleevent">{event?.isSolo ? "Gift Card Amount" : "Contribution Settings"}</h4>
      <div className='ContributionPage'>
        <div className='headcontri'>
          {
            event?.isSolo ?
              <></> :
              FieldError1 ? <h3 style={{ color: 'red' }}>Participants can contribute</h3> : <h3>Participants can contribute</h3>
          }
          {
            event?.isSolo ?
              <>
                <div className='amount-type'>
                  <BiRupee />
                  <Form.Control type="number" placeholder="100" value={fixedAmount} onChange={(e) => checkFixedAmount(e)} />
                </div>
                {FieldError3 ? <p style={{ color: 'red' }}>The fixed amount should be multiple of the minimum amount {getTotalContri(event?.giftCard?.minimumAmount)} of the Gift Card chosen</p> : ''}
                {FieldError4 ? <p style={{ color: 'red' }}>The fixed amount cannot be greater than maximum amount {getTotalContri(event?.giftCard?.maximumAmount)} of the Gift Card chosen</p> : ''}
                <br />
              </>
              :
              <div className='Contributionbox'>
                <Accordion onSelect={handeAccordinChange}>
                  {/* Fixed Amount */}
                  <Accordion.Item className={activeTab === 1 ? 'active' : ''} eventKey="0">
                    <Accordion.Header>
                      <div className='headeraccor'>
                        <div className='typecheckbox'>
                          <div className='check'></div>
                          <img src="/images/Checkboxbase.png" alt='checkbox' />
                        </div>
                        <div className='titlebar'>
                          <h5>Fixed Amount</h5>
                          <p>All participants pay the same amount.</p>
                        </div>
                      </div>
                      <div className='icon'>
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.99935 5.33341V8.66675M14.9993 5.33341V8.66675M1.66602 3.83341L1.66602 10.1667C1.66602 11.1002 1.66602 11.5669 1.84767 11.9234C2.00746 12.237 2.26243 12.492 2.57603 12.6518C2.93255 12.8334 3.39926 12.8334 4.33268 12.8334L15.666 12.8334C16.5994 12.8334 17.0661 12.8334 17.4227 12.6518C17.7363 12.492 17.9912 12.237 18.151 11.9234C18.3327 11.5669 18.3327 11.1002 18.3327 10.1667V3.83342C18.3327 2.89999 18.3327 2.43328 18.151 2.07677C17.9912 1.76316 17.7363 1.50819 17.4227 1.34841C17.0661 1.16675 16.5994 1.16675 15.666 1.16675L4.33268 1.16675C3.39926 1.16675 2.93255 1.16675 2.57603 1.3484C2.26243 1.50819 2.00746 1.76316 1.84767 2.07676C1.66602 2.43328 1.66602 2.89999 1.66602 3.83341ZM12.0827 7.00008C12.0827 8.15067 11.1499 9.08342 9.99935 9.08342C8.84876 9.08342 7.91602 8.15067 7.91602 7.00008C7.91602 5.84949 8.84876 4.91675 9.99935 4.91675C11.1499 4.91675 12.0827 5.84949 12.0827 7.00008Z" stroke="#4F7A21" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>

                      <p>Enter per person contribution</p>
                      <div className='amount-type'>
                        <BiRupee />
                        <Form.Control type="number" placeholder="100" value={fixedAmount} onChange={(e) => checkFixedAmount(e)} />
                      </div>
                      {FieldError3 ? <p style={{ color: 'red' }}>The fixed amount should be multiple of the minimum amount {getTotalContri(event?.giftCard?.minimumAmount)} of the Gift Card chosen</p> : ''}
                      {FieldError4 ? <p style={{ color: 'red' }}>The fixed amount cannot be greater than maximum amount {getTotalContri(event?.giftCard?.maximumAmount)} of the Gift Card chosen</p> : ''}
                      <div className='note-error'>
                        <AiOutlineInfoCircle />
                        <p>Maximum Participant limit is {maxParticipants}  </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* Any Amount */}
                  {/* <Accordion.Item className={activeTab === 2 ? 'active' : ''} eventKey="1">
                <Accordion.Header>
                  <div className='headeraccor'>
                    <div className='typecheckbox'>
                      <div className='check'></div>
                      <img src="/images/Checkboxbase.png" alt='checkbox' />
                    </div>
                    <div className='titlebar'>
                      <h5>Any Amount</h5>
                      <p>Participants can choose to pay any amount they are comfortable with.</p>
                    </div>
                  </div>
                  <div className='icon'>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.99935 7.16658V10.4999M14.9993 5.49992V8.83325M14.166 1.33325C16.2066 1.33325 17.3103 1.64555 17.8594 1.88779C17.9326 1.92005 17.9691 1.93618 18.0747 2.03689C18.1379 2.09727 18.2534 2.27441 18.2831 2.35666C18.3327 2.49387 18.3327 2.56887 18.3327 2.71887V11.6759C18.3327 12.4332 18.3327 12.8118 18.2191 13.0065C18.1036 13.2044 17.9922 13.2965 17.776 13.3726C17.5634 13.4474 17.1343 13.3649 16.2762 13.2C15.6755 13.0846 14.9631 12.9999 14.166 12.9999C11.666 12.9999 9.16602 14.6666 5.83268 14.6666C3.79209 14.6666 2.6884 14.3543 2.13925 14.112C2.06612 14.0798 2.02955 14.0637 1.92402 13.9629C1.86075 13.9026 1.74528 13.7254 1.71557 13.6432C1.66602 13.506 1.66602 13.431 1.66602 13.281L1.66602 4.32396C1.66602 3.56666 1.66602 3.188 1.77958 2.99337C1.8951 2.79539 2.00651 2.70335 2.22273 2.62726C2.4353 2.55246 2.86438 2.6349 3.72254 2.79979C4.3232 2.9152 5.03558 2.99992 5.83268 2.99992C8.33268 2.99992 10.8327 1.33325 14.166 1.33325ZM12.0827 7.99992C12.0827 9.15051 11.1499 10.0833 9.99935 10.0833C8.84876 10.0833 7.91602 9.15051 7.91602 7.99992C7.91602 6.84932 8.84876 5.91658 9.99935 5.91658C11.1499 5.91658 12.0827 6.84932 12.0827 7.99992Z" stroke="#4F7A21" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Accordion.Header>
                <AccordionBody>
                  <Form.Group className="fixform" controlId="formBasicEmail">
                    <Form.Label>Minimum contribution</Form.Label>
                    <div className='amount-type'>
                      <BiRupee />
                      <Form.Control type="number" placeholder="100" value={minimumContriAmount} onChange={(e) => { setMinimumContriAmount(e.target.value) }} />
                      <span className='perperson'>/ person</span>
                    </div>
                  </Form.Group>
                </AccordionBody>
              </Accordion.Item>*/}
                </Accordion>

              </div>
          }
          {FieldError1 ?
            <h3 style={{ color: 'red' }}>{FieldError2}</h3> : ''}
          <div className='contri-bottom'>
            {
              FieldError3 || FieldError4 ?
                <button disabled type='button' className='btn-pink mb-3'><AiOutlineSave />Save</button>
                :
                <button type='button' onClick={e => {
                  if (!isSolo) {
                    setShowModal(true)
                  } else {
                    updateEvent()
                  }
                }} className='btn-pink mb-3'><AiOutlineSave />Save</button>

            }
            <p>Please note this setting cannot be changed once the contributions have started.</p>
          </div>

        </div>

      </div >

      <Modal className="Createevent-modal fixamountpopup dateadded-popup" show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="faviconadded" closeButton>
          weGroup
        </Modal.Header>
        <Modal.Body>
          Alert: Is your contribution amount setting final? Review the group size and amount before inviting participant's. Want To Continue?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={updateEvent} className="btn-pink">Yes</button>
          <Button className='btngray' onClick={handleCloseModal} type="button">No</Button >
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContributionPage;
