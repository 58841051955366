import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";

const LandingHeader = () => {
    return (
        <>
            <div className="LandingHeader">
                <Navbar expand="lg">
                    <Navbar.Brand >
                        <img src='/images/weGroup.png' alt='logo' />
                        <h6 className="mb-0">BETA</h6>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to='/landing'  >Home</NavLink>
                            <NavLink to="/landingaboutus">About</NavLink>
                            <NavLink to="/landingblogs">Blogs</NavLink>
                            <NavLink to="/login">Login</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </>
    );
}

export default LandingHeader;