import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';
import { AiOutlineHome } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import { isNotEmpty } from "../../utils/helpers";
import { ThemeContext } from '../../hooks/useThemeContext';


function Header() {
  const navigate = useNavigate()
  const { backUrlContext, setBackUrlContext, hide, setHide } = useContext(ThemeContext);

  useEffect(() => {
    localStorage.setItem('backUrl', backUrlContext);
  }, [backUrlContext])

  useEffect(() => {
    console.log(window.location.pathname)
    // if (window.location.pathname.startsWith("/totalcontribution")) {
    //   setHide(true);
    //   console.log("==jksjk")
    // } else {
    //   setHide(false);
    // }
  }, [hide])


  const goBack = (e) => {
    if (isNotEmpty(localStorage.getItem('backUrl'))) {
      let url;
      url = localStorage.getItem('backUrl');
      localStorage.removeItem("backUrl");
      navigate(url);
      e.preventDefault();
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      {
        hide ?
          <></>
          :
          <>
            <div className='Headerbar'>
              <ul>
                <li>
                  <Link className='backtobasic' onClick={goBack}>
                    {
                      backUrlContext == "home" ?
                        <AiOutlineHome />
                        :
                        <HiArrowSmLeft />
                    }
                  </Link>
                </li>
                <li>
                  <h6>BETA</h6>
                </li>
              </ul>
            </div>
          </>
      }
    </>
  );
}

export default Header;
