import React, { useRef, useState, useEffect, useContext } from 'react';
import { FiVideo, FiVideoOff } from "react-icons/fi";
import { MdFlipCameraAndroid } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import { convertStoMs, showLoader, hideLoader, haveValue } from '../utils/helpers';
import TestimonialAction from '../Actions/testimonial.service';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from "../hooks/useThemeContext";
import { AuthContext } from "../Context/AuthContext";

const VideoRecorder = (props) => {
    const { edit, testId } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [facingMode, setFacingMode] = useState('user');
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [recordedVideoUrl, setRecordedVideoUrl] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [editTest, setEditTest] = useState(false);
    const [permission, setPermission] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const [caption, setCaption] = useState('');
    const [blob, setBlob] = useState('');
    const [timer, setTimer] = useState(21);
    const [testimonialId, setTestimonialId] = useState('');
    let continueDrawing = true
    let animationFrameId;

    const { showSuccessToast, showErrorToast } = useContext(ThemeContext);
    const { setIsSidebarVisible, setIsLoggedIn } = useContext(AuthContext);
    const isMobileDevice = () => {
        return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };
    useEffect(() => {
        setTestimonialId(testId);
    }, [testId])

    useEffect(() => {
        setEditTest(edit);
    }, [edit])

    useEffect(() => {
        if (timer == 0) {
            stopRecording();
        }
    }, [timer])

    useEffect(() => {
        if (recordedChunks?.length > 0 && !isRecording) {
            previewRecordedVideo();
        }
    }, [recordedChunks])

    useEffect(() => {
        console.log(facingMode)
        getStream();
    }, [facingMode]);

    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setTimer(20);
        }
        return () => clearInterval(interval);
    }, [isRecording]);

    const getStream = async () => {
        try {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const videoConstraints = {
                    audio: false,
                    video: {
                        width: { ideal: 320 },
                        height: { ideal: 320 },
                    },
                };
                if (isMobileDevice()) {
                    setIsMobile(true);
                    videoConstraints.video = { facingMode: { exact: facingMode } };
                }
                console.log(videoConstraints, "Video Constraints")
                const supported = navigator.mediaDevices.getSupportedConstraints().aspectRatio;
                console.log(supported, "===supported====", videoConstraints)
                if (supported) {
                    // videoConstraints.facingMode = { facingMode: { exact: facingMode } };
                }
                const audioConstraints = { audio: true };
                // create audio and video streams separately
                const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
                const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
                console.log(audioStream, videoStream, "HERE")
                setPermission(true);

                const combinedStream = new MediaStream([
                    ...videoStream.getVideoTracks(),
                    ...audioStream.getAudioTracks(),
                ]);
                setStream(combinedStream);
                if (videoRef.current) {
                    videoRef.current.srcObject = videoStream;
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const toggleCamera = () => {
        setFacingMode((prevFacingMode) => (prevFacingMode === 'user' ? 'environment' : 'user'));
    };

    const startRecording = async () => {
        if (videoRef.current && stream) {
            var options = { mimeType: 'video/mp4' };
            if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
                options = { mimeType: 'video/webm; codecs=vp9' };
            } else if (MediaRecorder.isTypeSupported('video/webm')) {
                options = { mimeType: 'video/webm' };
            } else if (MediaRecorder.isTypeSupported('video/mp4')) {
                options = { mimeType: 'video/mp4' };
            } else {
                alert("No suitable mimetype found for this device");
                console.error("No suitable mimetype found for this device");
                return;
            }

            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;

            // If facingMode is 'user', flip the video horizontally
            if (facingMode === 'user') {
                context.translate(canvas.width, 0);
                context.scale(-1, 1);
            }

            // Start drawing frames onto the canvas continuously
            const drawFrame = () => {
                if (!haveValue(videoRef?.current)) return; // Stop drawing frames if continueDrawing is false
                console.log(!haveValue(videoRef?.current), "drawFrame")
                if (videoRef?.current?.readyState === videoRef?.current?.HAVE_ENOUGH_DATA && haveValue(videoRef?.current)) {
                    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                }
                animationFrameId = requestAnimationFrame(drawFrame); // Continue the drawing loop
            };

            const startDrawing = () => {
                continueDrawing = true;
                drawFrame(); // Start the drawing loop
            };

            startDrawing(); // Start drawing frames
            const canvasStream = canvas.captureStream();
            const combinedStream = new MediaStream([
                ...canvasStream.getVideoTracks(),
                ...audioStream.getAudioTracks()
            ]);

            // Pass the canvas stream to the MediaRecorder
            const mediaRecorder = new MediaRecorder(combinedStream, options);

            mediaRecorder.ondataavailable = handleDataAvailable;
            mediaRecorder.start();

            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
            setRecordedChunks([]);
            setRecordedVideoUrl('');
        }
    };


    // const startRecording = () => {
    //     if (videoRef.current && stream) {
    //         // const options = { mimeType: 'video/webm' };
    //         var options = { mimeType: 'video/mp4' };
    //         if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
    //             options = { mimeType: 'video/webm; codecs=vp9' };
    //         } else if (MediaRecorder.isTypeSupported('video/webm')) {
    //             options = { mimeType: 'video/webm' };
    //         } else if (MediaRecorder.isTypeSupported('video/mp4')) {
    //             options = { mimeType: 'video/mp4' };
    //         } else {
    //             alert("no suitable mimetype found for this device");
    //             console.error("no suitable mimetype found for this device");
    //         }
    //         console.log(options,"Check Options here")
    //         const mediaRecorder = new MediaRecorder(stream, options);
    //         mediaRecorder.ondataavailable = handleDataAvailable;
    //         mediaRecorder.start();

    //         mediaRecorderRef.current = mediaRecorder;
    //         setIsRecording(true);
    //         setRecordedChunks([]);
    //         setRecordedVideoUrl('');
    //     }
    // };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            continueDrawing = false;
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            continueDrawing = false;
            cancelAnimationFrame(animationFrameId);
            setVideoDuration(convertStoMs(timer));
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        }
    };

    const handleDataAvailable = (event) => {
        if (event.data.size > 0) {
            setRecordedChunks((prevRecordedChunks) => [...prevRecordedChunks, event.data]);
        }
    };

    const previewRecordedVideo = () => {
        if (recordedChunks.length === 0) {
            setRecordedVideoUrl('');
            return;
        }
        const newBlob = new Blob(recordedChunks, { type: 'video/mp4' });  // supported by all devices while preview, webm is not supported in iOS
        setBlob(newBlob);
        const url = URL.createObjectURL(newBlob);
        setRecordedVideoUrl(url);
    };

    const saveTestimonial = () => {
        const data = new FormData();
        data.append("caption", caption);
        data.append("eventId", id);
        data.append("mediaType", "video");
        let videofile;
        videofile = new File([blob], "videofile.mp4", {
            type: "video/mp4",
        });
        data.append("file", videofile);
        showLoader();
        TestimonialAction.createTestimonial(data, id).then(res => {
            showSuccessToast('Wish Added');
            setCaption('');
            setTimeout(() => {
                hideLoader();
                toast.dismiss();
                props.handleVideoRecordingState(false);
                props?.onRefresh();
            }, 1000);
        }).catch(err => {
            hideLoader();
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);
                navigate('/');
            }
        });
    }

    const updateTestimonial = () => {
        showLoader();
        const data = new FormData();
        data.append("caption", caption);
        data.append("mediaType", "video");
        let videofile;
        videofile = new File([blob], "videofile.mp4", {
            type: "video/mp4",
        });
        data.append("file", videofile);

        TestimonialAction.updateTestimonial(data, testimonialId).then(res => {
            setCaption('');
            setEditTest(false);
            showSuccessToast('Wish Updated');
            setTimeout(() => {
                hideLoader();
                toast.dismiss();
                props.handleVideoRecordingState(false);
                props?.onRefresh();
            }, 1000);
        }).catch(err => {
            hideLoader();
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);
                navigate('/');
            }
        });
    }


    return (
        <>
            <div className="videoRecordingbar">
                {recordedChunks.length > 0 ? (
                    <div className='videoshow'>
                        <video autoPlay playsInline controls src={recordedVideoUrl} ></video>
                    </div>
                ) :
                    <div className='videoshow'>
                        {console.log(facingMode != 'user')}
                        <video ref={videoRef} autoPlay playsInline style={{ transform: facingMode != 'user' ? 'none' : 'scaleX(-1)' }}></video>
                    </div>
                }
                {
                    isMobile && !isRecording && !(recordedChunks.length > 0) ?
                        <button className='switchcam' onClick={toggleCamera}><MdFlipCameraAndroid /></button>
                        :
                        ''
                }
                {!isRecording ? (
                    !(recordedChunks.length > 0) && permission ?
                        <>
                            <button className='startcam' onClick={startRecording}><FiVideo /></button>
                        </>
                        :
                        ''
                ) : (
                    <>
                        <p className='duration'> {convertStoMs(timer)} seconds</p>
                        <button className='startcam'><FiVideo />Recording IS On</button>
                        <button className='stopcam' onClick={stopRecording}><FiVideoOff /></button>
                    </>
                )}
                {recordedChunks.length > 0 ?
                    <>
                        <div className='durationend-bar'>
                            {/* <p>Video Duration: {videoDuration} seconds</p> */}
                            <Form>
                                <Form.Control
                                    className=''
                                    as="textarea"
                                    placeholder="Add a message"
                                    style={{ height: '140px' }}
                                    value={caption ? caption : ''} onChange={(e) => setCaption(e.target.value)}
                                />
                            </Form>
                        </div>
                    </>
                    :
                    null
                }
                {recordedChunks.length > 0 ?
                    editTest ?
                        <div className='space-24'>
                            <button className='btn-pink' onClick={updateTestimonial}>Upload</button>
                        </div>
                        :
                        <div className='space-24'>
                            <button className='btn-pink' onClick={saveTestimonial}>Upload</button>
                        </div>
                    :
                    null
                }
            </div >
        </>
    );
}

export default VideoRecorder;