import React from "react";
import useSoloEvent from "../../hooks/useSoloEvent";

const EventHeaderTitle = () => {
  const isSolo = useSoloEvent();
  console.log("isSolo", isSolo);
  return (
    <>
      {isSolo ? (
        <h4 className="titleevent">Create Solo Gift Event</h4>
      ) : (
        <h4 className="titleevent">
          {console.log("isGroup")}Create Group Gift Event
        </h4>
      )}
    </>
  );
};

export default EventHeaderTitle;
