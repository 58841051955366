import React from 'react';
import AudioTestimonial from './AudioTestimonial';
import PhotosTestimonial from './PhotosTestimonial';
import TextTestimonial from './TextTestimonial';
import VideoTestimonial from './VideoTestimonial';

function TestimonialMain(props) {
  const { testimonials, viewOnly } = props;

  return (
    <>
      {
        testimonials?.mediaType === 'video' ?
          <VideoTestimonial onEdit={props.onEdit} onRefresh={props.onRefresh} testimonial={testimonials} viewOnly={viewOnly} />
          :
          ''
      }
      {
        testimonials?.mediaType === 'text' ?
          <TextTestimonial onEdit={props.onEdit} onRefresh={props.onRefresh} testimonial={testimonials} viewOnly={viewOnly} />
          :
          ''
      }
      {
        testimonials?.mediaType === 'image' ?
          <PhotosTestimonial onEdit={props.onEdit}  onRefresh={props.onRefresh} testimonial={testimonials} viewOnly={viewOnly} />
          :
          ''
      }
      {
        testimonials?.mediaType === 'voice' ?
          <AudioTestimonial onRefresh={props.onRefresh} testimonial={testimonials} viewOnly={viewOnly} />
          :
          ''
      }
      {
        testimonials?.mediaType === 'gif' ?
          <PhotosTestimonial onEdit={props.onEdit} onRefresh={props.onRefresh} testimonial={testimonials} viewOnly={viewOnly} />
          :
          ''
      }
    </>
  );
}

export default TestimonialMain;
