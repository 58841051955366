import Agent from "../Services/RequestInstance";
import { ServerError } from "../utils/helpers";
import * as http from '../Services/http';


import config from "../Config/Config";

const BACKEND_URL = config.BACKEND_URL;


function createEvent(payload, cb) {
    return http.post('/event/', payload);
}
function viewEvent(eventId) {
    return http.get('/event/' + eventId);
}

function invite(payload, cb) {
    return http.post('/event/invite', payload);
}

function acceptInvite(hash, stat) {
    return http.get(`/event/invite/accept/${hash}/${stat}`);
}

function rejectInvite(hash, stat) {
    return http.get(`/event/invite/accept/${hash}/${stat}`);
}

function participantsList(eventId) {
    return http.get('/event/participants/' + eventId);
}
function reSendInvitation(eventId) {
    return http.get('/event/invitation/resend/' + eventId);
}

function deleteParicipant(eventId) {
    return http.get('/event/delete-participant/' + eventId);
}

function updateEventById(payload, cb) {
    return http.post('/event/update', payload);
}

function cancelEvent(eventId) {
    return http.get('/event/cancel-event/' + eventId);
}

function selectGiftCard(payload, eventId) {
    return http.patch('/event/select-gift/' + eventId, payload);
}
function confirmOrder(eventId) {
    return http.get('/event/confirm-order/' + eventId);
}
function getGiftCards() {
    return http.get('event/get-cards');
}
function sendKudos(eventId, payload) {
    return http.post('event/send-kudos/' + eventId, payload);
}
function sendAllReminder(eventId) {
    return http.post('event/send-all-reminders/' + eventId);
}
function updateEventImg(eventId, payload) {
    return http.post('event/update-event-img/' + eventId, payload)
}




export default {
    createEvent,
    viewEvent,
    invite,
    acceptInvite,
    rejectInvite,
    participantsList,
    reSendInvitation,
    deleteParicipant,
    updateEventById,
    cancelEvent,
    selectGiftCard,
    getGiftCards,
    confirmOrder,
    sendKudos,
    sendAllReminder,
    updateEventImg
};