import { AiOutlineCheck } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import moment from 'moment';
import { getDateLocal, isNotEmpty, isValidDate } from '../../../utils/helpers';
import { Link } from 'react-scroll'
import { useEffect, useState } from 'react';

const EventPartiallyList = (props) => {

    const { eventData, enableAutoScroll } = props;

    const date = getDateLocal(eventData?.eventStart, eventData?.eventTime)

    let [autoScroll, setAutoScroll] = useState(false);

    useEffect(() => {
        if (isNotEmpty(enableAutoScroll)) {
            setAutoScroll(enableAutoScroll);
        }

    }, [enableAutoScroll]);

    const handleClickScroll = (id) => {
        if (window.location.search.toString().includes("preview=true")) {
            return false;
        }
        if (autoScroll) {
            const element = document.getElementById(`${id}`);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        }
    };

    const manuallyClick = () => {
        if (eventData?.scrollAction !== "AddParticipant") {
            if (isValidDate(eventData?.eventStart)) {
                if (isNotEmpty(eventData?.giftCardType)) {
                    if (isNotEmpty(eventData?.contriType)) {
                        if (eventData?.participants?.length > 1) {
                            if (eventData?.testimonials?.length > 0) {
                                if (eventData?.isConfirmed) {
                                    //nothing to scroll 
                                } else {
                                    handleClickScroll('ConfirmYourOrder');
                                }
                            } else {
                                handleClickScroll('AddTestimonialBox');
                            }
                        } else {
                            handleClickScroll('AddParticipant');
                        }
                    } else {
                        handleClickScroll('MoneyCollectBox');
                    }
                } else {
                    handleClickScroll('AddGiftBox');
                }
            } else {
                handleClickScroll('DateTimeBox');
            }
        }
    }


    useEffect(() => {
        manuallyClick();
    }, [eventData]);

    return (<>
        <ul className="EventPartially-list">

            {
                isValidDate(eventData?.eventStart) ?
                    <li className='checkbar'>
                        <Link to="DateTimeBox"  >
                            <span>  <AiOutlineCheck /> </span>
                            {/* {moment(eventData?.eventStart).format("LL")} {eventData?.eventTime} */}
                            {moment(date).format("LL")} {moment(date).format('h:mm a')}
                            {/* {moment(eventData?.eventTime).local()} */}
                        </Link>
                    </li>
                    :
                    new Date() > new Date(date) ?
                        <>
                            <li className='checkbar danger-checkbar'>
                                <Link to="DateTimeBox"  >
                                    <span>  <RxCross2 /> </span>
                                    Add Event Dates
                                </Link>
                            </li>
                        </>
                        :

                        <li >
                            <Link to="DateTimeBox"  >
                                <span>1</span>
                                Add Event Dates
                            </Link>
                        </li>
            }
            {
                eventData?.giftCardType ?
                    <li className='checkbar'>
                        <Link to="AddGiftBox"  >
                            <span>  <AiOutlineCheck /> </span>
                            Select Gift Options
                        </Link>
                    </li>
                    :
                    new Date() > new Date(date) ?
                        <>
                            <li className='checkbar danger-checkbar'>
                                <Link to="AddGiftBox"  >
                                    <span>  <RxCross2 /> </span>
                                    Select Gift Options
                                </Link>
                            </li>
                        </>
                        :
                        <> <li>
                            <Link to="AddGiftBox"  >
                                <span>2</span>
                                Select Gift Options
                            </Link>
                        </li></>
            }
            {
                eventData?.isSolo ?
                    isNotEmpty(eventData?.contriType) ?
                        <li className='checkbar'>
                            <Link to="MoneyCollectBox"  >
                                <span>  <AiOutlineCheck /> </span>
                                Add Money
                            </Link>
                        </li>
                        :
                        new Date() > new Date(date) ?
                            <>
                                <li className='checkbar danger-checkbar'>
                                    <Link to="MoneyCollectBox"  >
                                        <span>  <RxCross2 /> </span>
                                        Add Money
                                    </Link>
                                </li>
                            </>
                            :
                            <li>

                                <Link to="MoneyCollectBox"  >
                                    <span>3</span>
                                    Add Money
                                </Link>
                            </li>
                    :
                    isNotEmpty(eventData?.contriType) ?
                        <li className='checkbar'>
                            <Link to="MoneyCollectBox"  >
                                <span>  <AiOutlineCheck /> </span>
                                Configure & contribute money
                            </Link>
                        </li>
                        :
                        new Date() > new Date(date) ?
                            <>
                                <li className='checkbar danger-checkbar'>
                                    <Link to="MoneyCollectBox"  >
                                        <span>  <RxCross2 /> </span>
                                        Configure & contribute money
                                    </Link>
                                </li>
                            </>
                            :
                            <li>

                                <Link to="MoneyCollectBox"  >
                                    <span>3</span>
                                    Configure & contribute money
                                </Link>
                            </li>
            }
            {

                eventData?.isSolo ?
                    <>
                    </>
                    :
                    (eventData?.participants?.length > 1) ?
                        <li className='checkbar'>
                            <Link to="AddParticipant"  >
                                <span>  <AiOutlineCheck /> </span>
                                Invite {eventData?.name}'s friend's and family
                            </Link>
                        </li>
                        :
                        new Date() > new Date(date) ?
                            <>
                                <li className='checkbar danger-checkbar'>
                                    <Link to="AddParticipant"  >
                                        <span>  <RxCross2 /> </span>
                                        Invite {eventData?.name}'s friend's and family
                                    </Link>
                                </li>
                            </>
                            :
                            <li >

                                <Link to="AddParticipant"  >
                                    <span>4</span>
                                    Invite {eventData?.name}'s friend's and family
                                </Link>
                            </li>
            }


            {(eventData?.testimonials?.length > 0) ?
                <li className='checkbar'>
                    <Link to="AddTestimonialBox"  >
                        <span>  <AiOutlineCheck /> </span>
                        Send Wishes
                    </Link>
                </li>
                :
                new Date() > new Date(date) ?
                    <>
                        <li className='checkbar danger-checkbar'>
                            <Link to="AddTestimonialBox"  >
                                <span>  <RxCross2 /> </span>
                                Send Wishes
                            </Link>
                        </li>
                    </>
                    :
                    <li >

                        <Link to="AddTestimonialBox"  >
                            <span>{eventData?.isSolo ? '4' : '5'}</span>
                            Send Wishes
                        </Link>

                    </li>}

            {(eventData?.isConfirmed) ?
                <li className='checkbar'>
                    <Link to="ConfirmYourOrder"  >
                        <span>  <AiOutlineCheck /> </span>
                        Order Confirmation
                    </Link>
                </li>
                :
                new Date() > new Date(date) ?
                    <>
                        <li className='checkbar danger-checkbar'>
                            <Link to="ConfirmYourOrder"  >
                                <span>  <RxCross2 /> </span>
                                Order Confirmation
                            </Link>
                        </li>
                    </>
                    :
                    <li >

                        <Link to="ConfirmYourOrder"  >
                            <span>{eventData?.isSolo ? '5' : '6'}</span>
                            Order Confirmation
                        </Link>

                    </li>}
        </ul>

    </>)
}

export default EventPartiallyList