import Agent from "../Services/RequestInstance";
import { ServerError } from "../utils/helpers";
import * as http from '../Services/http';


import config from "../Config/Config";

const BACKEND_URL = config.BACKEND_URL;



function getFaqs(hash) {
  return http.get('/public/faq');
}

function getInvitationDetails(hash) {
  return http.get('/public/invitation-details/' + hash);
}



export default {
  getInvitationDetails,
  getFaqs
};