import React from "react";
import { Outlet } from "react-router-dom";
import ProfileHeader from "../../Header/ProfileHeader";


const ProfileLayout = () => {


    return (
        <>
            <div className="max-width-window">
                <ProfileHeader />
                <div className='body-wrapper'>
                    <Outlet />
                </div>
            </div>

        </>
    );
}

export default ProfileLayout;