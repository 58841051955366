import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Link, useParams } from 'react-router-dom';
import EventAction from "../../Actions/event.service";
import { showLoader, hideLoader, haveValue } from '../../utils/helpers';
import { ThemeContext } from '../../hooks/useThemeContext';
import Modal from 'react-bootstrap/Modal';
import FieldError from '../Common/FieldError';
import { isEmpty } from "lodash";
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../Context/AuthContext';


function WellWisher() {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [event, setEvent] = useState({});
    const { loggedInUserData } = useContext(AuthContext);
    const { setBackUrlContext, showSuccessToast, showErrorToast } = useContext(ThemeContext);
    const [error, setError] = useState("");
    const [thankyouMessage, setThankyouMessage] = useState("");
    const [sendKudosModal, setSendKudosModal] = useState(false);
    const [isRecipient, setIsRecipient] = useState(false);

    const handleClosedateadded = () => {
        setSendKudosModal(false);
    }

    const getEvent = () => {
        showLoader()
        EventAction.viewEvent(id).then(res => {
            setEvent(res);
            setData(res?.participants);
            hideLoader();
        }).catch(err => {
            console.log(err)
        });
    }
    const sendKudosToEveryoneAction = () => {
        if (isEmpty(thankyouMessage)) {
            setError("Please Write Your Message");
            return false;
        }
        const formData = new FormData();
        formData.append("thankyouMessage", thankyouMessage);
        showLoader()
        EventAction.sendKudos(id, formData).then(res => {
            setSendKudosModal(false);
            hideLoader();
            showSuccessToast('Kudos Sent')
            setThankyouMessage("");
            setError("");
        }).catch(err => {
            console.log(err)
            showErrorToast('Sending Kudos Failed');
            hideLoader();
        });
    }

    useEffect(() => {
        getEvent();
        setBackUrlContext(`/recipient/${id}`)
    }, [])

    useEffect(() => {
        showLoader()
        if (haveValue(loggedInUserData) && haveValue(event)) {
            if (event?.email == loggedInUserData?.email || `+${event?.phone}` == loggedInUserData?.phoneNumber) {
                setIsRecipient(true);
                hideLoader();
            } else {
                setIsRecipient(false);
                hideLoader();
            }
        }
    }, [loggedInUserData, event])

    console.log(data, "CHECK HERE")

    return (
        <>
            <h4 className="titleevent">Well Wishers</h4>
            <div className='wellwisherlist'>
                <ul>
                    {data?.length > 0 && data.map((item, i) => {
                        return (<>
                            {item?.status === "joined" && item?.hasContributed ?
                                <>
                                    <li>
                                        <h4>{item?.userId?.fullName}</h4>
                                        {/* <Button className='btngray'>Send Kudos</Button> */}
                                    </li>
                                </>
                                : ''
                            }
                        </>)
                    })}
                    {
                        haveValue(isRecipient) && isRecipient ?
                            <>
                                <div className='btn-fix'>
                                    <Button className='btn-pink' onClick={(e) => setSendKudosModal(true)}>Send Kudos to everyone</Button>
                                </div>
                            </>
                            :
                            <></>
                    }
                </ul>

            </div>
            <Modal className="Createevent-modal fixamountpopup thankyoubar opacity-full dateadded-popup" show={sendKudosModal} onHide={handleClosedateadded}>
                <Modal.Header closeButton>
                    <h6>Thank you Message  </h6>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="fixform mb-3" controlId="formBasicEmail">
                        <div className='amount-type'>
                            <Form.Control
                                className={error ? 'error' : ""}
                                as="textarea"
                                style={{ height: '134px' }}
                                onChange={(e) => setThankyouMessage(e?.target.value)} value={thankyouMessage}
                            />
                        </div>
                    </Form.Group>
                    <FieldError error={error} />
                    <Button type="button" onClick={sendKudosToEveryoneAction} className='btn-pink'>
                        Send Kudos
                    </Button>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default WellWisher;
