import React, { useState, useEffect, useContext } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SearchInput from '../SearchInput/Index';
import AllTestimonial from './TestimonialTabs/AllTestimonial';
import { hideLoader, showLoader } from "../../utils/helpers";
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineSend, AiOutlineLink, AiOutlineCamera, AiOutlineAudio } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import TestimonialAction from "../../Actions/testimonial.service";
import EventAction from "../../Actions/event.service";
import VideoRecorder from 'react-video-recorder';
import { ThemeContext } from "../../hooks/useThemeContext";
import Modal from 'react-bootstrap/Modal';
import FieldError from '../Common/FieldError';
import { isEmpty } from "lodash";
import { AuthContext } from "../../Context/AuthContext";

function TestimonialListRecipient(props) {
  const navigate = useNavigate();

  const { id } = useParams();
  const [testimonialList, setTestimonialList] = useState([]);
  const [testimonialListMain, setTestimonialListMain] = useState([]);
  const [type, setType] = useState('all');
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [sendKudosModal, setSendKudosModal] = useState(false);

  const { showSuccessToast, setBackUrlContext, showErrorToast } = useContext(ThemeContext);
  const { setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);

  useEffect(() => {
    getTestimonials();
    setBackUrlContext(`/recipient/${id}`)
  }, []);

  const handleClosedateadded = () => {
    setSendKudosModal(false);
  }

  const getTestimonials = () => {
    showLoader();
    TestimonialAction.testimonialListRec(id, type, value).then(res => {
      setTestimonialListMain(res.testimonials);
      setTestimonialList(res.testimonials);
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);

        navigate('/');
      }
    });
  }
  const handleRefreshTestimonials = () => {
    getTestimonials();
  }
  const handleOnChange = (searchWord) => {
    // setValue(searchWord);
    // testimonialList.filter(caption => caption.includes(event)).map(abc => (
    //   console.log(abc, "==")
    // ))
    let resultA = [];
    const newFilter = Object.keys(testimonialListMain).reduce((result, key) => {
      if (testimonialListMain[key].caption.includes(searchWord)) {
        resultA.push(testimonialListMain[key]);
      };
      setTestimonialList(resultA);
    }, []);
  };

  useEffect(() => {
    getTestimonials();
  }, [value]);
  useEffect(() => {
    getTestimonials();
  }, [type]);

  const sendKudosToEveryoneAction = () => {
    if (isEmpty(thankyouMessage)) {
      setError("Please Write Your Message");
      return false;
    }
    const formData = new FormData();
    formData.append("thankyouMessage", thankyouMessage);
    showLoader()
    EventAction.sendKudos(id, formData).then(res => {
      setSendKudosModal(false);
      hideLoader();
      showSuccessToast('Kudos Sent')
      setThankyouMessage("");
      setError("");
    }).catch(err => {
      console.log(err)
      showErrorToast('Sending Kudos Failed');
      hideLoader();
    });
  }

  return (
    <>
      {
        testimonialList && testimonialList.length > 0 ? <>
          <h4 className="titleevent">Wishes</h4>
          <div className='TestimonialList'>
            <SearchInput handleSearch={(e) => handleOnChange(e)} />
            <div className='testimonial-tabs'>
              <Tabs
                defaultActiveKey="all" onSelect={(index) => setType(index)}>
                <Tab eventKey="all" title="All">
                  <AllTestimonial viewOnly="true" onRefresh={handleRefreshTestimonials} testimonial={testimonialList} recipient={true} />
                </Tab>
                {/* <Tab eventKey="video" title="Videos">
                  <AllTestimonial viewOnly="true" onRefresh={handleRefreshTestimonials} testimonial={testimonialList} recipient={true} />
                </Tab> */}
                <Tab eventKey="voice" title="Audio">
                  <AllTestimonial viewOnly="true" onRefresh={handleRefreshTestimonials} testimonial={testimonialList} recipient={true} />
                </Tab>
                <Tab eventKey="text" title="Text">
                  <AllTestimonial viewOnly="true" onRefresh={handleRefreshTestimonials} testimonial={testimonialList} recipient={true} />
                </Tab>
                <Tab eventKey="gif" title="Gif">
                  <AllTestimonial viewOnly="true" onRefresh={handleRefreshTestimonials} testimonial={testimonialList} recipient={true} />
                </Tab>
              </Tabs>
            </div>
            <Button className='btn-pink' onClick={(e) => setSendKudosModal(true)}>Send Kudos to everyone</Button>
          </div>
        </> : <>
          <h4 className="titleevent">Wishes</h4>
          <div className='TestimonialList'>
            <SearchInput handleSearch={(e) => handleOnChange(e)} />
            <div className='testimonial-tabs'>
              <Tabs
                defaultActiveKey="all" onSelect={(index) => setType(index)}>
                <Tab viewOnly="true" eventKey="all" title="All">
                </Tab>
                {/* <Tab viewOnly="true" eventKey="video" title="Videos">
                </Tab> */}
                <Tab viewOnly="true" eventKey="voice" title="Audio">
                </Tab>
                <Tab viewOnly="true" eventKey="text" title="Text">
                </Tab>
                <Tab viewOnly="true" eventKey="gif" title="Gif">
                </Tab>
              </Tabs>
            </div>
          </div>
          <Button className='btn-pink' onClick={(e) => setSendKudosModal(true)}>Send Kudos to everyone</Button>
        </>
      }
      <Modal className="Createevent-modal fixamountpopup thankyoubar opacity-full dateadded-popup" show={sendKudosModal} onHide={handleClosedateadded}>
        <Modal.Header closeButton>
          <h6>Thankyou Message  </h6>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="fixform mb-3" controlId="formBasicEmail">
            <div className='amount-type'>
              <Form.Control
                className={error ? 'error' : ""}
                as="textarea"
                style={{ height: '134px' }}
                onChange={(e) => setThankyouMessage(e?.target.value)} value={thankyouMessage}
              />
            </div>
          </Form.Group>
          <FieldError error={error} />
          <Button type="button" onClick={sendKudosToEveryoneAction} className='btn-pink'>
            Send Kudos
          </Button>
        </Modal.Body>
      </Modal >
    </>

  );
}

export default TestimonialListRecipient;
