import React, { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import Form from 'react-bootstrap/Form';

function SearchInput(props) {

  return (
    <>
      <div className='SearchInput'>
        <BiSearch />
        <Form.Control type="text" placeholder="Search" onChange={(e) => props?.handleSearch(e.target.value)} />
      </div>
    </>
  );
}

export default SearchInput;
