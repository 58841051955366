import React from 'react';
import { Link } from 'react-router-dom';
import { BsXLg } from 'react-icons/bs';

function ProfileHeader() {
  return (
      <>
      <div className='Headerbar profilehead'>
        <ul>
          <li>
            <h4>Profile</h4>
          </li>
          <li>
          <Link className='' to="/getStarted">
                <BsXLg/>
            </Link>
          </li>
        </ul>
      </div>
      </>
  );
}

export default ProfileHeader;
