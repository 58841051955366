import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sparkle from '../../../Animations/Sparkle';
import OpenGiftCard from '../../../Components/GiftCard/OpenGiftCard';
import AddKudos from '../../../Components/Kudos/AddKudos';
import AllTestimonial from '../../../Components/Testimonials/TestimonialTabs/AllTestimonial';
import TextCardTestimonial from '../../../Components/Testimonials/TestimonialTabs/TextCardTestimonial';
import { useParams } from 'react-router-dom';
import EventAction from "../../../Actions/event.service";
import TestimonialAction from "../../../Actions/testimonial.service";
import { usersInEvent, showLoader, hideLoader, withBackendUrl, ShowImage, haveValue, usersInEventTestimonial } from '../../../utils/helpers';
import UserPerviewListRec from "../../../Components/UserPerviewListRec";
import BottomBtn from "../../../Components/BottomToTop";
import Button from 'react-bootstrap/esm/Button';
import { AuthContext } from '../../../Context/AuthContext';
import { ThemeContext } from '../../../hooks/useThemeContext';
import { isEmpty } from 'lodash';

function Recepient() {
  const navigate = useNavigate();
  const { isLoggedIn, loggedInUserData, loggedInUserId, setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);
  const { setBackUrlContext, backUrlContext } = useContext(ThemeContext);

  const { id } = useParams();
  const [data, setData] = useState({});
  const [videoTest, setVideoTest] = useState(false);
  const [participantList, setParticipantList] = useState({});
  const [testimonialList, setTestimonialList] = useState([]);
  const [testimonialListRec, setTestimonialListRec] = useState([]);
  const [testimonialListGif, setTestimonialListGif] = useState([]);
  const [testimonialListAud, setTestimonialListAud] = useState([]);
  const [testimonialListText, setTestimonialListText] = useState([]);
  const [usersDataTest, setUsersDataTest] = useState([]);
  const [usersDataPart, setUsersDataPart] = useState([]);
  const [isRecipient, setIsRecipient] = useState(false);

  useEffect(() => {
    getEvent();
    getTestimonials();
    if (haveValue(isLoggedIn) && !isLoggedIn) {
      setBackUrlContext(`/recipient/${id}`);
      return;
    }
    if (localStorage.getItem('backToEvent')) {
      setBackUrlContext(`/${localStorage.getItem('role') ?? 'organiser'}/${id}`);
    } else if (localStorage.getItem('backToRecList')) {
      setBackUrlContext(`recipientlist`)
      localStorage.removeItem('backToRecList')
    } else if (localStorage.getItem('backToHome') || localStorage.getItem('redirectBackTo') === "/getStarted") {
      setBackUrlContext(`/getStarted`)
      localStorage.removeItem('backToHome')
    } else {
      setBackUrlContext(`recipientlist`)
    }
  }, [isLoggedIn]);


  const checkLoginandRedirect = (link) => {
    localStorage.setItem("link", link);
    localStorage.setItem("backUrl", `/recipient/${data?._id}`);
    setBackUrlContext(`/recipient/${data?._id}`);
    navigate('/login');
  }

  const getEvent = () => {
    showLoader();
    EventAction.viewEvent(id).then(res => {
      setData(res);
      setParticipantList(res?.participants);
      hideLoader();
    }).catch(err => {
      console.log(err)
    });
  }

  useEffect(() => {
    if (isEmpty(loggedInUserData)) {
      setIsRecipient(false);
    } else {
      if (data?.email == loggedInUserData?.email || data?.phone == loggedInUserData?.phoneNumber || `+${data?.phone}` == loggedInUserData?.phoneNumber) {
        setIsRecipient(true);
      }
    }
  }, [participantList, loggedInUserId]);

  const getTestimonials = () => {
    TestimonialAction.testimonialListRec(id, "all", "").then(res => {
      setTestimonialListRec(res.testimonials);

      const testimonialListGifTemp = [];
      const testimonialListAudTemp = [];
      const testimonialListTextTemp = [];

      for (let wish of res.testimonials) {
        if (wish?.mediaType === "gif") {
          testimonialListGifTemp.push(wish);
          // setTestimonialListGif(current => [...current, wish]);
        } else if (wish?.mediaType === "voice") {
          testimonialListAudTemp.push(wish);
          // setTestimonialListAud(current => [...current, wish]);
        } else if (wish?.mediaType === "text") {
          testimonialListTextTemp.push(wish);
          // setTestimonialListText(current => [...current, wish]); 
        } else {
        }
      }

      setTestimonialListGif(current => [...testimonialListGifTemp]);
      setTestimonialListAud(current => [...testimonialListAudTemp]);
      setTestimonialListText(current => [...testimonialListTextTemp]); 
      
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
    TestimonialAction.testimonialList(id, "all", "").then(res => {
      setTestimonialList(res.testimonials);
      for (let wish of res.testimonials) {
        if (wish?.mediaType === "video") {
          setVideoTest(true)
        }
      }
      hideLoader();
    }).catch(err => {
      console.log(err)
      if (err?.response?.data?.message == "Unauthorized User.") {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setIsSidebarVisible(false);
        navigate('/');
      }
    });
  }


  useEffect(() => {
    let TestimonialListData = usersInEventTestimonial(testimonialList);
    setUsersDataTest(TestimonialListData);
  }, [testimonialList])

  useEffect(() => {
    let ParticipantListData = usersInEvent(participantList);
    setUsersDataPart(ParticipantListData);
  }, [participantList])

  const handleInstagram = async () => {
    window.open('https://www.instagram.com/social.wegroup4/', '_blank')
  }
  const handleFacebook = async () => {
    window.open('https://www.facebook.com/profile.php?id=61552328150885', '_blank')
  }
  const handleTwitter = () => {
    window.open('https://twitter.com/social_weGroup4')
  }
  const handleYoutube = () => {
    window.open('https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w')
  }

  return (
    <>
      <div className='Recepient RecepientPartially'>
        <div className="avatar-img">
          <img src={ShowImage(data?.photo, data?.name)} alt="avatar-img" />
        </div>
        <Sparkle />
        <div className='receipient-message'>
          {
            data?.occasionId === "Birthday" ?
              <><h5 className='font-send'>Happy Birthday</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Wedding" ?
              <><h5 className='font-send'>Happy Married Life</h5></>
              :
              ''
          }
          {
            data?.occasionId === "House Warming" ?
              <><h5 className='font-send'>Happy House Warming</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Baby Shower" ?
              <><h5 className='font-send'>Happy Baby Shower</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Farewell" ?
              <><h5 className='font-send'>All the Best</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Promotion" ?
              <><h5 className='font-send'>Congratulations on the Promotion</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Anniversary" ?
              <><h5 className='font-send'>Happy Anniversary</h5></>
              :
              ''
          }
          {
            data?.occasionId === "Graduation" ?
              <><h5 className='font-send'>Congratulations</h5></>
              :
              ''
          }
          {
            data?.occasionId !== "Graduation" && data?.occasionId !== "Anniversary" && data?.occasionId !== "Promotion" && data?.occasionId !== "Farewell" && data?.occasionId !== "Baby Shower" && data?.occasionId !== "House Warming" && data?.occasionId !== "Wedding" && data?.occasionId !== "Birthday" ?
              <><h5 className='font-send'>Congratulations</h5></>
              :
              ''
          }


          <h3>{data?.name}</h3>
        </div>
        <AddKudos usersData={usersDataPart} event={data} />
        {
          haveValue(data?.giftCardType) ?
            <OpenGiftCard event={data} isRecipient={isRecipient} />
            :
            <></>
        }
        <UserPerviewListRec usersData={usersDataTest} videoLink={data?.video} event={data} hasVideo={videoTest} />
        {
          testimonialListRec.length > 0 ?
            <>
              <div className='testimonialbar'>
                <h4 className='title mb-3'>Wishes</h4>
                {
                  testimonialListGif.length > 0 ?
                    <AllTestimonial testimonial={testimonialListGif} viewOnly="true" />
                    :
                    <></>
                }
                {
                  testimonialListText.length > 0 ?
                    <TextCardTestimonial testimonial={testimonialListText} />
                    :
                    <></>
                }
                {
                  testimonialListAud.length > 0 ?
                    <AllTestimonial testimonial={testimonialListAud} viewOnly="true" />
                    :
                    <></>
                }
              </div>
            </>
            :
            <></>
        }
        <BottomBtn />
      </div>
      <div className='Footer'>
        <div className='foot-logo'>
          <Link to='/'><img src="/images/wegroup-white.svg" alt='logo' /></Link>
        </div>
        {/* <ul className='social-links'>
          <li><Link onClick={handleFacebook}><BsFacebook /></Link></li>
          <li><Link onClick={handleInstagram}><BsInstagram /></Link></li>
        </ul> */}
        <div className='contact-detailbar'>
          {/* <ul>
            <li><b>Company Name:</b> Radipro Technologies India Pvt Ltd.</li>
            <li><b>Address:</b> NK-I/605, FF-2, Indirapuram, Ghaziabad, Uttar Pradesh, PIN-201014</li>
            <li><b>Email Id:</b> <a href="mailto:Sumeshk@radiprotech.com">Sumeshk@radiprotech.com</a></li>
            <li><b>Mobile :</b> <a href="tel:+91-7718880313">+91-7718880313</a></li>
          </ul> */}
        </div>
        <ul className="social-links">
          <li>
            {/* <div className="Leftbar" onClick={handleFacebook}> */}
            <a className="Leftbar" href='https://www.facebook.com/profile.php?id=61552328150885' target='_blank'>
              <img className="social-img" src="/images/Facebook.png" alt="fb" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleInstagram}> */}
            <a className="Leftbar" href='https://www.instagram.com/social.wegroup4/' target='_blank'>
              <img className="social-img" src="/images/instagram.png" alt="instagram" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleTwitter}> */}
            <a className="Leftbar" href='https://twitter.com/social_weGroup4' target='_blank'>
              <img className="social-img" src="/images/TwitterNew.png" alt="twitter" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleYoutube}> */}
            <a className="Leftbar" href='https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w' target='_blank'>
              <img className="social-img" src="/images/youtube.png" alt="twitter" />
            </a>
            {/* </div> */}
          </li>
          <li>
            <div className="Leftbar">
              <img className="social-img" src="/images/linkedin.png" alt="twitter" />
            </div>
          </li>
        </ul>
        <p className='note'>© 2024 Radipro Technologies India Pvt Ltd. All rights reserved</p>
      </div>
    </>
  );
}

export default Recepient;
