import React, { useState, useEffect, useContext } from "react";
import { BsThreeDots, BsPencil } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineDelete, AiOutlineSend } from "react-icons/ai";
import moment from 'moment';
import { AuthContext } from './../../../Context/AuthContext';
import { ThemeContext } from "../../../hooks/useThemeContext";
import EditDelete from './../editDelete';
import { ShowImage, withBackendUrl, hideLoader, showLoader } from '../../../utils/helpers';
import Form from "react-bootstrap/Form";
import TestimonialAction from "../../../Actions/testimonial.service";
import Button from 'react-bootstrap/Button';


function TextTestimonial(props) {
  const { loggedInUserId } = useContext(AuthContext);
  const { showSuccessToast } = useContext(ThemeContext);

  const { testimonial, viewOnly } = props;
  const [editField, setEditField] = useState(false);
  const [caption, setCaption] = useState('');

  useEffect(() => {
    setCaption(testimonial?.caption);
  }, [testimonial]);
  const updateTestimonial = () => {
    const data = new FormData();
    data.append("caption", caption);
    showLoader();
    TestimonialAction.updateTestimonial(data, testimonial?._id).then(res => {
      setCaption('');
      setEditField(false);
      hideLoader();
      showSuccessToast('Wish Updated');
      props.onRefresh();
    }).catch(err => {
      hideLoader();
      console.log(err)
    });
  }


  const handleEdit = () => {
    localStorage.setItem('editTest', true);
    localStorage.setItem('isText', true);
    setEditField(true);
    props.onEdit();
  }

  return (
    <>
      <div className='Testi-content text'>
        <div className='testimonial-content-head'>
          <div className='headleft'>
            <img src={ShowImage(testimonial?.userId?.profilePhoto, testimonial?.userId?.fullName)} alt='rs' />
            <h6>{testimonial?.userId?.fullName}</h6>
          </div>
          <div className='headryt'>
            <p className='date'>{moment(testimonial?.createdAt).format("MMM DD YYYY")}</p> |
            <p className='time'>{moment(testimonial?.createdAt).format("hh:mm A")}</p>
          </div>
        </div>
        <div className='testimonials-content-text'>
          {
            editField ?
              <>
                <div className='inputbar'>
                  <Form.Control type="text" value={caption ? caption : ''} onChange={(e) => setCaption(e.target.value)} />
                  <Button onClick={updateTestimonial} type='button' className='btnsend'><AiOutlineSend /></Button>
                </div>
              </>
              :
              <p>{testimonial?.caption}</p>
          }
        </div>
        {
          viewOnly === "true" || viewOnly === true ?
            ''
            :
            <>
              {
                (loggedInUserId === testimonial?.userId?._id) || (testimonial.eventId.createdBy === loggedInUserId) ?
                  <EditDelete onEdit={handleEdit} onRefresh={props.onRefresh} testimonial={testimonial} type="text" handleTestimonial={(e) => props.handleTestimonialList(e)} />

                  :
                  ''
              }
            </>
        }
      </div>

    </>
  );

}

export default TextTestimonial;

