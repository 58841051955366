import React, { useEffect, useState, useContext } from 'react';
import { printUnderScoreText, formateDate, showLoader, hideLoader, getTotalContri } from '../../utils/helpers';
import moment from 'moment';
import { AuthContext } from "../../Context/AuthContext";

function ContributionRow(props) {
    const { isLoggedInUserId } = useContext(AuthContext);

    const { contribution, yet, hideAmount } = props;
    console.log(hideAmount)
    return (
        <>

            {yet ?
                <>
                    <li className='revokeli'>
                        <div className='li'>
                            <div className='detail-contribution'>
                                <h4>{contribution?.userId?.fullName}</h4>
                            </div>
                            <div className='btnbar'>
                                <h4>
                                    {
                                        contribution?.eventId?.contriType === "fixed" ?
                                            getTotalContri(contribution?.eventId?.contriPerPerson)
                                            :
                                            '-'
                                    }
                                </h4>
                            </div>
                        </div>
                    </li>
                </>
                :
                <>
                    <li className='revokeli'>
                        <div className='li'>
                            <div className='detail-contribution'>
                                <h4>{contribution?.eventParticipantId?.userId?.fullName}</h4>
                                <p>{moment(contribution?.createdAt).format("MMM DD YYYY")} | {moment(contribution?.createdAt).format("hh:mm A")}</p>
                            </div>
                            {
                                hideAmount ?
                                    <>
                                    </>
                                    :
                                    <div className='btnbar'>
                                        <h4>{getTotalContri(contribution?.amount)}</h4>
                                    </div>
                            }
                        </div>
                    </li>
                </>}
        </>
    );
}

export default ContributionRow;
