import React, { useEffect, useState, useContext } from 'react';
import Sparkle from '../../Animations/Sparkle';
import { Link, useNavigate } from 'react-router-dom';
import MoneyCollectBox from '../../Components/Dashboard/MoneyCollectBox/Index';
import AddParticipant from '../../Components/Dashboard/AddParticipant/Index';
import AddGiftBox from '../../Components/Dashboard/AddGiftBox/Index';
import AddTestimonialBox from '../../Components/Dashboard/AddTestimonialBox/Index';
import moment from 'moment';
import Countdown from 'react-countdown';
import { isValidDate, ShowImage, hideLoader, showLoader, withBackendUrl, getTotalContri, haveValue, getDateLocal } from '../../utils/helpers';
import UserPerviewList from '../UserPerviewList';
import contributionAction from '../../Actions/contribution.service';
import { AuthContext } from "../../Context/AuthContext";
import Button from 'react-bootstrap/esm/Button';
import { Badge } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BiRupee } from "react-icons/bi";
import FieldError from "./../../../src/Components/Common/FieldError";



function EventParticipantBox(props) {
    const { event } = props;
    var date = getDateLocal(event?.eventStart, event?.eventTime)
    const navigate = useNavigate();
    const { loggedInUserId, setIsLoggedIn, setIsSidebarVisible, loggedInUserData } = useContext(AuthContext);

    const [showContributeNow, setShowContributeNow] = useState(false);
    const [showContributeFirst, setShowContributeFirst] = useState(false);
    const [showContributionSetting, setShowContributionSetting] = useState(false);
    const [alreadyContributed, setAlreadyContributed] = useState(false);
    const [amount, setAmount] = useState();
    const [error, setError] = useState();
    const [wishesCheck, setWishesCheck] = useState(false)


    useEffect(() => {
        contributionsList();
    }, [loggedInUserData]);

    useEffect(() => {
        localStorage.setItem('backToHome', true)
        const mediaCounts = event?.testimonials.reduce((counts, media) => {
            if (media?.userId === loggedInUserId) {
                switch (media?.mediaType) {
                    case "text":
                        counts.textCount++;
                        break;
                    case "voice":
                        counts.audioCount++;
                        break;
                    case "video":
                        counts.videoCount++;
                        break;
                    case "image":
                        counts.photoCount++;
                        break;
                    case "gif":
                        counts.gifCount++;
                        break;
                    default:
                        counts.textCount++;
                }
            }
            return counts;
        }, {
            textCount: 0,
            audioCount: 0,
            videoCount: 0,
            photoCount: 0,
            gifCount: 0,
        });

        if (mediaCounts?.textCount > 0 && mediaCounts?.audioCount > 0 && mediaCounts?.videoCount > 0 && mediaCounts?.photoCount > 0 && mediaCounts?.gifCount > 0) {
            setWishesCheck(true)
        }

        console.log(mediaCounts)
    }, []);
    const contributeNow = () => {
        setShowContributeNow(true)
    }
    const contributeFirst = () => {
        setShowContributeFirst(true)
    }
    const handleClosedateadded = () => {
        setShowContributionSetting(false);
        setShowContributeNow(false);
        setShowContributeFirst(false);
    }
    const contributionsList = () => {
        showLoader();
        contributionAction.contributionList(event?._id).then(res => {
            res.map((itemc) =>
                itemc?.eventParticipantId?.userId?._id === loggedInUserId ?
                    setAlreadyContributed(true)
                    :
                    ''
            )
            hideLoader();
        }).catch(err => {
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);

                navigate('/');
            }
        });
    }

    const continuePay = () => {
        showLoader();
        const formdata = new FormData();
        if (event?.contriType === "fixed") {
            formdata.append("amount", event?.contriPerPerson);
            setAmount(event?.contriPerPerson);
        }
        if (event?.contriType === "any") {

            if (amount >= event?.minimumContriAmount) {
                formdata.append("amount", amount);
                setError();
            } else {
                setError(`Please pay a minimum of ${event?.minimumContriAmount}`);
                hideLoader();
                return false;
            }
        }
        formdata.append("eventId", event?._id);
        contributionAction.initiateTransaction(event?._id, formdata).then(res => {
            hideLoader();
            console.log(res)
            window.location.href = `https://pay.easebuzz.in/v2/pay/${res?.data}`;
            // navigate(`/paymentsuccess/${event?._id}`);
        }).catch(err => {
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);
                navigate('/');
            }
        });
    }
    console.log(event)
    return (
        <>
            <div className="Home-component EventPartially Participantbar ">
                <div onClick={() => <>{event?.isCancelled != true && navigate(`/participant/${event._id}`)}</>}>
                    <div className='head-with-badge'>
                        <div className="avatar-img">
                            <img src={ShowImage(event?.photo, event?.name)} alt="img" />
                            <h4><span>{event.name}</span>'s <br></br>{event.occasionId}</h4>
                        </div>
                        <div className='header_celebration_badge'>
                            {
                                haveValue(event?.isCancelled) && event?.isCancelled == true
                                    ?
                                    <Badge pill className='badge_red'>
                                        Event Cancelled
                                    </Badge>
                                    :
                                    new Date() > new Date(date) && !event?.isConfirmed ?
                                        <Badge pill className='badge_red'>
                                            Event Closed
                                        </Badge>
                                        :
                                        new Date() > new Date(date) && event?.isConfirmed ?
                                            <Badge pill className='badge_green'>
                                                Event Successful
                                            </Badge>
                                            :
                                            event?.isConfirmed ?
                                                <Badge pill className='badge_green'>
                                                    Event Confirmed
                                                </Badge>
                                                :
                                                ''
                            }
                        </div>
                    </div>
                </div>
                <Sparkle />
                <h6 className="date">  {moment(date).format("LL")} </h6>
                {
                    event?.isCancelled != true ?
                        <div className="timeevent">
                            <p>Time to event </p>
                            <h5>
                                {
                                    event && event.eventStart && event.eventTime ? <>
                                        {console.log(date)}
                                        <Countdown date={date}></Countdown>
                                        {/* <Countdown date={moment(`${event?.eventStart} ${event?.eventTime}`).format()}></Countdown> */}
                                    </> : 'To be Decided'
                                }
                            </h5>
                        </div>
                        :
                        ''
                }

                <UserPerviewList />

                <div className="btnsubmit btncontribute">
                    {/* {
                        event?.contriType === "fixed" && (event?.totalContri < parseInt(event?.giftCard?.maximumAmount)) && event?.isCancelled != true ?
                            <><Button onClick={contributeNow} type="button" className='btn-pink'>
                                Contribute now

                                <span>
                                    {getTotalContri(event?.contriPerPerson)}  / person
                                </span>
                            </Button></>
                            :
                            ''
                    } */}
                    {
                        // console.log(event?.isCancelled != true,"aksjakjshdkjadhjsk")
                        event?.contriType === "any" && event?.isCancelled != true ?
                            <Button onClick={contributeNow} type="button" className='btn-pink contribute-now'>Contribute now</Button>
                            :
                            ''
                    }
                    {
                        event && !event?.isConfirmed && (new Date() < new Date(date)) && event?.isCancelled != true ?
                            alreadyContributed ?
                                wishesCheck ?
                                    <Link to={`/participant/${event?._id}?contribute=true`} type="button" className='btngray'>Preview Event</Link>
                                    :
                                    <Link to={`/testimoniallist/${event?._id}`} type="button" className='btngray'>Write a Wish</Link>
                                :
                                <Link to={`/participant/${event?._id}?contribute=true`} type="button" className='btngray'>Contribute Now</Link>
                            :
                            ''
                    }
                    {
                        event && event?.isConfirmed && (new Date() > new Date(event?.eventStart + " " + event?.eventTime)) && event?.isCancelled != true ?
                            <Link className='btn-pink' to={`/recipient/${event?._id}`}>Recipient view</Link>
                            :
                            <></>
                    }
                </div>
            </div >

            <Modal className="Createevent-modal fixamountpopup opacity-full dateadded-popup" show={showContributeNow} onHide={handleClosedateadded}>
                <Modal.Header closeButton>
                    <h6>You will be redirected to</h6>
                </Modal.Header>
                <Modal.Body>
                    {
                        event?.contriType === "fixed" ?
                            <><div className='FixedAmount'>
                                <p>Our Secure payment gateway provider</p>
                                <h4>Pay {getTotalContri(event?.contriPerPerson)}</h4>
                            </div>
                            </>
                            :
                            <>
                                <Form.Group className="fixform" controlId="formBasicEmail">
                                    <Form.Label>Enter amount</Form.Label>
                                    <div className='amount-type'>
                                        <BiRupee />
                                        <Form.Control className={error ? 'error' : ""} type="number" onChange={(e) => setAmount(e?.target.value)} />
                                    </div>
                                </Form.Group>
                                <FieldError error={error} />
                                <div className='FixedAmount'>
                                    <h4>Pay a minimum amount of {getTotalContri(event?.minimumContriAmount)}</h4>
                                </div>
                            </>
                    }
                    <Button onClick={continuePay} type="button" className='btn-pink'>
                        Continue & Pay
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal className="Createevent-modal fixamountpopup opacity-full dateadded-popup" show={showContributeFirst} onHide={handleClosedateadded}>
                <Modal.Header closeButton>
                    <h6>To Write a Wish, You need to contribute first.</h6>
                    {/* <h6>You will be redirected to</h6> */}
                </Modal.Header>
                <Modal.Body>
                    {
                        event?.contriType === "fixed" ?
                            <><div className='FixedAmount'>
                                <p>You will be redirected to our Secure payment gateway provider</p>
                                <h4>Pay {getTotalContri(event?.contriPerPerson)}</h4>
                            </div>
                            </>
                            :
                            <>
                                <Form.Group className="fixform" controlId="formBasicEmail">
                                    <Form.Label>Enter amount</Form.Label>
                                    <div className='amount-type'>
                                        <BiRupee />
                                        <Form.Control className={error ? 'error' : ""} type="number" onChange={(e) => setAmount(e?.target.value)} />
                                    </div>
                                </Form.Group>
                                <FieldError error={error} />
                                <div className='FixedAmount'>
                                    <h4>Pay a minimum amount of {getTotalContri(event?.minimumContriAmount)}</h4>
                                </div>
                            </>
                    }
                    <Button onClick={continuePay} type="button" className='btn-pink'>
                        Continue & Pay
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EventParticipantBox; 
