import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiPencil } from "react-icons/bi";
import { AuthContext } from "../../../Context/AuthContext"
import moment from 'moment';
import { withBackendUrl, isEmpty, haveValue, isNotEmpty, getDateLocal } from '../../../utils/helpers';

function AddGiftBox(props) {
  const { isLoggedInUserId } = useContext(AuthContext);
  const [disabledButton, setDisabledButton] = useState(true);
  const [joinedPart, setJoinedPart] = useState([]);

  const { event, disable, contributions } = props;

const [date,setDate] = useState('')

  useEffect(() => {
    setDisabledButton(disable);
  }, [disable, contributions])

  useEffect(() => {
    let resultA = [];
    if (isNotEmpty(event?.participants)) {
      const newFilter = Object.keys(event?.participants).reduce((result, key) => {
        if (event?.participants[key].status.includes("joined")) {
          resultA.push(event?.participants[key]);
        };
        setJoinedPart(resultA);
      }, []);
    }

    setDate(getDateLocal(event?.eventStart, event?.eventTime))

  }, [event])


  return (
    <>
      <div className='AddParticipant addgiftcard'>
        <div className='participant-head a'>
          <div className='headleft'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#E6F4D7" />
              <g clip-path="url(#clip0_1116_8247)">
                <path d="M15.9987 12V22.6666M15.9987 12H13.6416C13.2942 12 12.9612 11.8595 12.7156 11.6095C12.47 11.3594 12.332 11.0203 12.332 10.6666C12.332 10.313 12.47 9.97389 12.7156 9.72384C12.9612 9.47379 13.2942 9.33331 13.6416 9.33331C15.4749 9.33331 15.9987 12 15.9987 12ZM15.9987 12H18.3558C18.7031 12 19.0362 11.8595 19.2818 11.6095C19.5274 11.3594 19.6654 11.0203 19.6654 10.6666C19.6654 10.313 19.5274 9.97389 19.2818 9.72384C19.0362 9.47379 18.7031 9.33331 18.3558 9.33331C16.5225 9.33331 15.9987 12 15.9987 12ZM21.332 15.3333V20.5333C21.332 21.2801 21.332 21.6534 21.1867 21.9386C21.0589 22.1895 20.8549 22.3935 20.604 22.5213C20.3188 22.6666 19.9454 22.6666 19.1987 22.6666L12.7987 22.6666C12.052 22.6666 11.6786 22.6666 11.3934 22.5213C11.1425 22.3935 10.9385 22.1895 10.8107 21.9386C10.6654 21.6534 10.6654 21.28 10.6654 20.5333V15.3333M9.33203 13.0666L9.33203 14.2666C9.33203 14.64 9.33203 14.8267 9.40469 14.9693C9.46861 15.0947 9.5706 15.1967 9.69604 15.2607C9.83865 15.3333 10.0253 15.3333 10.3987 15.3333L21.5987 15.3333C21.9721 15.3333 22.1588 15.3333 22.3014 15.2607C22.4268 15.1967 22.5288 15.0947 22.5927 14.9693C22.6654 14.8267 22.6654 14.64 22.6654 14.2666V13.0666C22.6654 12.6933 22.6654 12.5066 22.5927 12.364C22.5288 12.2385 22.4268 12.1366 22.3014 12.0726C22.1588 12 21.9721 12 21.5987 12L10.3987 12C10.0253 12 9.83865 12 9.69604 12.0726C9.5706 12.1366 9.46861 12.2385 9.40469 12.364C9.33203 12.5066 9.33203 12.6933 9.33203 13.0666Z" stroke="#4F7A21" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1116_8247">
                  <rect width="16" height="16" fill="white" transform="translate(8 8)" />
                </clipPath>
              </defs>
            </svg>
            <div className='participant-detail'>
              <h4>Gift Options</h4>
            </div>

          </div>
          {isLoggedInUserId(event?.createdBy?._id) && haveValue(contributions) && !(contributions?.length > 0) && !event?.isConfirmed && !(window.location.pathname.startsWith("/participant")) && (haveValue(joinedPart) && joinedPart.length < 2) && (haveValue(event?.participants) && event?.participants.length < 2) ?
            <><div className='headryt'>
              <Link to={`/selectgiftcard/${event?._id}`} className='editbtn' ><BiPencil /></Link>
            </div></>
            :
            ''}

        </div>
        {
          event?.giftCardType ?
            <> <div className='giftcard'>
              <img src={withBackendUrl(event?.giftCard?.icon)} alt='img' />
            </div>
              <ul className='timerzone'>
                <li>Minimum ₹{parseFloat(event?.giftCard?.minimumAmount).toLocaleString('en-US')}</li>
                <li>Maximum ₹{parseFloat(event?.giftCard?.maximumAmount).toLocaleString('en-US')}</li>
              </ul>
            </>
            :
            <> <div className='participant-avatar'>
              <img src="/images/Hands.png" alt='img' />
            </div></>
        }

        <div className='willsent'>
          <h6>Will be sent to {event?.name}</h6>
          {
            event?.eventStart && event?.eventTime ?
              <>
                <p>at <span className='timer'>{moment(date).format('h:mm a')}</span> on <span className='dated'>{moment(date).format("LL")}</span>  </p>
              </>
              :
              ''
          }
        </div>


        <div className='savebtn'>

          {isLoggedInUserId(event?.createdBy?._id) && !(contributions?.length > 0) && !event?.isConfirmed && !(window.location.pathname.startsWith("/participant")) && (haveValue(joinedPart) && joinedPart.length < 2) ?
            disabledButton ?
              <>
                <Link disabled className='btn-pink'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5013 5.00002V18.3334M9.5013 5.00002H6.55487C6.12074 5.00002 5.70439 4.82443 5.39741 4.51186C5.09043 4.1993 4.91797 3.77538 4.91797 3.33335C4.91797 2.89133 5.09043 2.4674 5.39741 2.15484C5.70439 1.84228 6.12074 1.66669 6.55487 1.66669C8.84654 1.66669 9.5013 5.00002 9.5013 5.00002ZM9.5013 5.00002H12.4477C12.8819 5.00002 13.2982 4.82443 13.6052 4.51186C13.9122 4.1993 14.0846 3.77538 14.0846 3.33335C14.0846 2.89133 13.9122 2.4674 13.6052 2.15484C13.2982 1.84228 12.8819 1.66669 12.4477 1.66669C10.1561 1.66669 9.5013 5.00002 9.5013 5.00002ZM16.168 9.16669V10.8334M11.168 18.3334H5.5013C4.56788 18.3334 4.10117 18.3334 3.74465 18.1517C3.43105 17.9919 3.17608 17.7369 3.01629 17.4233C2.83464 17.0668 2.83464 16.6001 2.83464 15.6667V9.16669M16.168 19.1667V16.6667M16.168 16.6667V14.1667M16.168 16.6667H13.668M16.168 16.6667H18.668M1.16797 6.33335L1.16797 7.83335C1.16797 8.30006 1.16797 8.53342 1.2588 8.71168C1.33869 8.86848 1.46617 8.99596 1.62298 9.07586C1.80124 9.16669 2.03459 9.16669 2.5013 9.16669L16.5013 9.16669C16.968 9.16669 17.2014 9.16669 17.3796 9.07586C17.5364 8.99596 17.6639 8.86848 17.7438 8.71168C17.8346 8.53342 17.8346 8.30006 17.8346 7.83335V6.33335C17.8346 5.86664 17.8346 5.63329 17.7438 5.45503C17.6639 5.29823 17.5364 5.17074 17.3796 5.09085C17.2014 5.00002 16.968 5.00002 16.5013 5.00002L2.5013 5.00002C2.03459 5.00002 1.80124 5.00002 1.62298 5.09085C1.46618 5.17074 1.33869 5.29823 1.2588 5.45503C1.16797 5.63329 1.16797 5.86664 1.16797 6.33335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Select gift card
                </Link>
              </>
              :
              <>
                <Link to={`/selectgiftcard/${event?._id}`} className='btn-pink'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5013 5.00002V18.3334M9.5013 5.00002H6.55487C6.12074 5.00002 5.70439 4.82443 5.39741 4.51186C5.09043 4.1993 4.91797 3.77538 4.91797 3.33335C4.91797 2.89133 5.09043 2.4674 5.39741 2.15484C5.70439 1.84228 6.12074 1.66669 6.55487 1.66669C8.84654 1.66669 9.5013 5.00002 9.5013 5.00002ZM9.5013 5.00002H12.4477C12.8819 5.00002 13.2982 4.82443 13.6052 4.51186C13.9122 4.1993 14.0846 3.77538 14.0846 3.33335C14.0846 2.89133 13.9122 2.4674 13.6052 2.15484C13.2982 1.84228 12.8819 1.66669 12.4477 1.66669C10.1561 1.66669 9.5013 5.00002 9.5013 5.00002ZM16.168 9.16669V10.8334M11.168 18.3334H5.5013C4.56788 18.3334 4.10117 18.3334 3.74465 18.1517C3.43105 17.9919 3.17608 17.7369 3.01629 17.4233C2.83464 17.0668 2.83464 16.6001 2.83464 15.6667V9.16669M16.168 19.1667V16.6667M16.168 16.6667V14.1667M16.168 16.6667H13.668M16.168 16.6667H18.668M1.16797 6.33335L1.16797 7.83335C1.16797 8.30006 1.16797 8.53342 1.2588 8.71168C1.33869 8.86848 1.46617 8.99596 1.62298 9.07586C1.80124 9.16669 2.03459 9.16669 2.5013 9.16669L16.5013 9.16669C16.968 9.16669 17.2014 9.16669 17.3796 9.07586C17.5364 8.99596 17.6639 8.86848 17.7438 8.71168C17.8346 8.53342 17.8346 8.30006 17.8346 7.83335V6.33335C17.8346 5.86664 17.8346 5.63329 17.7438 5.45503C17.6639 5.29823 17.5364 5.17074 17.3796 5.09085C17.2014 5.00002 16.968 5.00002 16.5013 5.00002L2.5013 5.00002C2.03459 5.00002 1.80124 5.00002 1.62298 5.09085C1.46618 5.17074 1.33869 5.29823 1.2588 5.45503C1.16797 5.63329 1.16797 5.86664 1.16797 6.33335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Select gift card
                </Link>
              </>
            :
            ''
          }

        </div >
        {/* <div className='togglebar'>
          <p>The selected gift card will be sent to {event?.name} on the the chosen date and time</p>
        </div> */}
      </div >
    </>
  );
}

export default AddGiftBox;
