// import { initializeApp } from "firebase/app";
// import { getAuth } from 'firebase/auth';
// const firebaseConfig = {
//   apiKey: "AIzaSyDFG8UfJWq98DPwPnXmvO8MBEHTyINyyV0",
//   authDomain: "wegroup-274b3.firebaseapp.com",
//   projectId: "wegroup-274b3",
//   storageBucket: "wegroup-274b3.appspot.com",
//   messagingSenderId: "1076008473532",
//   appId: "1:1076008473532:web:80d04d9beb04b45899d1b9",
//   measurementId: "G-38RXMRCVE7"
// };


// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);
// export default app;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUu3kjiJwun7Q32fuxW__vrrpf1YLF9cY",
  authDomain: "wegroup-be974.firebaseapp.com",
  projectId: "wegroup-be974",
  storageBucket: "wegroup-be974.appspot.com",
  messagingSenderId: "64683006390",
  appId: "1:64683006390:web:fd6b69aba05e32ba928917",
  measurementId: "G-TNHY0X5S76"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
