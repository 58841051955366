import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function OnboardSlider() {
    return (
        <>
            <div className='OnboardSlider'>
                <Carousel fade>
                    <Carousel.Item>
                        <div className='peoplegroup'>
                            <img
                                className="d-block w-100"
                                src="/images/peoplegroup.png"
                                alt="First slide"
                            />
                        </div>
                        <Carousel.Caption>
                            <div className='teamup-content'>
                                <h6>Team up & contribute for gifting the special one</h6>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='peoplegroup'>
                            <img
                                className="d-block w-100"
                                src="/images/onboard-slide2.png"
                                alt="second slide"
                            />
                        </div>
                        <Carousel.Caption>
                            <div className='teamup-content'>
                                <h6>Choose the right gift and create fun wishes</h6>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='peoplegroup'>
                            <img
                                className="d-block w-100"
                                src="/images/onboard-slide3.png"
                                alt="third slide"
                            />
                        </div>
                        <Carousel.Caption>
                            <div className='teamup-content'>
                                <h6>Surprise your loved ones with gift and wishes sent on the D-day!</h6>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    );
}

export default OnboardSlider;
