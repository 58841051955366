import React, { useContext, useEffect } from 'react';
import { ThemeContext } from './../hooks/useThemeContext';
import { useNavigate } from 'react-router-dom';

const DisableNav = () => {
    const { backUrlContext } = useContext(ThemeContext);
    const navigate = useNavigate();
    useEffect(() => {
        const handleCustomNavigation = () => {
            // Perform your custom navigation logic here
            // For example, you can navigate to a specific URL
            // console.log(backUrlContext, "backUrlContext--=-=-=")
            // window.history.pushState(null, null, `${backUrlContext}`);
            // window.location.href = backUrlContext;

            navigate(backUrlContext);
            // window.location.reload();
        };

        // Add an event listener to the 'popstate' event
        window.addEventListener('popstate', handleCustomNavigation);

        // Clean up the event listener when the component unmounts
        return () => {
            // console.log("here")
            window.removeEventListener('popstate', handleCustomNavigation);
        };
    }, [backUrlContext]);

    // return <div>Your component content here</div>;
};

export default DisableNav;
