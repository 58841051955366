import React from "react";
// import { BsCalendar2Check, BsGift } from 'react-icons/bs';
// import { FiUserPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import UserPerviewList from "../UserPerviewList";

const FirstTimeUser = (props) => {
    const { isLoggedIn } = useContext(AuthContext);

    const navigate = useNavigate();
    const checkLoginandRedirect = () => {
        localStorage.setItem("link", `/eventstep1`);
        localStorage.setItem("backUrl", `/home`);
        navigate('/login');
    }
    return (
        <>
            <div className="Home-component">
                <p className="teamsup">Team up with your loved ones and create memorable group gifting experiences!</p>
                <UserPerviewList />
                <div className="btnsubmit">
                    {
                        isLoggedIn ?
                            <>
                                <Link to='/eventstep1' onClick={e => localStorage.setItem("soloGift", false)} type="button" className='btn-pink'>Start Group Gift Event</Link>
                                <Link to='/eventstep1' onClick={e => localStorage.setItem("soloGift", true)} type="button" className='btn-pink'>Start Solo Gift Event</Link>
                            </>
                            :
                            <>
                                <Link to='/eventstep1' onClick={e => localStorage.setItem("soloGift", false)} type="button" className='btn-pink'>Start Group Gift Event</Link>
                                <Link to='/eventstep1' onClick={e => localStorage.setItem("soloGift", true)} type="button" className='btn-pink'>Start Solo Gift Event</Link>
                                {/* <Button onClick={checkLoginandRedirect} type="button" className='btn-pink'>Start group gift now</Button> */}
                                <Link to='/login' type="button" className='btngray'>Already have a group? Login</Link>
                            </>
                    }
                </div>

                {
                    props?.showEventHints ? <>

                        <div className="Event-demo">
                            <ul>
                                <li className="iconcircle">
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 7.83342H1.5M16.5 9.08342V6.83342C16.5 5.43328 16.5 4.73322 16.2275 4.19844C15.9878 3.72803 15.6054 3.34558 15.135 3.1059C14.6002 2.83341 13.9001 2.83341 12.5 2.83341H5.5C4.09987 2.83341 3.3998 2.83341 2.86502 3.1059C2.39462 3.34558 2.01217 3.72803 1.77248 4.19844C1.5 4.73322 1.5 5.43328 1.5 6.83341V13.8334C1.5 15.2335 1.5 15.9336 1.77248 16.4684C2.01217 16.9388 2.39462 17.3212 2.86502 17.5609C3.3998 17.8334 4.09987 17.8334 5.5 17.8334H9.41667M12.3333 1.16675V4.50008M5.66667 1.16675V4.50008M13.5813 12.5934C12.9982 11.9441 12.0258 11.7694 11.2951 12.364C10.5645 12.9585 10.4616 13.9526 11.0354 14.6557C11.6092 15.3589 13.5813 17.0001 13.5813 17.0001C13.5813 17.0001 15.5535 15.3589 16.1273 14.6557C16.7011 13.9526 16.6107 12.9523 15.8676 12.364C15.1244 11.7757 14.1645 11.9441 13.5813 12.5934Z" stroke="#4F7A21" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </li>
                                <li>
                                    <h5>Create group event</h5>
                                    <p>eg: Mahesh's Farewell </p>
                                </li>
                            </ul>
                            <ul className="mx-40">
                                <li className="iconcircle">
                                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8327 16V11M13.3327 13.5H18.3327M9.99935 11H6.66602C5.11288 11 4.33631 11 3.72374 11.2537C2.90698 11.592 2.25806 12.241 1.91975 13.0577C1.66602 13.6703 1.66602 14.4469 1.66602 16M12.916 1.2423C14.1376 1.73679 14.9993 2.93443 14.9993 4.33333C14.9993 5.73224 14.1376 6.92988 12.916 7.42437M11.2493 4.33333C11.2493 6.17428 9.75697 7.66667 7.91602 7.66667C6.07507 7.66667 4.58268 6.17428 4.58268 4.33333C4.58268 2.49238 6.07507 1 7.91602 1C9.75697 1 11.2493 2.49238 11.2493 4.33333Z" stroke="#4F7A21" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </li>
                                <li>
                                    <h5>Invite people to group</h5>
                                    <p>Add family, friends or colleagues</p>
                                </li>
                            </ul>
                            <ul>
                                <li className="iconcircle">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99935 5.00008V18.3334M9.99935 5.00008H7.05292C6.61879 5.00008 6.20243 4.82449 5.89545 4.51193C5.58847 4.19937 5.41602 3.77544 5.41602 3.33341C5.41602 2.89139 5.58847 2.46746 5.89545 2.1549C6.20243 1.84234 6.61879 1.66675 7.05292 1.66675C9.34459 1.66675 9.99935 5.00008 9.99935 5.00008ZM9.99935 5.00008H12.9458C13.3799 5.00008 13.7963 4.82449 14.1032 4.51193C14.4102 4.19937 14.5827 3.77544 14.5827 3.33341C14.5827 2.89139 14.4102 2.46746 14.1032 2.1549C13.7963 1.84234 13.3799 1.66675 12.9458 1.66675C10.6541 1.66675 9.99935 5.00008 9.99935 5.00008ZM16.666 9.16675V15.6667C16.666 16.6002 16.666 17.0669 16.4844 17.4234C16.3246 17.737 16.0696 17.992 15.756 18.1518C15.3995 18.3334 14.9328 18.3334 13.9993 18.3334L5.99935 18.3334C5.06593 18.3334 4.59922 18.3334 4.2427 18.1518C3.92909 17.992 3.67413 17.737 3.51434 17.4234C3.33268 17.0669 3.33268 16.6002 3.33268 15.6667V9.16675M1.66602 6.33341L1.66602 7.83341C1.66602 8.30013 1.66602 8.53348 1.75684 8.71174C1.83674 8.86854 1.96422 8.99603 2.12102 9.07592C2.29928 9.16675 2.53264 9.16675 2.99935 9.16675L16.9994 9.16675C17.4661 9.16675 17.6994 9.16675 17.8777 9.07592C18.0345 8.99603 18.162 8.86854 18.2419 8.71174C18.3327 8.53348 18.3327 8.30013 18.3327 7.83342V6.33342C18.3327 5.8667 18.3327 5.63335 18.2419 5.45509C18.162 5.29829 18.0345 5.1708 17.8777 5.09091C17.6994 5.00008 17.4661 5.00008 16.9993 5.00008L2.99935 5.00008C2.53264 5.00008 2.29928 5.00008 2.12102 5.09091C1.96422 5.1708 1.83674 5.29829 1.75684 5.45509C1.66602 5.63335 1.66602 5.8667 1.66602 6.33341Z" stroke="#4F7A21" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </li>
                                <li>
                                    <h5>Contribute, send wishes and make it a memorable one!</h5>
                                    <p>with WeGroup</p>
                                </li>
                            </ul>
                        </div>

                    </> : null
                }

            </div>
        </>
    );
}

export default FirstTimeUser;