import React from 'react';
import Form from 'react-bootstrap/Form';
import { BiRupee } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { AiOutlineInfoCircle } from "react-icons/ai";

function FixedAmount() {
  return (
    <>
      <div className='FixedAmount'>
        <img src="/images/budget.png" alt='icon' />
        <h4>Fixed Amount</h4>
        <p>All Participants pay the same amount.</p>
        <Form.Group className="fixform" controlId="formBasicEmail">
          <Form.Label>Enter per person contribution</Form.Label>
          <div className='amount-type'>
            <BiRupee />
            <Form.Control type="number" placeholder="300" />
            <span className='perperson'>/ person</span>
          </div>
        </Form.Group>
        <div className='note-error'>
          <AiOutlineInfoCircle />
          <p>Cannot update this as the contributions have already started.</p>
        </div>
        <Link to='' type='button' className='btn-pink'>Update</Link>
        
      </div>
    </>
  );
}

export default FixedAmount;
