import React, { useState, useEffect, useContext, useRef } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import TestimonialAction from "../../Actions/testimonial.service";
import { toast } from 'react-toastify';
import getBlobDuration from 'get-blob-duration';
import { ThemeContext } from '../../hooks/useThemeContext';
import { showLoader, hideLoader, isNotEmpty, haveValue } from '../../utils/helpers';
import { AuthContext } from '../../Context/AuthContext';
import { RxCross2 } from 'react-icons/rx';


function AudioRecorderscreen(props) {

    const audioRef = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setBackUrlContext } = useContext(ThemeContext)

    const [audioLength, setAudioLength] = useState('0:00');
    const [retake, setRetake] = useState(false);
    const [playButton, setPlayButton] = useState(false);
    const [editTest, setEditTest] = useState(false);
    const [upload, setUpload] = useState(true);
    const [eventId, setEventId] = useState('');
    const [testimonialId, setTestimonialId] = useState('');
    const [caption, setCaption] = useState('');
    const [blobUrl, setBlobUrl] = useState('');
    const [audioUrl, setAudioUrl] = useState('');

    const { showSuccessToast } = useContext(ThemeContext);
    const { setIsLoggedIn, setIsSidebarVisible } = useContext(AuthContext);

    const [playAudio, setPlayAudio] = useState(false);

    useEffect(() => {
        setBackUrlContext(`/testimonialList/${localStorage.getItem('roleId')}`);
        if (localStorage.getItem('editTest') === "true") {
            setEditTest(true);
            setTestimonialId(id);
            localStorage.removeItem('editTest');
        } else {
            setEventId(id);
        }
    }, [])

    const goBack = (e) => {
        if (isNotEmpty(localStorage.getItem('backUrl'))) {
            let url;
            url = localStorage.getItem('backUrl');
            localStorage.removeItem("backUrl");
            navigate(url);
            e.preventDefault();
        } else {
            navigate(-1);
        }
    }

    const recorderControls = useAudioRecorder();

    const setRetakeFunc = () => {
        setAudioLength('00:00');
        setRetake(true);
        setPlayButton(false);
        setBlobUrl('');
        setAudioUrl('');
        setPlayAudio(false);
        recorderControls.recordingBlob = undefined;
        recorderControls.startRecording();
    }

    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        setBlobUrl(blob);
        setAudioUrl(url);
        audio.url = url;
        audio.controls = true;
        audio.className = 'audiobloburl';
        setPlayButton(true);
    };

    useEffect(() => {
        if (haveValue(blobUrl)) {
            getBlobDuration(blobUrl).then(function (duration) {
                setAudioLength(convertStoMs(Math.round(duration)))
            });
        }
    }, [blobUrl])


    let output = '0:00';
    const convertStoMs = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = seconds % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        return output = minutes + " : " + extraSeconds;
    }
    useEffect(() => {
        setAudioLength(convertStoMs(recorderControls.recordingTime));
    }, [recorderControls.recordingTime])



    const uploadVoice = () => {
        setUpload(false);
        setPlayButton(false);
    }


    const saveTestimonial = () => {
        // let errors = validateJOIFormField(caption, schema);
        // if (errors == null) {
        showLoader();
        const data = new FormData();
        data.append("caption", caption);
        data.append("eventId", eventId);
        data.append("mediaType", "voice");
        const audiofile = new File([blobUrl], "audiofile.mp3", {
            type: "audio/mp3",
        });

        data.append("file", audiofile);
        TestimonialAction.createTestimonial(data, eventId).then(res => {
            showSuccessToast('Wish Added');
            setCaption('');
            setPlayButton(false);
            setTimeout(() => {
                toast.dismiss();
                navigate(`/testimoniallist/${eventId}`);
                hideLoader();
            }, 1000);
        }).catch(err => {
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);
                hideLoader();
                navigate('/');
            }
        });
    }

    const updateTestimonial = () => {
        showLoader();
        const data = new FormData();
        data.append("caption", caption);
        data.append("mediaType", "voice");
        const audiofile = new File([blobUrl], "audiofile.mp3", {
            type: "audio/mp3",
        });

        data.append("file", audiofile);

        TestimonialAction.updateTestimonial(data, testimonialId).then(res => {
            setCaption('');
            setEditTest(false);
            showSuccessToast('Wish Updated');
            setPlayButton(false);
            setTimeout(() => {
                toast.dismiss();
                navigate(-1);
                hideLoader();
            }, 1000);
            props.onRefresh();
        }).catch(err => {
            hideLoader();
            console.log(err)
            if (err?.response?.data?.message == "Unauthorized User.") {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                setIsSidebarVisible(false);

                navigate('/');
            }
        });
    }

    useEffect(() => {
        if (upload) {
            document.body.classList.remove("media-viewer");
        } else {
            document.body.classList.add("media-viewer");
        }
    }, [upload]);

    const start = () => {
        if (audioRef != null) {
            setPlayAudio(true);
        }
    }

    const pausePlay = () => {
        if (audioRef != null) {
            setPlayAudio(false);
        }
    }

    useEffect(() => {
        if (audioRef != null && audioRef.current != null) {
            if (playAudio) {
                audioRef.current.play();
                audioRef.current.addEventListener("ended", function () {
                    setPlayAudio(false);
                });
            } else {
                audioRef.current.pause();
            }
        }
    }, [playAudio]);

    return (
        <>
            {
                haveValue(upload) && upload ?
                    <div className='audio-record-screen'>
                        <div className='crossbar'><Link onClick={goBack}>
                            <RxCross2 />
                        </Link></div>
                        <div className='middlebar'>
                            <AudioRecorder
                                onRecordingComplete={(blob) => addAudioElement(blob)}
                                recorderControls={recorderControls}
                            />
                            <img src="./../images/audio.png" alt='audio-icon' />
                            <p className='timerbar'>{audioLength}</p>
                            {
                                recorderControls.isRecording && !recorderControls.isPaused
                                    ?

                                    <div className='player'>
                                        <Audio
                                            height="80"
                                            width="80"
                                            radius="9"
                                            color='#e31b54'
                                            ariaLabel='three-dots-loading'
                                            wrapperStyle
                                            wrapperClass
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        {
                            playButton ?
                                <>
                                    <div className='reactPlayerMain d-none'>
                                        <audio ref={audioRef} controls src={blobUrl ? URL.createObjectURL(blobUrl) : ''}>
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                </>
                                :
                                null
                        }
                        <div className='bottomabr'>
                            <div className='Controls'>
                                {
                                    recorderControls.isRecording ?
                                        <button onClick={recorderControls.stopRecording} className='controlbtn stop' type='button'><img src='./../images/stopcontrol.png' alt='stop' /></button>
                                        :
                                        ''
                                }
                                {
                                    (!recorderControls.isRecording && recorderControls.recordingBlob !== undefined) ?

                                        <>

                                            {
                                                playAudio ?
                                                    <>
                                                        <button onClick={pausePlay} className='controlbtn stop' type='button'><img src='./../images/stopcontrol.png' alt='pause' /></button>
                                                    </> :
                                                    <>
                                                        <button onClick={start} className='controlbtn play' type='button'><img src='./../images/playcontrol.png' alt='play' /></button>
                                                    </>
                                            }

                                        </>

                                        //convert to play button
                                        :
                                        ''
                                }
                                {
                                    (!(recorderControls.recordingBlob) && !(recorderControls.isRecording)) ?
                                        <button onClick={recorderControls.startRecording} className='controlbtn precord' type='button'><img src='./../images/recordcontrol.png' alt='precord' /></button>
                                        :
                                        ''
                                }
                            </div>
                            {
                                (recorderControls.isRecording == false) && (recorderControls.recordingBlob !== undefined) ?
                                    <>
                                        {
                                            editTest ?
                                                <button className='btn-pink' onClick={updateTestimonial}>Upload</button>
                                                :
                                                <button className='btn-pink' onClick={saveTestimonial}>Upload</button>

                                        }
                                        <button className='btngray' onClick={setRetakeFunc}>Re take</button></>
                                    :
                                    ''
                            }
                        </div >

                    </div>
                    : <>
                        <div className='addmedia-wrapper pt-0'>
                            <div className='headbar'>
                                <div className='crossbar'><Link onClick={goBack}>
                                    <RxCross2 />
                                </Link></div>
                                <div className='media-wraper'>
                                    <audio controls src={URL.createObjectURL(blobUrl)}>
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>
                            <div className='media-message-box'>
                                <Form>
                                    <Form.Control
                                        className=''
                                        as="textarea"
                                        placeholder="Add a message"
                                        style={{ height: '140px' }}
                                        value={caption ? caption : ''} onChange={(e) => setCaption(e.target.value)}
                                    />
                                    <Button className="btn-pink" onClick={saveTestimonial}>Upload</Button>
                                </Form>
                            </div>
                        </div>
                    </>
            }
        </>
    );

}

export default AudioRecorderscreen;