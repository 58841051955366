import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function EventCancelState(props) {

  // Cancel Event Modal const
  const [CancelEventshow, EventsetShow] = useState(false);
  const EventhandleClose = () => EventsetShow(false);
  const EventhandleShow = () => EventsetShow(true);
  {/* event can't be cancelled */ }
  const [EventCantshow, EventCantsetShow] = useState(false);
  const EventCantsethandleClose = () => EventCantsetShow(false);
  const EventCantsethandleShow = () => EventCantsetShow(true);

  return (
    <>
      {/* EventCancelState */}
      <div className='EventCancelState'>
        <p className='event-text'>Did you create the event by mistake, or do you want to start from scratch?</p>
        <button className='btn-cancelbar btngray' type="submit" onClick={(e) => props.handleClickCancel(e)} >Cancel This Event</button>
        <p className='note-error'>Note: This action cannot be undone.</p>
      </div>

      {/* Cancel Event Modal const */}
      <Modal className='Createevent-modal dateadded-popup cancelevent-modal' show={CancelEventshow} onHide={EventhandleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body >
          <div className='del-icon'>
            <img src='/images/del.png' alt='delete-icon' />
          </div>
          <h4>Cancel this event?</h4>
          <p>Are you sure you want to cancel this event? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-pink btn-red' onClick={EventCantsethandleShow}>
            Yes, Cancel this event
          </Button>
          <Button className='btngray' onClick={EventhandleClose}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
      {/* event can't be cancelled */}
      <Modal className='Createevent-modal dateadded-popup cancelevent-modal Eventcannot-modal' show={EventCantshow} onHide={EventCantsethandleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body >
          <div className='del-icon'>
            <img src='/images/del.png' alt='delete-icon' />
          </div>
          <h4>This event cannot be cancelled</h4>
          <p>Since people have contributed towards the event, we cannot cancel this.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btngray' onClick={EventCantsethandleClose}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventCancelState;
